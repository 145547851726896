import React, { createContext, useContext } from "react";
import { ExpenseRateSheet } from "~/gql/types";
import {
  assignSuccessorExpenseRateSheet,
  createExpenseRate,
  editExpenseRateSheet,
} from "~/routes";
import { Route, Routes, useParams } from "react-router-dom";
import { Cell, Grid, Row } from "@material/react-layout-grid";
import Card from "@material/react-card";
import RateSheet from "~/visuals/pages/ExpenseRates/ExpenseRateSheet";
import NewExpenseRate from "~/visuals/pages/ExpenseRates/NewExpenseRate";
import EditExpenseRate from "~/visuals/pages/ExpenseRates/EditExpenseRate";
import { useExpenseRateSheetQuery } from "./query.generated";
import { AssignSuccessorExpenseRateSheet } from "~/visuals/pages/ExpenseRates/ExpenseRateSheetRouter/AssignSuccessor";
import { EditExpenseRateSheet } from "~/visuals/pages/ExpenseRates/ExpenseRateSheetRouter/EditExpenseRateSheet";

export type ExpenseRateSheetData = {
  expenseRates: {
    rateSheet: ExpenseRateSheet;
  };
};

export type ExpenseRateSheetRouteData = {
  name: string;
  rateName?: string;
};

const Loading = () => (
  <Grid>
    <Row>
      <Cell columns={12}>
        <Card>loading...</Card>
      </Cell>
    </Row>
  </Grid>
);

const useReload = (): React.DispatchWithoutAction => {
  const api = useContext(ExpenseRateSheetContext);

  if (!api) {
    throw new Error(
      "Do not use ExpenseRateSheetRouter.useReload outside of a ExpenseRateSheetRouter."
    );
  }

  return api.reload;
};

type RouteProps = {
  rateSheet: ExpenseRateSheet;
};

const PageRoutes: React.FC<RouteProps> = ({ rateSheet }) => {
  return (
    <Routes>
      <Route
        path={createExpenseRate.subPath}
        element={
          <NewExpenseRate
            rateSheetName={rateSheet?.name}
            useReload={useReload}
          />
        }
      />
      <Route
        path={assignSuccessorExpenseRateSheet.subPath}
        element={
          <AssignSuccessorExpenseRateSheet
            rateSheet={rateSheet}
            useReload={useReload}
          />
        }
      />
      <Route
        path={editExpenseRateSheet.subPath}
        element={
          <EditExpenseRateSheet rateSheet={rateSheet} useReload={useReload} />
        }
      />
      <Route path={"/edit-expense-rate"}>
        <Route
          path={":rateName"}
          element={
            <EditExpenseRate rateSheet={rateSheet} useReload={useReload} />
          }
        />
      </Route>
      <Route
        path={"*"}
        element={<RateSheet rateSheet={rateSheet} useReload={useReload} />}
      />
    </Routes>
  );
};

type ExpenseRateSheetContextApi = {
  reload: () => void;
};

const ExpenseRateSheetContext =
  createContext<ExpenseRateSheetContextApi | null>(null);

const ExpenseRateSheetRouter: React.FC = () => {
  const params = useParams<ExpenseRateSheetRouteData>();

  const { data, loading, refetch } = useExpenseRateSheetQuery({
    variables: { name: params.name! },
  });
  const expenseRateSheet = data?.expenseRates?.rateSheet;

  if (data && expenseRateSheet && !loading) {
    const api = {
      reload: refetch,
    };

    return (
      <ExpenseRateSheetContext.Provider value={api}>
        <PageRoutes rateSheet={expenseRateSheet} />
      </ExpenseRateSheetContext.Provider>
    );
  }

  return <Loading />;
};

export default ExpenseRateSheetRouter;
