import React from "react";
import { useGetFieldOfficesQuery } from "./query.generated";
import { Route, Routes } from "react-router-dom";
import FieldOfficeList from "./FieldOfficeList";
import { FieldOffice } from "~/gql/types";
import { FieldOfficePage } from "~/visuals/pages/FieldOffice";

const FieldOfficesRouter: React.FC = () => {
  const { data, loading, refetch } = useGetFieldOfficesQuery();

  const fieldOffices = ((data && data.fieldOffices?.all) ??
    []) as FieldOffice[];

  return (
    <Routes>
      <Route
        element={<FieldOfficePage offices={fieldOffices} refetch={refetch} />}
        path=":office"
      />
      <Route
        index
        element={
          <FieldOfficeList fieldOffices={fieldOffices} isLoading={loading} />
        }
      />
    </Routes>
  );
};

export default FieldOfficesRouter;
