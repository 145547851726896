import React, { useEffect, useReducer } from "react";
import ProjectTimeEntryForm from "~/visuals/organisms/ProjectTimeForm";
import {
  Action,
  FormData,
  reducer,
  State,
} from "~/visuals/organisms/ProjectTimeForm/stateMachine";
import moment from "moment";
import { useWorkDayApi } from "~/WorkDayApi";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useGetEffectiveTaskRateSheetLazyQuery } from "./query.generated";
import { useParams } from "react-router-dom";
import {
  CreateProjectTimeCommandDailyHoursInput,
  ProjectStatus,
  TaskRate,
  TaskRateType,
} from "~/gql/types";
import { useProjectTimeCallback } from "~/visuals/organisms/ProjectTimeForm/useProjectTimeCallback";
import { toDailyHoursArgs } from "~/visuals/organisms/ProjectTimeForm/toDailyHoursArgs";

const NewProjectTimeEntry: React.FC = () => {
  const {
    workDay: { date, employee },
    breadcrumbs,
    upn,
    createProjectTime,
  } = useWorkDayApi();

  const params = useParams();

  const initialProject =
    params?.projectName && params?.projectNumber
      ? {
          name: params.projectName,
          number: +params.projectNumber,
          status: ProjectStatus.Active,
        }
      : null;

  const initialTask = params?.taskName;

  useBreadcrumbs(
    [...breadcrumbs, { text: "Create New Project Time Entry" }],
    [breadcrumbs]
  );

  const [getSheet, { data: rateSheetData }] =
    useGetEffectiveTaskRateSheetLazyQuery();

  const rateSheet = rateSheetData?.projects?.effectiveTaskRateSheet;
  const taskRates = (rateSheet?.rates || []) as any as TaskRate[];

  const durationFormat = (d) => {
    if (!d) {
      return null;
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm:ss");
  };

  const getCloneCallback = useProjectTimeCallback();

  const initialState: State = {
    eeCode: null,
    hours: null,
    project: initialProject,
    taskName: null,
    taskRate: null,
    notes: null,
    myRole: null,
    otherCrewOne: null,
    otherCrewTwo: null,
    otherRole1: null,
    otherRole2: null,
    crewSize: null,
    loading: false,
    location: null,
    taskRates,
    adminNotes: null,
    rateSheetName: null,
    saveAndClone: false,
    copyToDate: false,
    copyToDates: false,
    dailyHours: [],
    formErrors: null,
    employee: employee!,
    fieldSupervisor: "",
    leadPartyChief: "",
  };

  const [state, dispatch] = useReducer(
    (a: State, b: Action) => reducer(a, b),
    initialState as State
  );

  useEffect(() => {
    const taskRate = taskRates?.find((x) => x.name === initialTask);
    if (taskRate) {
      dispatch({
        tag: "UpdateField",
        value: taskRate as TaskRate,
        fieldName: "taskRate",
      });
    }
  }, [initialTask, taskRates]);

  useEffect(() => {
    if (state.project) {
      dispatch({ tag: "LoadData" });
      void getSheet({
        variables: {
          asOf: date,
          projectNumber: state.project.number,
        },
      });
    }
  }, [state.project]);

  useEffect(() => {
    if (rateSheetData) {
      dispatch({
        tag: "TaskRatesLoaded",
        taskRates,
        rateSheetName: rateSheet?.name ?? "",
      });
    }
  }, [rateSheetData]);

  const addProjectTimeEntry = async (values: FormData) => {
    if (state.formErrors) {
      return state.formErrors;
    }

    dispatch({ tag: "LoadData" });

    const rateType =
      state.taskRates?.find((x) => x.name == state.taskName)?.rateType ?? null;
    const includeCrew = rateType === TaskRateType.Field;

    const toCrewValue = (val: any) => (includeCrew ? val ?? null : null);

    const variables = {
      date,
      hours: durationFormat(values.hours)!,
      projectNumber: values.project?.number!,
      eeCode: values.eeCode?.eeCode!,
      taskName: values.taskName!,
      notes: values.notes!,
      myRole: toCrewValue(values.myRole),
      otherCrewUpn1: toCrewValue(values?.otherCrewOne?.userPrincipalName),
      otherCrewUpn2: toCrewValue(values?.otherCrewTwo?.userPrincipalName),
      otherRole1: toCrewValue(values?.otherRole1),
      otherRole2: toCrewValue(values?.otherRole2),
      state: values?.location?.state!,
      county: values?.location?.county!,
      adminNotes: values?.adminNotes || null,
      userPrincipalName: upn,
      dailyHours: toDailyHoursArgs<CreateProjectTimeCommandDailyHoursInput>(
        state.dailyHours
      ),
    };
    const cloneCallback = getCloneCallback(values, "Create");

    const result = await createProjectTime(variables, cloneCallback);

    dispatch({ tag: "Submitted" });

    return result;
  };
  return (
    <ProjectTimeEntryForm
      type="Create"
      handleSubmit={addProjectTimeEntry}
      date={date}
      state={state}
      dispatch={dispatch}
    />
  );
};

export default NewProjectTimeEntry;
