import * as Types from '../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllEeCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllEeCodesQuery = { __typename?: 'QueryModel', allEeCodes?: Array<{ __typename?: 'EeCodeRecord', eeCode: string, description: string, overrideEmployeeId?: string | null, isActive: boolean, employee?: { __typename?: 'Employee', firstName: string, middleInitial: string, lastName: string, userPrincipalName: string, ultiProId: string, isMobileEmployee: boolean, isTerminated: boolean } | null } | null> | null };


export const GetAllEeCodesDocument = gql`
    query GetAllEeCodes {
  allEeCodes {
    eeCode
    description
    employee {
      firstName
      middleInitial
      lastName
      userPrincipalName
      ultiProId
      isMobileEmployee
      isTerminated
    }
    overrideEmployeeId
    isActive
  }
}
    `;

/**
 * __useGetAllEeCodesQuery__
 *
 * To run a query within a React component, call `useGetAllEeCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEeCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEeCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEeCodesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEeCodesQuery, GetAllEeCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEeCodesQuery, GetAllEeCodesQueryVariables>(GetAllEeCodesDocument, options);
      }
export function useGetAllEeCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEeCodesQuery, GetAllEeCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEeCodesQuery, GetAllEeCodesQueryVariables>(GetAllEeCodesDocument, options);
        }
export type GetAllEeCodesQueryHookResult = ReturnType<typeof useGetAllEeCodesQuery>;
export type GetAllEeCodesLazyQueryHookResult = ReturnType<typeof useGetAllEeCodesLazyQuery>;
export type GetAllEeCodesQueryResult = Apollo.QueryResult<GetAllEeCodesQuery, GetAllEeCodesQueryVariables>;