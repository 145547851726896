import React, { useEffect, useState } from "react";
import TitledCard from "~/titled-card";
import List, { ListItem } from "@material/react-list";
import { useUser } from "~/Auth/useUser";
import { InlineProgress } from "~/inline-progress";
import "./RoleStats.scss";
import { Link } from "react-router-dom";
import { useGetChargeStatsQuery } from "./getChargeStats.generated";

export type Role = "Pm" | "Ps" | "Ba";

const toSuffix = (count: number) => (count === 1 ? "" : "s");

type StatProps = {
  charges: number;
  projects: number;
  role: Role;
};

export const TotalStatItem: React.FC<StatProps> = ({
  charges,
  projects,
  role,
}) => {
  if (!charges) {
    return null;
  }

  const path = `/project-charges?chargeState=${role}Pending`;
  const text = `${charges} charge${toSuffix(
    charges
  )} across ${projects} project${toSuffix(
    projects
  )} total needing ${role.toUpperCase()} approval`;

  return (
    <Link to={path}>
      <ListItem className={"total-stat"}>{text}</ListItem>
    </Link>
  );
};

export const MyStatItem: React.FC<StatProps> = ({
  charges,
  projects,
  role,
}) => {
  const user = useUser();

  if (charges === 0) {
    return null;
  }

  const roleMap = {
    Pm: "projectManager",
    Ps: "projectSupervisor",
    Ba: "billingAdmin",
  };

  const stateFilter = `chargeState=${role}Pending`;
  const userFilter = `${roleMap[role]}=${user.email}`;
  const path = `/project-charges?${stateFilter}&${userFilter}`;
  const text = `${charges} charge${toSuffix(
    charges
  )} across ${projects} project${toSuffix(
    projects
  )} needing my ${role.toUpperCase()} approval`;

  return (
    <Link to={path}>
      <ListItem className={"my-stat"}>{text}</ListItem>
    </Link>
  );
};

const DataFetcher = ({ onChange }) => {
  const result = useGetChargeStatsQuery();

  useEffect(() => {
    onChange(result);
  }, [result.loading, result.data]);

  return null;
};

type FetchResult = ReturnType<typeof useGetChargeStatsQuery>;

export const RoleStats: React.FC = () => {
  const user = useUser();

  const [{ data, loading }, setResult] = useState<FetchResult>({
    loading: true,
  } as any as FetchResult);

  const stats = data?.projectCharges?.roleStats;
  const isAdmin = user.authorizations.legacyAuth.administerProjectCharges;

  const myStatValues = stats
    ? [stats.myBaApproval, stats.myPmApproval, stats.myPsApproval]
    : [];
  const totalStatValues =
    stats && isAdmin
      ? [stats.allBaApproval, stats.allPmApproval, stats.allPsApproval]
      : [];
  const shouldDisplay = [...myStatValues, ...totalStatValues].some(
    (x) => x && x > 0
  );

  const emptyMessage =
    stats?.showIfEmpty &&
    totalStatValues.length === 0 &&
    !myStatValues.some((x) => x > 0)
      ? "No Project Charges Needing Approval"
      : null;

  return (
    <>
      <DataFetcher onChange={setResult} />
      {loading && <InlineProgress />}
      {!loading && (shouldDisplay || emptyMessage) && (
        <TitledCard
          title="Project Charges Pending Approval"
          className="charge-role-stats"
        >
          {stats && (
            <List>
              {emptyMessage && <ListItem>{emptyMessage}</ListItem>}
              <MyStatItem
                charges={stats.myPmApproval}
                projects={stats.myPmApprovalProjects}
                role="Pm"
              />
              <MyStatItem
                charges={stats.myPsApproval}
                projects={stats.myPsApprovalProjects}
                role="Ps"
              />
              <MyStatItem
                charges={stats.myBaApproval}
                projects={stats.myBaApprovalProjects}
                role="Ba"
              />

              {isAdmin && (
                <>
                  <TotalStatItem
                    charges={stats.allPmApproval!}
                    projects={stats.allPmApprovalProjects!}
                    role="Pm"
                  />
                  <TotalStatItem
                    charges={stats.allPsApproval!}
                    projects={stats.allPsApprovalProjects!}
                    role="Ps"
                  />
                  <TotalStatItem
                    charges={stats.allBaApproval!}
                    projects={stats.allBaApprovalProjects!}
                    role="Ba"
                  />
                </>
              )}
            </List>
          )}
        </TitledCard>
      )}
    </>
  );
};
