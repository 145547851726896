import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SingleEmployeeQueryVariables = Types.Exact<{
  userPrincipalName: Types.Scalars['String'];
}>;


export type SingleEmployeeQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', single?: { __typename?: 'Employee', firstName: string, middleInitial: string, lastName: string, timeEntryMethod: string, email: string, userPrincipalName: string } | null } | null };


export const SingleEmployeeDocument = gql`
    query SingleEmployee($userPrincipalName: String!) {
  employees {
    single(userPrincipalName: $userPrincipalName) {
      firstName
      middleInitial
      lastName
      timeEntryMethod
      email
      userPrincipalName
    }
  }
}
    `;

/**
 * __useSingleEmployeeQuery__
 *
 * To run a query within a React component, call `useSingleEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleEmployeeQuery({
 *   variables: {
 *      userPrincipalName: // value for 'userPrincipalName'
 *   },
 * });
 */
export function useSingleEmployeeQuery(baseOptions: Apollo.QueryHookOptions<SingleEmployeeQuery, SingleEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SingleEmployeeQuery, SingleEmployeeQueryVariables>(SingleEmployeeDocument, options);
      }
export function useSingleEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SingleEmployeeQuery, SingleEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SingleEmployeeQuery, SingleEmployeeQueryVariables>(SingleEmployeeDocument, options);
        }
export type SingleEmployeeQueryHookResult = ReturnType<typeof useSingleEmployeeQuery>;
export type SingleEmployeeLazyQueryHookResult = ReturnType<typeof useSingleEmployeeLazyQuery>;
export type SingleEmployeeQueryResult = Apollo.QueryResult<SingleEmployeeQuery, SingleEmployeeQueryVariables>;