import React from "react";
import { Link } from "react-router-dom";
import List, { ListItemText, ListItemGraphic } from "@material/react-list";
import MaterialIcon from "@material/react-material-icon";
import {
  home,
  customerManagement,
  pendingCustomers,
  fieldOffices,
  perDiemRates,
  employees,
  myWork,
  expenseRateSheets,
  taskRateSheets,
  projects,
  invoices,
  timesheetReview,
  salesTaxReport,
  projectCharges,
  expensesSearch,
  employeeRates,
  atvExpenses,
  overheadEeCodes,
  serviceDescriptions,
  corpLocations,
  divisions,
  projectRequests,
  createProjectRequest,
  myExpenses,
  roles,
  projectStatus,
  createProject,
  officeBillingReport,
  customRollupInvoices,
  performanceStats,
  eeCodesSearch,
  thirdPartyInvoices,
  revenueReporting,
} from "~/routes";
import { route as products } from "~/products";
import "./NavMenu.scss";
import { useUser } from "~/Auth/useUser";
import _ from "lodash";
import { ProjectChargesNavItem } from "./ProjectChargesNavItem";
import { ProjectsNavItem } from "./ProjectsNavItem";
import { AuthMenuItemProps, NavMenuProps } from "./types";
import { Route } from "~/routeTypes";
import { MenuAuthorizations } from "~/gql/types2";

const AuthMenuItem: React.FC<AuthMenuItemProps> = (props) => {
  const { isVisible, route, icon, title, onClick, search } = props;

  if (!isVisible) {
    return null;
  }

  const linkPath = route?.path ? route.path + `${search ?? ""}` : "";

  return (
    <li>
      <Link to={linkPath} className="mdc-list-item" onClick={onClick}>
        <ListItemGraphic graphic={<MaterialIcon icon={icon} />} />
        <ListItemText primaryText={title} />
      </Link>
    </li>
  );
};

export default function NavMenu(props: NavMenuProps): React.ReactElement {
  const user = useUser();
  const { authorizations } = user;
  const auths: Partial<Record<MenuAuthorizations, MenuAuthorizations>> =
    _.chain(authorizations.auth.menus)
      .keyBy((x) => x)
      .value();

  const homeItem = {
    route: home,
    title: "Home",
    icon: "home",
    isVisible: true,
  };
  const logoutItem = {
    onClick: () => user.logout(),
    title: "Log Out",
    icon: "logout",
    isVisible: true,
  };

  const items: AuthMenuItemProps[] = _.chain([
    {
      route: atvExpenses,
      title: "ATV Expenses",
      icon: "two_wheeler",
      isVisible: true,
    },
    {
      route: customerManagement,
      title: "Customers",
      icon: "account_balance",
      isVisible: true,
    },
    {
      route: employees,
      title: "Employees",
      icon: "supervised_user_circle",
      isVisible: auths.Impersonate,
      search: "?status=Active",
    },
    {
      route: employeeRates,
      title: "Employee Rates",
      icon: "person_add",
      isVisible: auths.Rates,
    },
    {
      route: expensesSearch,
      title: "Expenses",
      icon: "payment",
      isVisible: auths.Expenses,
    },
    {
      route: expenseRateSheets,
      title: "Expense Rate Sheets",
      icon: "table_view",
      isVisible: auths.Rates,
    },
    {
      route: fieldOffices,
      title: "Field Offices",
      icon: "business",
      isVisible: auths.FieldOffices,
    },
    {
      route: invoices,
      title: "Invoices",
      icon: "playlist_add_check",
      isVisible: auths.Invoices,
    },
    {
      route: customRollupInvoices,
      title: "Custom Rollup Invoices",
      icon: "playlist_add",
      isVisible: auths.Invoices,
    },
    {
      route: myWork.toRoute(user.email),
      title: "My Work",
      icon: "date_range",
      isVisible: true,
    },
    {
      route: overheadEeCodes,
      title: "Overhead EE Codes",
      icon: "numbers",
      isVisible: auths.EeCodes,
    },
    {
      route: pendingCustomers,
      title: "Pending Customers",
      icon: "hourglass_empty",
      isVisible: auths.PendingCustomers,
    },
    {
      route: perDiemRates,
      title: "Per Diem Rates",
      icon: "attach_money",
      isVisible: auths.Rates,
    },
    {
      route: projects,
      title: "Projects",
      icon: "source",
      search: "?status=Active",
      isVisible: true,
    },
    {
      route: projectCharges,
      title: "Project Charges",
      icon: "shopping_cart",
      isVisible: auths.ProjectCharges,
    },
    {
      route: salesTaxReport,
      title: "Sales Tax Report",
      icon: "calculate",
      isVisible: auths.SalesTaxReport,
    },
    {
      route: taskRateSheets,
      title: "Task Rate Sheets",
      icon: "backup_table",
      isVisible: auths.Rates,
    },
    {
      route: timesheetReview,
      title: "Timesheet Review",
      icon: "calendar_month",
      isVisible: auths.TimesheetReview,
    },
    {
      route: serviceDescriptions,
      title: "Service Descriptions",
      icon: "label_important",
      isVisible: auths.CorporateStructure,
    },
    {
      route: corpLocations,
      title: "Corporate Locations",
      icon: "near_me",
      isVisible: auths.CorporateStructure,
    },
    {
      route: divisions,
      title: "Divisions",
      icon: "workspaces",
      isVisible: auths.CorporateStructure,
    },
    {
      route: projectRequests,
      title: "Project Requests",
      icon: "rule_folder",
      isVisible: auths.ProjectRequests,
      search: "?state=Pending",
    },
    {
      route: createProjectRequest,
      title: "Request New Project",
      icon: "create_new_folder",
      isVisible: auths.RequestNewProject,
    },
    {
      route: createProject,
      title: "Create New Project",
      icon: "create_new_folder",
      isVisible: auths.CreateNewProject,
    },
    {
      route: myExpenses,
      title: "My Expenses",
      icon: "payment",
      isVisible: true,
    },
    {
      route: roles,
      title: "Employee Roles",
      icon: "badge",
      isVisible: auths.EmployeeRoles,
    },
    {
      route: projectStatus,
      title: "Project Status",
      icon: "analytics",
      isVisible: auths.ViewProjectStatus,
    },
    {
      route: officeBillingReport,
      title: "Office Billing Report",
      icon: "payments",
      isVisible: auths.OfficeBillingReport,
    },
    {
      route: performanceStats,
      title: "Performance Stats",
      icon: "bar_chart",
      isVisible: auths.PerformanceStats,
    },
    {
      route: eeCodesSearch,
      title: "EE Codes",
      icon: "pin",
      isVisible: auths.EeCodes,
    },
    {
      route: thirdPartyInvoices,
      title: "Third Party Invoices",
      icon: "playlist_add_circle",
      isVisible: auths.ThirdPartyInvoices,
    },
    {
      route: revenueReporting,
      title: "Revenue Reports",
      icon: "request_quote",
      isVisible: auths.RevenueReports,
    },
    {
      route: new Route(products),
      title: "Products",
      icon: "category",
      isVisible: auths.Products,
    },
  ])
    .sortBy((x) => x.title)
    .map((x) => ({
      ...x,
      isVisible: !!x.isVisible,
    }))
    .value();

  const allItems = (
    [homeItem, ...items, logoutItem] as AuthMenuItemProps[]
  ).map((x, index) =>
    x.title === "Project Charges" ? (
      <ProjectChargesNavItem
        {...{
          ...x,
          ...props,
        }}
        key={index}
      />
    ) : x.title === "Projects" ? (
      <ProjectsNavItem
        {...{
          ...x,
          ...props,
          isVisible: !!auths.Projects,
        }}
        key={index}
      />
    ) : (
      <AuthMenuItem
        {...{
          ...x,
          onClick: x.onClick ?? props.closeNav,
        }}
        key={index}
      />
    )
  );

  return <List nonInteractive>{allItems}</List>;
}
