import { ActiveFilter, FilterProvider } from "~/ultra-filter/types";
import React from "react";
import { FilterProviderProps } from "./types";
import FieldOfficePickerFormField from "~/visuals/organisms/FieldOfficePickerFormField";
import {
  useFieldOfficesAsync,
  useOfficeFromParam,
} from "~/refdata2/fieldOffices";
import { FieldOffice } from "~/gql/types";

export function useFieldOfficeProvider(
  props: FilterProviderProps
): FilterProvider {
  const { documentationText: origDocText } = props;
  const officeFromParam = useOfficeFromParam();
  const getOffices = useFieldOfficesAsync();

  const documentationText = `${origDocText} Use "off" for quick filtering.`;
  const type = "officeCode";
  const label = "Office Code";

  return {
    render: () => (
      <FieldOfficePickerFormField
        formField={type}
        label={label}
        helperText=""
      />
    ),
    type,
    label,
    documentationText,
    getSuggestions: async (text: string) => {
      if (!text) {
        return [];
      }
      const result = await getOffices();
      if (!result || result.length === 0) {
        return [];
      }
      return result
        .filter((o) => o.officeCode.toLowerCase().includes(text.toLowerCase()))
        .map((o) => ({ text: o.officeCode, value: o }));
    },
    shouldSuggest: (text: string) => text.toLowerCase().startsWith("off"),
    toFilter: (value: FieldOffice) => ({
      label: `${label}: ${value.officeCode}`,
      type,
      value,
    }),
    filterFromParams: async (params: URLSearchParams) => {
      const paramOffice = params.get(type) || null;
      const office = paramOffice ? await officeFromParam(paramOffice) : null;

      return office
        ? {
            label: `${label}: ${office.officeCode}`,
            value: office,
            type,
          }
        : null;
    },
    toParam: (filter: ActiveFilter) => filter.value?.officeCode ?? "",
  };
}
