import React from "react";
import { useParams } from "react-router-dom";
import PageTitleRow from "~/page-title-row";
import Card from "@material/react-card";
import AllowsProjects from "./AllowsProjects";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { fieldOffices } from "~/routes";
import { MutationForm } from "~/forms/MutationForm";
import { Body1, Body2 } from "@material/react-typography";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import Button from "~/button";
import {
  Employee,
  ValidationError,
  ValidationResponse,
  FieldOffice,
} from "~/gql/types";
import { useAddProjectRequestReviewerMutation } from "./addReviewer.generated";
import { useRemoveProjectRequestReviewerMutation } from "./removeReviewer.generated";
import List, { ListItem } from "@material/react-list";
import Spinner from "~/spinner";
import "./FieldOffice.scss";
import { useUser } from "~/Auth/useUser";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

type FieldOfficePageProps = {
  offices: FieldOffice[];
  refetch: React.DispatchWithoutAction;
};

type ReviewerRowProps = {
  reviewer: Employee;
  refetch: React.DispatchWithoutAction;
  officeCode: string;
  canUpdateRoles: boolean;
};

const ReviewerRow: React.FC<ReviewerRowProps> = ({
  reviewer,
  officeCode,
  refetch,
  canUpdateRoles,
}) => {
  const [removeReviewer, { loading }] =
    useRemoveProjectRequestReviewerMutation();

  const onRemove = async () => {
    const variables = {
      userPrincipalName: reviewer.userPrincipalName,
      officeCode,
    };

    const result = await removeReviewer({ variables });
    const data = result?.data?.fieldOffices?.removeProjectRequestReviewer;

    if (data?.errors && data.errors?.length > 0) {
      const errors: string[] = [];
      data!.errors.forEach((e) => {
        const { argumentName, message } = e as ValidationError;
        if (argumentName) {
          errors.push(`${argumentName}: ${message}`);
        } else if (message) {
          errors.push(message);
        }
      });

      alert({
        key: `${Math.random()}`,
        message: errors.join(" "),
        isSuccess: false,
      });
    } else {
      refetch();
    }
  };

  return (
    <>
      {formatFirstMiddleLast(reviewer)}
      {canUpdateRoles && (
        <Button icon="delete" className="remove-reviewer" onClick={onRemove} />
      )}
      <Spinner open={loading} />
    </>
  );
};

export const FieldOfficePage: React.FC<FieldOfficePageProps> = ({
  offices,
  refetch,
}) => {
  const { office } = useParams<{ office?: string }>();
  const matched = offices.find(
    (x) => x.officeCode.toLowerCase() === office?.toLowerCase()
  );

  const user = useUser();
  const { updateRoles } = user.authorizations.legacyAuth;

  const [addReviewer, { loading }] = useAddProjectRequestReviewerMutation();

  useBreadcrumbs(
    [
      { text: "Field Offices", to: fieldOffices.path },
      { text: `Office: ${matched ? matched.officeCode : "(loading)"}` },
    ],
    [matched]
  );

  if (!office || !matched) {
    return <Spinner open={true} />;
  }

  const runMutation = async (values: { employee: Employee | null }) => {
    const variables = {
      userPrincipalName: values.employee!.userPrincipalName,
      officeCode: matched!.officeCode,
    };

    const result = await addReviewer({ variables });
    return result?.data?.fieldOffices
      ?.addProjectRequestReviewer as ValidationResponse;
  };

  const onSuccess = ({ form }) => {
    form.reset();
    refetch();
  };

  const reviewers = matched?.projectRequestReviewers ?? [];

  return (
    <div className="field-office-page">
      <PageTitleRow title={matched.officeCode} />
      <Card>
        <AllowsProjects fieldOffice={matched} />

        <div className="project-request-reviewers">
          <Body1>Project Request Reviewers:</Body1>
          {reviewers.length > 0 && (
            <List>
              {reviewers.map((r) => (
                <ListItem key={r!.userPrincipalName}>
                  <ReviewerRow
                    reviewer={r!}
                    officeCode={matched.officeCode}
                    refetch={refetch}
                    canUpdateRoles={updateRoles}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {reviewers.length === 0 && <Body2>{"(none)"}</Body2>}

          {updateRoles && (
            <MutationForm
              initialValues={{ employee: null }}
              runMutation={runMutation}
              onSuccess={onSuccess}
            >
              <EmployeePicker
                formField="employee"
                label="Add Project Request Reviewer"
                helperText=""
              />
              <Button primary>Add</Button>
            </MutationForm>
          )}
        </div>
      </Card>
      <Spinner open={loading} />
    </div>
  );
};
