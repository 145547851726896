import {
  Customer,
  Employee,
  ProjectGroup,
  FieldOffice,
  StateCounty,
} from "~/gql/types";
import { ProjectRefData } from "~/refdata/sources";
import { EnumType } from "../visuals/organisms/InlineEnumPicker";
import { ServiceDescription } from "~/refdata2/serviceDescriptions";
import { Moment } from "moment";
import { ProjectOriginationValue } from "./editProjectOrigination";

type ProjectFieldState = {
  name: string;
  required?: boolean;
  toVariables: (val: any) => Record<string, any>;
};

type ProjectFieldMap = Record<string, ProjectFieldState>;

export const toDate = (value: Moment | null): string =>
  `${value ? `${value.format("YYYY-MM-DD")}` : null}`;

export const projectFieldMap: ProjectFieldMap = {
  status: {
    name: "status",
    required: true,
    toVariables: (value: EnumType) => ({ status: value.name }),
  },
  customer: {
    name: "customer",
    required: true,
    toVariables: (value: Customer) => ({ customerNumber: value.number! }),
  },
  dateIn: {
    name: "dateIn",
    required: true,
    toVariables: (value: Moment | null) => ({ dateIn: toDate(value) }),
  },
  clientContactName: {
    name: "clientContactName",
    required: true,
    toVariables: (value: string) => ({ clientContactName: value }),
  },
  officeCode: {
    name: "officeCode",
    required: true,
    toVariables: (value: FieldOffice) => ({ officeCode: value.officeCode }),
  },
  projectManager: {
    name: "projectManager",
    required: true,
    toVariables: (value: Employee) => ({
      projectManager: value.userPrincipalName,
    }),
  },
  projectSupervisor: {
    name: "projectSupervisor",
    required: true,
    toVariables: (value: Employee) => ({
      projectSupervisor: value.userPrincipalName,
    }),
  },
  billingAdmin: {
    name: "billingAdmin",
    required: true,
    toVariables: (value: Employee) => ({
      billingAdmin: value.userPrincipalName,
    }),
  },
  estimatedStartDate: {
    name: "estimatedStartDate",
    toVariables: (value: Moment | null) => ({
      estimatedStartDate: value ? toDate(value) : null,
    }),
  },
  estimatedEndDate: {
    name: "estimatedEndDate",
    toVariables: (value: Moment | null) => ({
      estimatedEndDate: value ? toDate(value) : null,
    }),
  },
  actualStartDate: {
    name: "actualStartDate",
    toVariables: (value: Moment | null) => ({
      actualStartDate: value ? toDate(value) : null,
    }),
  },
  actualEndDate: {
    name: "actualEndDate",
    toVariables: (value: Moment | null) => ({
      actualEndDate: value ? toDate(value) : null,
    }),
  },
  name: {
    name: "name",
    required: true,
    toVariables: (value: string) => ({ name: value }),
  },
  description: {
    name: "description",
    required: true,
    toVariables: (value: string) => ({ description: value }),
  },
  location: {
    name: "location",
    required: true,
    toVariables: (value: StateCounty) => ({
      state: value.state,
      county: value.county,
    }),
  },
  afe: {
    name: "afe",
    toVariables: (value: string | null) => ({ afe: value || null }),
  },
  section: {
    name: "section",
    toVariables: (value: string | null) => ({ section: value || null }),
  },
  township: {
    name: "township",
    toVariables: (value: string | null) => ({ township: value || null }),
  },
  range: {
    name: "range",
    toVariables: (value: string | null) => ({ range: value || null }),
  },
  block: {
    name: "block",
    toVariables: (value: string | null) => ({ block: value || null }),
  },
  survey: {
    name: "survey",
    toVariables: (value: string | null) => ({ survey: value || null }),
  },
  geographicLocation: {
    name: "geographicLocation",
    toVariables: (value: string) => ({ geographicLocation: value ?? "" }),
  },
  networkPath: {
    name: "networkPath",
    toVariables: (value: string | null) => ({ networkPath: value || null }),
  },
  internalNotes: {
    name: "internalNotes",
    toVariables: (value: string) => ({ internalNotes: value ?? "" }),
  },
  invoiceNotes: {
    name: "invoiceNotes",
    toVariables: (value: string) => ({ invoiceNotes: value ?? "" }),
  },
  division: {
    name: "division",
    required: true,
    toVariables: (value: ProjectRefData) => ({ divisionId: value.id }),
  },
  corpLocation: {
    name: "corpLocation",
    required: true,
    toVariables: (value: ProjectRefData) => ({ corpLocationId: value.id }),
  },
  serviceDescription: {
    name: "serviceDescription",
    required: true,
    toVariables: (value: ServiceDescription) => ({
      serviceDescription: value.displayName,
    }),
  },
  governmentFunding: {
    name: "governmentFunding",
    required: true,
    toVariables: (value: EnumType) => ({ governmentFunding: value.name }),
  },
  billingFrequency: {
    name: "billingFrequency",
    required: true,
    toVariables: (value: EnumType) => ({ billingFrequency: value.name }),
  },
  budget: {
    name: "budget",
    toVariables: (value: string | null) => ({
      budget: value ? parseFloat(value) : null,
    }),
  },
  scope: {
    name: "scope",
    required: true,
    toVariables: (value: string | null) => ({ scope: value || null }),
  },
  certifiedPayroll: {
    name: "certifiedPayroll",
    required: false,
    toVariables: (value: boolean) => ({ certifiedPayroll: value }),
  },
  projectGroup: {
    name: "projectGroup",
    required: false,
    toVariables: (value: ProjectGroup | null) => ({
      projectGroupName: value?.name ?? null,
    }),
  },
  projectOrigination: {
    name: "projectOrigination",
    required: true,
    toVariables: (value: ProjectOriginationValue) => ({
      projectOrigination: value.origination,
      projectOriginationName: value.name ?? null,
    }),
  },
  allowThirdPartyInvoice: {
    name: "allowThirdPartyInvoice",
    required: false,
    toVariables: (value: boolean) => ({ allowThirdPartyInvoice: value }),
  },
  po: {
    name: "po",
    required: false,
    toVariables: (value: boolean) => ({ po: value || null }),
  },
  wo: {
    name: "wo",
    required: false,
    toVariables: (value: boolean) => ({ wo: value || null }),
  },
  contractNumber: {
    name: "contractNumber",
    required: false,
    toVariables: (value: boolean) => ({ contractNumber: value || null }),
  },
  glCode: {
    name: "glCode",
    required: false,
    toVariables: (value: boolean) => ({ glCode: value || null }),
  },
  costCenter: {
    name: "costCenter",
    required: false,
    toVariables: (value: boolean) => ({ costCenter: value || null }),
  },
  requisitioner: {
    name: "requisitioner",
    required: false,
    toVariables: (value: boolean) => ({ requisitioner: value || null }),
  },
};
