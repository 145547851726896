import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { viewTaskRateSheet } from "~/routes";
import TaskRateForm, {
  TaskRateFormData,
  useTaskRateCalculator,
} from "~/visuals/organisms/TaskRateForm";
import { FORM_ERROR } from "final-form";
import { Form } from "react-final-form";
import ConfirmationDialog from "~/confirmation-dialog/ConfirmationDialog";
import { TaskRateSheetRouteData } from "~/visuals/pages/TaskRates/TaskRateSheetRouter";
import {
  useDeleteTaskRateMutation,
  useEditTaskRateMutation,
} from "./queries.generated";
import { TaskRateSheet, TaskRateType, TaskRateUnit } from "~/gql/types";

type EditTaskRateProps = {
  rateSheet: TaskRateSheet;
  useReload(): React.DispatchWithoutAction;
};

const EditTaskRate: React.FC<EditTaskRateProps> = ({
  rateSheet,
  useReload,
}) => {
  const [doMutation, { data, loading }] = useEditTaskRateMutation();
  const [doDelete, { data: deleteData, loading: deleteLoading }] =
    useDeleteTaskRateMutation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState("");
  const params = useParams<TaskRateSheetRouteData>();
  const calculator = useTaskRateCalculator();

  const rateName = params.rateName;
  const original = rateSheet.rates!.find((x) => x!.name === rateName);

  const initialValues = {
    ...original,
    product: {
      name: original!.name,
    },
  };
  delete initialValues.name;

  const reloadRateSheet = useReload();
  const parseNum = (val: any) => (val ? parseFloat(val) : null);

  const editTaskRate = async (values: TaskRateFormData) => {
    const rateType = values?.rateType as TaskRateType;

    const { data } = await doMutation({
      variables: {
        oldName: original?.name!,
        newName: values?.product?.name!,
        rateSheetName: rateSheet.name,
        billableAmount: parseNum(values?.billableAmount),
        requiresDaily: values?.requiresDaily,
        rateType: rateType,
        unit: values?.unit as TaskRateUnit,
        usesEmployeeRate: values.usesEmployeeRate,
        certifiedPayroll: values.certifiedPayroll,
        crewSize: rateType === TaskRateType.Field ? +values.crewSize! : 1,
        glAccount: values?.glAccount || null,
      },
    });

    reloadRateSheet();

    const responseErrors = data?.taskRates?.editRate?.errors || [];
    if (responseErrors.length > 0) {
      const errors = {};
      const messages: string[] = [];

      responseErrors
        .map((x) => x!)
        .forEach(({ argumentName, message }) => {
          if (argumentName) {
            errors[argumentName] = message;
          } else if (message) {
            messages.push(message);
            errors[FORM_ERROR] = messages.join(" ");
          }
        });
      return errors;
    }
  };

  const deleteTaskRate = async () => {
    const { data } = await doDelete({
      variables: {
        rateSheetName: rateSheet.name,
        rateName: original?.name!,
      },
    });

    reloadRateSheet();

    const responseErrors = data?.taskRates?.deleteRate?.errors || [];
    if (responseErrors.length > 0) {
      let errors = "";

      responseErrors
        .map((x) => x!)
        .forEach(({ argumentName, message }) => {
          if (argumentName) {
            errors += ` ${argumentName}: ${message}`;
          } else if (message) {
            errors += ` ${message}`;
          }
        });
      setDeleteError(errors);
    }
  };

  if (
    (data &&
      !loading &&
      (data?.taskRates?.editRate?.errors?.length ?? 0) === 0) ||
    (deleteData &&
      !deleteLoading &&
      (deleteData?.taskRates?.deleteRate?.errors?.length ?? 0) === 0)
  ) {
    return <Navigate to={viewTaskRateSheet.toRoute(rateSheet.name).path} />;
  }

  return (
    <>
      {dialogOpen && (
        <ConfirmationDialog
          action="Delete"
          objectName="task rate"
          onConfirmation={() => {
            void deleteTaskRate();
            setDialogOpen(false);
          }}
          onCancel={() => setDialogOpen(false)}
        />
      )}
      <Form
        onSubmit={editTaskRate}
        initialValues={initialValues}
        decorators={[calculator]}
        render={({ handleSubmit, submitError, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TaskRateForm
                errorMessage={submitError || deleteError}
                rateSheetName={rateSheet.name}
                loading={loading || deleteLoading}
                type="Edit"
                onDelete={() => setDialogOpen(true)}
                usesEmployeeRate={values.usesEmployeeRate}
                rateType={values.rateType}
              />
            </form>
          );
        }}
      />
    </>
  );
};

export default EditTaskRate;
