import React from "react";
import { Button } from "rmwc";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { ValidationError } from "~/gql/types";
import { useSnackBar } from "~/snackbar";
import { useApproveThirdPartyInvoiceMutation } from "./approve.generated";
import { useUser } from "~/Auth/useUser";
type ButtonProps = {
  invoiceNumber: string;
  refetch: () => void;
};

export const ApproveButton: React.FC<ButtonProps> = ({
  invoiceNumber,
  refetch,
}) => {
  const user = useUser();

  const [doMutation] = useApproveThirdPartyInvoiceMutation();

  const prompt = useConfirmationPrompt();
  const addAlert = useSnackBar();

  const handleApprove = async () => {
    const result = await doMutation({
      variables: {
        invoiceNumber,
      },
    });

    const res = result?.data?.thirdPartyInvoices?.approve;
    if (res?.errors && res.errors.length > 0) {
      const errors = res.errors as ValidationError[];
      const formErrors: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          formErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          formErrors.push(message);
        }
      });
      addAlert({
        key: `${invoiceNumber}`,
        message: formErrors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: `${invoiceNumber}`,
        message: `${invoiceNumber} approved`,
        isSuccess: true,
      });

      refetch();
    }
  };

  const openPrompt = async () => {
    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, Approve",
      message: "Are you sure you want to approve this?",
      title: `Third Party Invoice ${invoiceNumber}`,
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await handleApprove();
  };
  if (!user.authorizations.legacyAuth.approveThirdPartyInvoices) {
    return null;
  }
  return <Button onClick={openPrompt}>Approve</Button>;
};
