import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTimesheetsQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  workLocation?: Types.InputMaybe<Types.Scalars['String']>;
  status?: Types.InputMaybe<Types.TimesheetStatus>;
  week?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  dateBefore?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  dateAfter?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  reimbursement?: Types.InputMaybe<Types.TimesheetSearchReimbursementOption>;
  payrollAdmin?: Types.InputMaybe<Types.Scalars['String']>;
  timesheets?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  payPeriod?: Types.InputMaybe<Types.PayPeriod>;
  userPrincipalName?: Types.InputMaybe<Types.Scalars['String']>;
  showFuture?: Types.InputMaybe<Types.Scalars['Boolean']>;
  certifiedPayroll?: Types.InputMaybe<Types.Scalars['Boolean']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetTimesheetsQuery = { __typename?: 'QueryModel', timesheets?: { __typename?: 'TimesheetsQueryModel', timesheetSearch?: { __typename?: 'ConnectionOfTimesheet', token?: string | null, records?: Array<{ __typename?: 'Timesheet', weekBeginning: string, timesheetId: string, status: Types.TimesheetStatus, canBeApproved: boolean, canBeRejected: boolean, canBeReset: boolean, canBeSubmitted: boolean, canUpdateFinalPayroll: boolean, expensesReimbursable: number, payroll: number, companyPaid: number, totalExpensesPayable: number, totalMileage: number, employee?: { __typename?: 'Employee', firstName: string, middleInitial: string, lastName: string, payPeriod: Types.PayPeriod, workLocation: string, userPrincipalName: string, finalPayroll?: string | null, isTerminated: boolean, payrollAdmin?: { __typename?: 'Employee', userPrincipalName: string } | null } | null, daySummaries?: Array<{ __typename?: 'WorkDaySummary', date: string, time: string, timeOff: string, projectTime: string, expensesBillable: number, expensesReimbursable: number, totalExpensesPayable: number, payroll: number, companyPaid: number, mileage: number, hasReceipts: boolean } | null> | null, projectsWithDailies?: Array<{ __typename?: 'ProjectWithDailies', name: string, number: number } | null> | null } | null> | null } | null } | null };


export const GetTimesheetsDocument = gql`
    query getTimesheets($searchText: String, $workLocation: String, $status: TimesheetStatus, $week: LocalDate, $dateBefore: LocalDate, $dateAfter: LocalDate, $reimbursement: TimesheetSearchReimbursementOption, $payrollAdmin: String, $timesheets: [String!], $payPeriod: PayPeriod, $userPrincipalName: String, $showFuture: Boolean, $certifiedPayroll: Boolean, $token: String) {
  timesheets {
    timesheetSearch(
      searchText: $searchText
      workLocation: $workLocation
      status: $status
      week: $week
      dateBefore: $dateBefore
      dateAfter: $dateAfter
      reimbursement: $reimbursement
      payrollAdmin: $payrollAdmin
      timesheets: $timesheets
      payPeriod: $payPeriod
      userPrincipalName: $userPrincipalName
      showFuture: $showFuture
      certifiedPayroll: $certifiedPayroll
      token: $token
    ) {
      token
      records {
        weekBeginning
        timesheetId
        employee {
          firstName
          middleInitial
          lastName
          payPeriod
          workLocation
          userPrincipalName
          payrollAdmin {
            userPrincipalName
          }
          finalPayroll
          isTerminated
        }
        status
        canBeApproved
        canBeRejected
        canBeReset
        canBeSubmitted
        canUpdateFinalPayroll
        daySummaries {
          date
          time
          timeOff
          projectTime
          expensesBillable
          expensesReimbursable
          totalExpensesPayable
          payroll
          companyPaid
          mileage
          hasReceipts
        }
        projectsWithDailies {
          name
          number
        }
        expensesReimbursable
        payroll
        companyPaid
        totalExpensesPayable
        totalMileage
      }
    }
  }
}
    `;

/**
 * __useGetTimesheetsQuery__
 *
 * To run a query within a React component, call `useGetTimesheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimesheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimesheetsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      workLocation: // value for 'workLocation'
 *      status: // value for 'status'
 *      week: // value for 'week'
 *      dateBefore: // value for 'dateBefore'
 *      dateAfter: // value for 'dateAfter'
 *      reimbursement: // value for 'reimbursement'
 *      payrollAdmin: // value for 'payrollAdmin'
 *      timesheets: // value for 'timesheets'
 *      payPeriod: // value for 'payPeriod'
 *      userPrincipalName: // value for 'userPrincipalName'
 *      showFuture: // value for 'showFuture'
 *      certifiedPayroll: // value for 'certifiedPayroll'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetTimesheetsQuery(baseOptions?: Apollo.QueryHookOptions<GetTimesheetsQuery, GetTimesheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimesheetsQuery, GetTimesheetsQueryVariables>(GetTimesheetsDocument, options);
      }
export function useGetTimesheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimesheetsQuery, GetTimesheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimesheetsQuery, GetTimesheetsQueryVariables>(GetTimesheetsDocument, options);
        }
export type GetTimesheetsQueryHookResult = ReturnType<typeof useGetTimesheetsQuery>;
export type GetTimesheetsLazyQueryHookResult = ReturnType<typeof useGetTimesheetsLazyQuery>;
export type GetTimesheetsQueryResult = Apollo.QueryResult<GetTimesheetsQuery, GetTimesheetsQueryVariables>;