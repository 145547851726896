import React from "react";
import Table from "~/old-table";
import TitledCard from "~/titled-card";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import { FieldOffice } from "~/gql/types";
import TableHeader from "~/old-table-header";
import Spinner from "~/spinner";
import _ from "lodash";
import "./FieldOfficeList.scss";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { fieldOffices } from "~/routes";
import { LinkClickHandler } from "~/link-click-handler";

const FieldOfficeRow: React.FC<{ data: FieldOffice }> = ({ data }) => {
  const path = `${fieldOffices.path}/${data.officeCode}`;

  return (
    <LinkClickHandler path={path}>
      <TableCell>{data.officeCode}</TableCell>
      <TableCell>{data.allowsProjects ? "Yes" : "No"}</TableCell>
    </LinkClickHandler>
  );
};

export type FieldOfficeListProps = {
  fieldOffices: FieldOffice[];
  isLoading: boolean;
};

const FieldOfficeList: React.FC<FieldOfficeListProps> = ({
  fieldOffices,
  isLoading,
}) => {
  useBreadcrumbs([{ text: "Field Offices" }], []);

  const orderedFieldOffices = _.chain(fieldOffices)
    .orderBy((x) => x.officeCode)
    .value();

  return (
    <div className="field-offices">
      <Spinner open={isLoading} />

      <TitledCard title="Field Office Codes">
        <Table compact>
          <TableHeader>
            <TableCell text="Office code" />
            <TableCell text="Allows projects" />
          </TableHeader>
          {orderedFieldOffices.map((fieldOffice, idx) => (
            <TableContent
              key={idx}
              trailingSeparator={idx !== fieldOffices.length - 1}
              className="field-office"
            >
              <FieldOfficeRow data={fieldOffice} />
            </TableContent>
          ))}
        </Table>
      </TitledCard>
    </div>
  );
};

export default FieldOfficeList;
