import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectStatusQueryVariables = Types.Exact<{
  startDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  endDate?: Types.InputMaybe<Types.Scalars['LocalDate']>;
  projectManager?: Types.InputMaybe<Types.Scalars['String']>;
  billingAdmin?: Types.InputMaybe<Types.Scalars['String']>;
  customerNumber?: Types.InputMaybe<Types.Scalars['String']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ProjectStatusQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', projectStatus?: Array<{ __typename?: 'ProjectStatusItem', projectNumber: number, unbilledCharges: number, billingAdmin?: { __typename?: 'Employee', firstName: string, middleInitial: string, lastName: string, userPrincipalName: string } | null, projectManager?: { __typename?: 'Employee', firstName: string, middleInitial: string, lastName: string, userPrincipalName: string } | null } | null> | null } | null };


export const ProjectStatusDocument = gql`
    query ProjectStatus($startDate: LocalDate, $endDate: LocalDate, $projectManager: String, $billingAdmin: String, $customerNumber: String, $searchText: String) {
  projects {
    projectStatus(
      startDate: $startDate
      endDate: $endDate
      projectManager: $projectManager
      billingAdmin: $billingAdmin
      customerNumber: $customerNumber
      searchText: $searchText
    ) {
      billingAdmin {
        firstName
        middleInitial
        lastName
        userPrincipalName
      }
      projectManager {
        firstName
        middleInitial
        lastName
        userPrincipalName
      }
      projectNumber
      unbilledCharges
    }
  }
}
    `;

/**
 * __useProjectStatusQuery__
 *
 * To run a query within a React component, call `useProjectStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStatusQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      projectManager: // value for 'projectManager'
 *      billingAdmin: // value for 'billingAdmin'
 *      customerNumber: // value for 'customerNumber'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useProjectStatusQuery(baseOptions?: Apollo.QueryHookOptions<ProjectStatusQuery, ProjectStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectStatusQuery, ProjectStatusQueryVariables>(ProjectStatusDocument, options);
      }
export function useProjectStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectStatusQuery, ProjectStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectStatusQuery, ProjectStatusQueryVariables>(ProjectStatusDocument, options);
        }
export type ProjectStatusQueryHookResult = ReturnType<typeof useProjectStatusQuery>;
export type ProjectStatusLazyQueryHookResult = ReturnType<typeof useProjectStatusLazyQuery>;
export type ProjectStatusQueryResult = Apollo.QueryResult<ProjectStatusQuery, ProjectStatusQueryVariables>;