import React from "react";
import { Body1, Body2 } from "@material/react-typography";
import TitledCard from "~/titled-card";
import "./ProjectCard.scss";
import moment from "moment";
import { TextHighlighter } from "~/text-highlighter";
import { project as projectRoute } from "~/routes";
import _ from "lodash";
import { CardSelectCheckbox } from "~/search-page-wrapper/BulkCheckboxes/CardSelectCheckbox";
import { Project } from "~/gql/types";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterOptions } from "./FilterForm";
import { LinkClickHandler } from "~/link-click-handler";
import { ToolTip } from "~/tooltip";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

type ProjectCardProps = {
  project: Project;
  state: State<Project, FilterOptions>;
  dispatch: React.Dispatch<Action<Project, FilterOptions>>;
};

const Highlight: React.FC<{ text: string; className?: string }> = ({
  text,
  className,
}) => <TextHighlighter {...{ text, className }} />;

type ItemProps = {
  label: string;
  value: string;
  highlight?: boolean;
};

const Item: React.FC<ItemProps> = ({ label, value, highlight }) => (
  <span className="item">
    <Body2 className="label">{`${label}:`}</Body2>
    <Body2 className="value">
      {highlight ? <Highlight text={value} /> : value}
    </Body2>
  </span>
);

const Row: React.FC<{ className: string }> = ({ children, className }) => (
  <div className={`row ${className}`}>{children}</div>
);

const getRateSheetName = (project: Project, type: string): string => {
  const rateSheets = project[type] ?? [];
  const rateSheet =
    rateSheets.length > 0
      ? _.orderBy(rateSheets, ["effectiveDate"], ["desc"])[0]!.rateSheet!.name
      : "";

  return rateSheet;
};

export const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  state,
  dispatch,
}) => {
  const {
    name,
    number,
    customer,
    dateIn,
    status,
    location,
    projectManager,
    projectSupervisor,
    billingAdmin,
  } = project;

  const topRow = (
    <Row className="top">
      <CardSelectCheckbox {...{ state, dispatch, item: project }} />
      <span>
        <Highlight text={name} />
        <span className="small-text pid">
          <Highlight text={`(${number})`} />
        </span>
      </span>
      <Body1 title="Customer">
        <Highlight text={customer!.name} />
        <span className="small-text">
          (<Highlight text={`${customer!.number}`} />)
        </span>
      </Body1>

      <Item {...{ label: "Status", value: status }} />
    </Row>
  );

  const expenseRateSheet = getRateSheetName(project, "expenseRateSheets");
  const taskRateSheet = getRateSheetName(project, "taskRateSheets");

  return (
    <LinkClickHandler
      path={projectRoute.toRoute(number).path}
      className={"project-search-card"}
    >
      <TitledCard title={topRow}>
        <Row className="middle">
          <Item
            {...{
              label: "State/County",
              value: `${location!.state}-${location!.county}`,
            }}
          />
          <Item
            {...{ label: "Client Contact", value: project.clientContactName }}
          />
          <Item {...{ label: "Task Rate Sheet", value: taskRateSheet }} />
          <Item
            {...{
              label: "PM",
              value: formatFirstMiddleLast(projectManager!),
            }}
          />
        </Row>
        <Row className="middle">
          <Item {...{ label: "Office", value: project.officeCode }} />
          <Item
            {...{
              label: "Date In",
              value: moment(dateIn).format("MM/DD/YYYY"),
            }}
          />
          <Item {...{ label: "Expense Rate Sheet", value: expenseRateSheet }} />
          <Item
            {...{
              label: "PS",
              value: formatFirstMiddleLast(projectSupervisor!),
            }}
          />
        </Row>
        <Row className="bottom">
          <Item {...{ label: "Budget", value: `$${project.budget ?? 0}` }} />
          <ToolTip text={project.afe ?? ""}>
            <Item {...{ label: "AFE", value: `${project.afe ?? ""}` }} />
          </ToolTip>
          <Item
            {...{
              label: "Project Group",
              value: project.projectGroup?.name ?? "",
            }}
          />
          <Item
            {...{
              label: "BA",
              value: formatFirstMiddleLast(billingAdmin!),
            }}
          />
        </Row>
      </TitledCard>
    </LinkClickHandler>
  );
};
