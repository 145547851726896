import React from "react";
import { useNavigate } from "react-router-dom";
import {
  taskRateSheets,
  createTaskRate,
  editTaskRate,
  assignSuccessorTaskRateSheet,
  editTaskRateSheet,
} from "~/routes";
import TableCell from "~/old-table-cell";
import Table from "~/old-table";
import TableHeader from "~/old-table-header";
import TableContent from "~/old-table-content";
import PageTitleRow from "~/page-title-row";
import { Cell, Row } from "@material/react-layout-grid";
import TitledCard from "~/titled-card";
import Button from "~/button";
import { TaskRateSheet, TaskRate } from "~/gql/types";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import "./TaskRateSheet.scss";
import _ from "lodash";
import { PopupMenuListItem } from "~/popup-menu-list";
import { RateSheetTitle } from "~/rate-sheet-title";
import { ActionsMenu } from "~/actions-menu";
import { useUser } from "~/Auth/useUser";

const TaskRateItem: React.FC<{ taskRate: TaskRate }> = ({ taskRate }) => {
  const {
    name,
    billableAmount,
    requiresDaily,
    rateType,
    unit,
    usesEmployeeRate,
    crewSize,
  } = taskRate;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <TableCell text={name} />
      <TableCell
        text={
          usesEmployeeRate
            ? "* employee rate"
            : formatter.format(billableAmount!)
        }
      />
      <TableCell text={rateType} />
      <TableCell text={unit} />
      <TableCell text={requiresDaily ? "Yes" : "No"} />
      <TableCell text={`${crewSize}`} />
    </>
  );
};

type RateSheetProps = {
  rateSheet: TaskRateSheet;
  useReload(): React.DispatchWithoutAction;
};

const RateSheet: React.FC<RateSheetProps> = ({ rateSheet }) => {
  useBreadcrumbs(
    [
      { text: "Task Rate Sheets", to: taskRateSheets.path },
      { text: rateSheet?.name },
    ],
    [rateSheet]
  );

  const user = useUser();
  const canEdit =
    user.authorizations.legacyAuth.manageRateSheets && !rateSheet.archived;

  const rates = _.orderBy(rateSheet?.rates || [], (x) => x!.name);

  const items = [
    {
      label: "Edit Rate Sheet",
      onClick: () => navigate(editTaskRateSheet.toRoute(rateSheet?.name).path),
      key: 1,
      dataItem: "edit-rate-sheet",
    },
    {
      label: "Assign Successor",
      onClick: () =>
        navigate(assignSuccessorTaskRateSheet.toRoute(rateSheet?.name).path),
      key: 2,
      dataItem: "assign-successor",
    },
  ].filter((x) => x) as PopupMenuListItem[];

  const cardAction = (
    <Button route={createTaskRate.toRoute(rateSheet?.name)}>
      <i className="material-icons mdc-button__icon">add</i>
      Create task rate
    </Button>
  );

  const navigate = useNavigate();

  return (
    <div className="task-rate-sheet-view">
      <PageTitleRow
        title={<RateSheetTitle {...{ rateSheet }} />}
        actions={canEdit ? <ActionsMenu {...{ items }} /> : <></>}
      />

      <Row>
        <Cell columns={12}>
          <TitledCard
            title="Rates"
            className="rates-card"
            actions={canEdit ? cardAction : <></>}
          >
            <Table>
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Billable" />
                <TableCell text="Task Type" />
                <TableCell text="Task Unit" />
                <TableCell text="Requires Daily?" />
                <TableCell text="Crew Size" />
              </TableHeader>
              {rates.map((taskRate, idx) => (
                <TableContent
                  key={idx}
                  trailingSeparator={idx !== rates.length - 1}
                  className="rate"
                  onClick={
                    canEdit
                      ? () =>
                          navigate(
                            editTaskRate.toRoute(
                              rateSheet?.name,
                              taskRate!.name
                            ).path
                          )
                      : () => undefined
                  }
                >
                  <TaskRateItem taskRate={taskRate!} />
                </TableContent>
              ))}
            </Table>
          </TitledCard>
        </Cell>
      </Row>
    </div>
  );
};

export default RateSheet;
