import React from "react";
import { TimeSheetDisplay } from "~/visuals/organisms/TimesheetDisplay";
import { FilterForm, FilterFormProps } from "../MyWorkWeeks/FilterForm";

type TimesheetReviewProps = {
  FilterFormComponent?: React.FC<FilterFormProps>;
};
export const ReviewWorkWeeks: React.FC<TimesheetReviewProps> = (props) => (
  <TimeSheetDisplay
    // @ts-expect-error TODO sort this out
    FilterFormComponent={props.FilterFormComponent ?? FilterForm}
    mode={"ReviewTimesheets"}
  />
);
