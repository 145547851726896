import { Moment } from "moment";
import { Project } from "~/gql/types";

type Page = "Form" | "Project";

type CoreState = {
  projects: Project[];
  projectNumbers: number[];
  loading: boolean;
  token: string | null;
  rateSheet: string;
  page: Page;
  successor: string | null;
  effectiveDate: Moment | null;
  reload: React.DispatchWithoutAction;
};

type TaggedState<T extends string> = { tag: T } & CoreState;

export type ShowingForm = TaggedState<"ShowingForm">;
export type ProjectsLoading = TaggedState<"ProjectsLoading">;
export type MoreProjectsLoading = TaggedState<"MoreProjectsLoading">;
export type ShowingProjects = TaggedState<"ShowingProjects">;

export type State =
  | ShowingForm
  | ProjectsLoading
  | MoreProjectsLoading
  | ShowingProjects;

type TaggedAction<T extends string> = { tag: T };

type LoadProjects = TaggedAction<"LoadProjects"> & {
  data: Project[];
  numbers: number[];
  token: string | null;
};
type GetMoreProjects = TaggedAction<"GetMoreProjects">;
type LoadMoreProjects = TaggedAction<"LoadMoreProjects"> & {
  data: Project[];
  token: string | null;
};
type ChangePage = TaggedAction<"ChangePage"> & { page: Page };
type UpdateField = TaggedAction<"UpdateField"> & {
  fieldName: string;
  value: any;
};

export type Action =
  | LoadProjects
  | ShowingProjects
  | GetMoreProjects
  | LoadMoreProjects
  | ChangePage
  | UpdateField;

export function reduceShowingForm(state: ShowingForm, action: Action): State {
  if (action.tag === "UpdateField") {
    return {
      ...state,
      projectNumbers: [],
      projects: [],
      token: null,
      [action.fieldName]: action.value,
    };
  }

  if (action.tag === "ChangePage") {
    return {
      ...state,
      tag: "ProjectsLoading",
      loading: true,
      page: action.page,
    };
  }

  return state;
}

export function reduceProjectsLoading(
  state: ProjectsLoading,
  action: Action
): State {
  if (action.tag === "LoadProjects") {
    return {
      ...state,
      tag: "ShowingProjects",
      loading: false,
      projects: action.data,
      projectNumbers: action.numbers,
      token: action.token,
    };
  }

  return state;
}

export function reduceMoreProjectsLoading(
  state: MoreProjectsLoading,
  action: Action
): State {
  if (action.tag === "LoadMoreProjects") {
    return {
      ...state,
      tag: "ShowingProjects",
      loading: false,
      projects: [...state.projects, ...action.data],
      token: action.token,
    };
  }

  return state;
}

export function reduceShowingProjects(
  state: ShowingProjects,
  action: Action
): State {
  if (action.tag === "ChangePage") {
    return {
      ...state,
      tag: "ShowingForm",
      page: action.page,
    };
  }

  if (action.tag === "GetMoreProjects") {
    return {
      ...state,
      tag: "MoreProjectsLoading",
      loading: true,
    };
  }

  return state;
}

export function reducer(state: State, action: Action): State {
  if (state.tag === "ProjectsLoading") {
    return reduceProjectsLoading(state, action);
  }

  if (state.tag === "MoreProjectsLoading") {
    return reduceMoreProjectsLoading(state, action);
  }

  if (state.tag === "ShowingProjects") {
    return reduceShowingProjects(state, action);
  }

  if (state.tag === "ShowingForm") {
    return reduceShowingForm(state, action);
  }

  return state;
}
