import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import { FieldOffice } from "~/gql/types";
import { useFieldOfficesAsync } from "~/refdata2/fieldOffices";

const format = (item: FieldOffice, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="field-office-picker-menuitem">
      <TextHighlighter text={item.officeCode} search={searchRegex} />
    </div>
  );
};

const chipFormat = (item: FieldOffice) => item.officeCode;

const FieldOfficePickerFormField: React.FC<FormFieldProps> = (props) => {
  const getOffices = useFieldOfficesAsync();

  const getData = async (searchText: string) => {
    if (!searchText) {
      return [];
    }
    const result = (await getOffices()) ?? [];
    return result.filter((t) =>
      t.officeCode.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const args = {
    ...props,
    getData,
    format,
    chipFormat,
  } as TypeaheadProps<FieldOffice>;

  return (
    <div className="field-office-picker">
      <TypeaheadFormField {...args} />
    </div>
  );
};

export default FieldOfficePickerFormField;
