import React, { useState } from "react";
import { InlineTextField } from "~/inline-text-field";
import { useInlineFormField, useInlineFormState } from "~/inline-fields-form";
import { EmployeeRole } from "~/refdata/useEmployeeSearch";
import { InlineEmployeePicker } from "~/visuals/organisms/InlineEmployeePicker";
import { InlineFieldOfficePicker } from "~/ProjectForm/InlineFieldOfficePicker";
import { InlineStateCountyPicker } from "~/ProjectForm/InlineStateCountyPicker";
import {
  FieldOffice,
  StateCounty,
  Customer,
  Employee,
  ProjectGroup,
} from "~/gql/types";
import { InlineDatePicker } from "~/visuals/organisms/InlineDatePicker";
import { Moment } from "moment";
import "./EditProjectComponents.scss";
import { ProjectRefData } from "~/refdata/sources";
import { InlineDivisionPicker } from "~/ProjectForm/InlineDivisionPicker";
import { InlineCorpLocationPicker } from "~/ProjectForm/InlineCorpLocationPicker";
import {
  EnumType,
  InlineEnumPicker,
} from "~/visuals/organisms/InlineEnumPicker";
import cn from "classnames";
import { ServiceDescription } from "~/refdata2/serviceDescriptions";
import { InlineServiceDescriptionPicker } from "~/ProjectForm/InlineServiceDescriptionPicker";
import { InlineCustomerPicker } from "~/visuals/organisms/InlineCustomerPicker";
import "~/visuals/organisms/BinaryPickerFormField/BinaryPickerFormField.scss";
import { CheckBox } from "~/checkbox";
import { InlineProjectGroupPicker } from "./InlineProjectGroupPicker";
export { EditProjectOrigination } from "./editProjectOrigination";

export type BaseProps = {
  name: string;
};

type TextFieldProps = BaseProps & {
  textarea?: boolean;
  onFocus?: React.FocusEventHandler<any>;
  onBlur?: React.FocusEventHandler<any>;
};

export const EditProjectTextField: React.FC<TextFieldProps> = (props) => {
  const { name, textarea, onFocus, onBlur } = props;
  const { value, error, setError, updateValue, required, disabled } =
    useInlineFormField(name);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValue(name, e.target.value);
  };

  const onBlurred = (e) => {
    if (required && !value) {
      setError(name, "Required");
    }
    onBlur && onBlur(e);
  };

  return (
    <InlineTextField
      {...{
        ...props,
        disabled,
        name,
        required,
        value: value ?? "",
        onChange,
        errorMessage: error ?? "",
        onBlur: onBlurred,
        onFocus,
        textarea,
      }}
    />
  );
};

export const EditProjectNotes: React.FC<BaseProps> = (props) => {
  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  const notesStyle = cn("project-notes", { focused });

  return (
    <div className={notesStyle}>
      <div className="scrollable-notes">
        <EditProjectTextField
          {...{
            ...props,
            textarea: true,
            onBlur,
            onFocus,
          }}
        />
      </div>
    </div>
  );
};

export const EditStateCounty: React.FC<BaseProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<StateCounty>(props);

  return (
    <InlineStateCountyPicker
      {...{
        ...props,
        disabled,
        required,
        value,
        onChange,
        submitError: error ?? "",
        onBlur,
      }}
    />
  );
};

type EditEmployeeProps = BaseProps & {
  roleName: EmployeeRole;
};

export const EditEmployee: React.FC<EditEmployeeProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<Employee>(props);

  return (
    <InlineEmployeePicker
      {...{
        ...props,
        disabled,
        required,
        value: value || null,
        onChange,
        submitError: error ?? "",
        onBlur,
        roleName: props.roleName,
      }}
    />
  );
};

export const EditCustomer: React.FC<
  BaseProps & { includePending?: boolean }
> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<Customer>(props);

  return (
    <InlineCustomerPicker
      {...{
        ...props,
        disabled,
        required,
        value: value || null,
        onChange,
        submitError: error ?? "",
        onBlur,
        includePending: props.includePending,
      }}
    />
  );
};

export const EditOfficeCode: React.FC<BaseProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<FieldOffice>(props);

  return (
    <InlineFieldOfficePicker
      {...{
        ...props,
        disabled,
        required,
        value,
        onChange,
        submitError: error ?? "",
        onBlur,
      }}
    />
  );
};

export const EditDate: React.FC<BaseProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<Moment>(props);

  return (
    <div className="edit-project-date">
      <InlineDatePicker
        {...{
          ...props,
          disabled,
          value,
          required,
          onChange,
          submitError: error ?? "",
          onBlur,
        }}
      />
      &nbsp;
      {value ? `(${value.fromNow()})` : ""}
    </div>
  );
};

export const EditDivision: React.FC<BaseProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<ProjectRefData>(props);

  return (
    <InlineDivisionPicker
      {...{
        ...props,
        disabled,
        required,
        value: value || null,
        onChange,
        submitError: error ?? "",
        onBlur,
      }}
    />
  );
};

export const EditServiceDescription: React.FC<BaseProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<ServiceDescription>(props);

  return (
    <InlineServiceDescriptionPicker
      {...{
        ...props,
        disabled,
        required,
        value: value || null,
        onChange,
        submitError: error ?? "",
        onBlur,
      }}
    />
  );
};

export const EditCorpLocation: React.FC<BaseProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<ProjectRefData>(props);

  return (
    <InlineCorpLocationPicker
      {...{
        ...props,
        disabled,
        required,
        value: value || null,
        onChange,
        submitError: error ?? "",
        onBlur,
      }}
    />
  );
};

type EditEnumProps = BaseProps & {
  enumType: string;
};

export const EditEnumType: React.FC<EditEnumProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<EnumType>(props);

  return (
    <InlineEnumPicker
      {...{
        ...props,
        disabled,
        required,
        value: value || null,
        onChange,
        submitError: error ?? "",
        onBlur,
      }}
    />
  );
};

export const EditCheckbox: React.FC<BaseProps> = (props) => {
  const { onChange, value, disabled } = useGetProps<boolean>(props);

  return (
    <CheckBox
      checked={value}
      onChange={() => onChange(!value)}
      disabled={disabled}
    />
  );
};

export const EditProjectBudget: React.FC<TextFieldProps> = (props) => {
  const { name } = props;
  const { value, error, setError, updateValue, required, disabled } =
    useInlineFormField(name);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValue(name, e.target.value);
  };

  const onBlur = () => {
    if (required && !value) {
      setError(name, "Required");
    }
  };

  return (
    <div className="edit-project-budget">
      <span>$</span>
      <InlineTextField
        {...{
          ...props,
          disabled,
          name,
          required,
          value: value ?? "",
          onChange,
          errorMessage: error ?? "",
          onBlur,
          currency: true,
        }}
      />
    </div>
  );
};

export const EditProjectGroup: React.FC<BaseProps> = (props) => {
  const { error, onChange, onBlur, value, required, disabled } =
    useGetProps<ProjectGroup>(props);

  const formState = useInlineFormState();

  const customerNumber = formState.fields.customer.value?.number;

  return (
    <InlineProjectGroupPicker
      {...{
        ...props,
        disabled,
        required,
        value: value || null,
        onChange,
        submitError: error ?? "",
        onBlur,
        customerNumber,
      }}
    />
  );
};

export function useGetProps<T>(props: BaseProps) {
  const { name } = props;
  const { value, error, setError, updateValue, required, disabled } =
    useInlineFormField(name);

  const onChange = (value: T | null) => {
    updateValue(name, value);
  };

  const onBlur = () => {
    if (required && (value === null || value === undefined || value === "")) {
      setError(name, "Required");
    }
  };

  return { onChange, onBlur, error, value, required, disabled, setError };
}
