import React from "react";
import {
  MobileAllowanceExpenseRate,
  MobileAllowanceExpense,
} from "~/gql/types";
import MobileAllowanceForm from "~/visuals/organisms/Expenses/ExpenseForm/MobileAllowanceTypeForm";

import { useWorkDayApi } from "~/WorkDayApi";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";

type EditMobileAllowanceProps = BaseExpenseFormProps & {
  expenseRate: MobileAllowanceExpenseRate;
  expenseEntry: MobileAllowanceExpense;
};

const EditMobileAllowanceExpense: React.FC<EditMobileAllowanceProps> = (
  props
) => {
  const { expenseEntry } = props;

  const { editMobileAllowanceExpense } = useWorkDayApi();

  const editExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc
  ) => {
    const res = await editMobileAllowanceExpense({
      ...defaultVals(values),
      state: values?.location?.state,
      county: values?.location?.county,
      expenseId: expenseEntry.id!,
      payableOverride: values?.payableOverride || null,
    });

    return res;
  };

  const uniqueInitVals: UniqueInitVals = [
    {
      location: expenseEntry.location ?? null,
    },
  ];

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: editExpense,
        expenseType: "Mobile Allowance",
        formType: "Edit",
      }}
    >
      <MobileAllowanceForm {...props} />
    </ExpensePageWrapper>
  );
};

export default EditMobileAllowanceExpense;
