import React, { useState } from "react";
import { Project } from "~/visuals/pages/Project/ProjectDetails/types";
import ClickSwallower from "~/click-swallower";
import { ProjectChargeForm } from "~/project-charge-form";
import { ChargeFormMutation } from "~/project-charge-form/types";
import { SingleChargeLoader } from "~/single-charge-loader";
import { Employee, ProjectCharge } from "~/gql/types";

type AddChargeProps = {
  project: Project;
  addCharge: (charge: ProjectCharge) => void;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  employee?: Employee;
  mutation: ChargeFormMutation;
  _FormComponent: typeof ProjectChargeForm;
};

export const AddCharge: React.FC<AddChargeProps> = ({
  project,
  addCharge,
  open,
  setOpen,
  employee,
  mutation,
  _FormComponent,
}) => {
  const [chargeId, setChargeId] = useState<number | null>(null);

  const ChargeFormComponent = _FormComponent ?? ProjectChargeForm;

  return (
    <>
      <ClickSwallower>
        <ChargeFormComponent
          {...{
            project,
            open,
            setOpen,
            chargeEmployee: employee,
            mutation,
            onSuccessFn: (id: number | null) => setChargeId(id),
            totalSelected: 1,
          }}
        />
      </ClickSwallower>
      {chargeId && (
        <SingleChargeLoader
          {...{
            dependency: !!chargeId,
            id: chargeId!,
            callback: (charge: ProjectCharge) => {
              addCharge(charge);
              setChargeId(null);
            },
          }}
        />
      )}
    </>
  );
};
