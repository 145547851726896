import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeSearchQueryVariables = Types.Exact<{
  workLocation?: Types.InputMaybe<Types.Scalars['String']>;
  token?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.EmployeeSortBy>;
  status?: Types.InputMaybe<Types.EmployeeStatus>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EmployeeSearchQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', search?: { __typename?: 'ConnectionOfEmployee', token?: string | null, records?: Array<{ __typename?: 'Employee', email: string, workLocation: string, firstName: string, middleInitial: string, lastName: string, isMobileEmployee: boolean, isTerminated: boolean, ultiProId: string, userPrincipalName: string, timeEntryMethod: string, finalPayroll?: string | null, fieldOffice?: { __typename?: 'FieldOffice', officeCode: string, allowsProjects: boolean } | null, payrollAdmin?: { __typename?: 'Employee', ultiProId: string, firstName: string, middleInitial: string, lastName: string } | null, roles?: Array<{ __typename?: 'EmployeeRole', roleName: string } | null> | null } | null> | null } | null } | null };


export const EmployeeSearchDocument = gql`
    query EmployeeSearch($workLocation: String, $token: String, $sortBy: EmployeeSortBy, $status: EmployeeStatus, $searchText: String) {
  employees {
    search(
      workLocation: $workLocation
      token: $token
      sortBy: $sortBy
      status: $status
      searchText: $searchText
    ) {
      token
      records {
        email
        workLocation
        firstName
        middleInitial
        lastName
        isMobileEmployee
        isTerminated
        ultiProId
        userPrincipalName
        timeEntryMethod
        fieldOffice {
          officeCode
          allowsProjects
        }
        payrollAdmin {
          ultiProId
          firstName
          middleInitial
          lastName
        }
        roles {
          roleName
        }
        finalPayroll
      }
    }
  }
}
    `;

/**
 * __useEmployeeSearchQuery__
 *
 * To run a query within a React component, call `useEmployeeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeSearchQuery({
 *   variables: {
 *      workLocation: // value for 'workLocation'
 *      token: // value for 'token'
 *      sortBy: // value for 'sortBy'
 *      status: // value for 'status'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useEmployeeSearchQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeSearchQuery, EmployeeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeSearchQuery, EmployeeSearchQueryVariables>(EmployeeSearchDocument, options);
      }
export function useEmployeeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeSearchQuery, EmployeeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeSearchQuery, EmployeeSearchQueryVariables>(EmployeeSearchDocument, options);
        }
export type EmployeeSearchQueryHookResult = ReturnType<typeof useEmployeeSearchQuery>;
export type EmployeeSearchLazyQueryHookResult = ReturnType<typeof useEmployeeSearchLazyQuery>;
export type EmployeeSearchQueryResult = Apollo.QueryResult<EmployeeSearchQuery, EmployeeSearchQueryVariables>;