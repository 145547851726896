import React, { useState } from "react";
import { TimeOffEntry } from "~/gql/types";
import TimeOffEntryForm, {
  FormData,
} from "~/visuals/organisms/TimeOffEntryForm";
import moment from "moment";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useWorkDayApi } from "~/WorkDayApi";
import { useEeCodes } from "~/refdata2/eeCodes";

type EditTimeOffEntryProps = {
  timeOffEntry: TimeOffEntry;
  date: string;
};

const EditTimeOffEntry: React.FC<EditTimeOffEntryProps> = ({
  timeOffEntry,
  date,
}) => {
  const { editTimeOffEntry, deleteTimeOffEntry, breadcrumbs } = useWorkDayApi();

  useBreadcrumbs(
    [...breadcrumbs, { text: `Edit Time Off Entry ${timeOffEntry.id ?? ""}` }],
    [breadcrumbs, timeOffEntry]
  );

  const [loading, setLoading] = useState(false);

  const eeCodes = useEeCodes();
  const selectedEeCode =
    eeCodes?.find((x) => x.eeCode === timeOffEntry.eeCode) ?? null;

  const durationFormat = (d) => {
    if (!d) {
      return null;
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm:ss");
  };

  const editTimeOff = async (values: FormData) => {
    setLoading(true);
    const res = await editTimeOffEntry({
      timeOffEntryId: timeOffEntry.id,
      hours: durationFormat(values.hours)!,
      timeOffType: values.timeOffType!,
      eeCode: values.eeCode?.eeCode!,
      adminNotes: values.adminNotes || null,
    });
    setLoading(false);

    return res;
  };

  const deleteTimeOff = async () => {
    setLoading(true);
    const res = await deleteTimeOffEntry({
      timeOffEntryId: timeOffEntry.id,
    });
    setLoading(false);

    return res;
  };

  const initialValues = {
    eeCode: selectedEeCode,
    hours: moment.duration(timeOffEntry.hours),
    timeOffType: timeOffEntry.timeOffType,
    adminNotes: null,
  };

  return (
    <>
      <TimeOffEntryForm
        type="Edit"
        handleSubmit={editTimeOff}
        initialValues={initialValues as FormData}
        date={date}
        loading={loading}
        onDelete={deleteTimeOff}
      />
    </>
  );
};

export default EditTimeOffEntry;
