import React from "react";
import { FieldOffice } from "~/gql/types";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import { Form } from "react-final-form";
import { useSnackBar } from "~/snackbar";
import { useUpdateFieldOfficeMutation } from "./query.generated";
import Spinner from "~/spinner";

type AllowsProjectsProps = {
  fieldOffice: FieldOffice;
};

type FormData = {
  allowsProjects: boolean;
};

const AllowsProjects: React.FC<AllowsProjectsProps> = ({ fieldOffice }) => {
  const [doMutation, { loading }] = useUpdateFieldOfficeMutation();
  const addAlert = useSnackBar();

  const updateFieldOffice = async (values: FormData) => {
    const { data } = await doMutation({
      variables: {
        officeCode: fieldOffice.officeCode,
        allowsProjects: values.allowsProjects,
      },
    });

    const responseErrors =
      data?.fieldOffices?.updateProjectAffinity?.errors || [];
    if (responseErrors.length > 0) {
      responseErrors
        .map((x) => x!)
        .forEach(({ message }) => {
          if (message) {
            addAlert({
              key: fieldOffice.officeCode,
              message: message,
              isSuccess: false,
            });
          }
        });
    } else if (data) {
      addAlert({
        key: fieldOffice.officeCode,
        message: `${fieldOffice.officeCode} updated.`,
        isSuccess: true,
      });
    }
  };

  return (
    <Form
      onSubmit={updateFieldOffice}
      initialValues={{ allowsProjects: fieldOffice.allowsProjects }}
      render={({ handleSubmit, form }) => {
        return (
          <form
            onChange={() => {
              void form.submit();
            }}
            onSubmit={handleSubmit}
          >
            <BinaryPickerFormField
              helperText=""
              label="Allows Projects"
              formField="allowsProjects"
            />
            <Spinner open={loading} />
          </form>
        );
      }}
    />
  );
};

export default AllowsProjects;
