import React from "react";
import WarningMessage from "~/warning-message";
import { Project, ProjectStatus } from "~/gql/types";
import "./InactiveProjectWarning.scss";

type ProjectWithStatus = Pick<Project, "status" | "number">;

export const InactiveProjectWarning: React.FC<{
  project: ProjectWithStatus | null;
}> = ({ project }) => {
  if (!project || project.status === ProjectStatus.Active) {
    return null;
  }

  return (
    <div className="inactive-project-warning">
      <WarningMessage
        {...{
          icon: "warning",
          message: `Warning: Project ${project!.number} is inactive`,
        }}
      />
    </div>
  );
};
