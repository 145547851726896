import React from "react";
import TextField, { Input } from "@material/react-text-field";
import HelperTextLine from "~/HelperTextLine";
import { PickedChip } from "~/picked-chip";
import classnames from "classnames";
import "./PickerWidget.scss";
import { InlineProgress } from "~/inline-progress";

export type PickerWidgetProps = {
  helperText: string;
  label: string;
  isValid: boolean;
  suggestionText: string;
  selectedText: React.ReactNode | null;
  onSuggestionTextChanged: React.Dispatch<string>;
  onCleared: React.DispatchWithoutAction;
  onFocus?: React.Dispatch<React.FocusEvent<HTMLInputElement>>;
  onBlur?: React.Dispatch<React.FocusEvent<HTMLInputElement>>;
  onKeyDown?: React.Dispatch<React.KeyboardEvent<HTMLInputElement>>;
  inputRef?: React.Ref<Input<HTMLInputElement>>;
  loading?: boolean;
  isEmpty?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

export const PickerWidget: React.FC<PickerWidgetProps> = (props) => {
  const {
    suggestionText,
    onSuggestionTextChanged,
    helperText,
    label: rawLabel,
    isValid,
    selectedText,
    onCleared,
    onKeyDown,
    onFocus,
    onBlur,
    loading,
    inputRef,
    children,
    isEmpty,
    disabled,
  } = props;

  const shouldFloat = suggestionText.length > 0 || !!selectedText;

  const isSelected = selectedText != null;
  const computedSuggestionText = isSelected ? "" : suggestionText;

  const swallowFocus = (evt: React.ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.target.blur();
  };
  const focusHandler = isSelected ? swallowFocus : onFocus;
  const label = loading ? "Loading..." : rawLabel;

  return (
    <section
      className={classnames("picker", "form-field", {
        "picker-loading": loading,
      })}
    >
      <TextField
        label={label}
        helperText={
          <HelperTextLine
            isValid={isValid}
            message={helperText}
            persistent={isEmpty}
          />
        }
        floatingLabelClassName={classnames({
          "mdc-floating-label--float-above": shouldFloat,
        })}
        className={classnames({
          "mdc-text-field--invalid": !isValid || isEmpty,
        })}
      >
        <Input
          value={computedSuggestionText}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            onSuggestionTextChanged(evt.target.value)
          }
          onFocus={focusHandler}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          aria-label={label}
          autoComplete="new-password"
          disabled={disabled}
          ref={inputRef as React.RefObject<Input<HTMLInputElement>> & null}
        />
      </TextField>
      {selectedText && (
        <PickedChip label={selectedText.toString()} onClick={onCleared} />
      )}
      {loading && <InlineProgress />}
      {children}
    </section>
  );
};
