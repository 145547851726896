import React from "react";
import { InlineTypeahead, InlineTypeaheadProps } from "~/inline-typeahead";
import { FieldOffice } from "~/gql/types";
import { useFieldOfficesAsync } from "~/refdata2";

type InlineFieldOfficePickerProps = {
  name: string;
  value: FieldOffice;
  onChange: (val: FieldOffice | null) => void;
  onBlur?: React.FocusEventHandler<any>;
  submitError?: string;
  required?: boolean;
};

export const InlineFieldOfficePicker: React.FC<InlineFieldOfficePickerProps> = (
  props
) => {
  const format = (item: FieldOffice) => item.officeCode;

  const getOffices = useFieldOfficesAsync();

  const getData = async (searchText: string) => {
    const result = (await getOffices()) ?? [];
    const filtered = result.filter((x) => x.allowsProjects);

    if (!searchText) {
      return filtered;
    }

    return filtered.filter((t) =>
      t.officeCode.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const args = {
    ...props,
    getData,
    format,
  } as InlineTypeaheadProps<FieldOffice>;

  return (
    <div className="inline-field-office-picker">
      <InlineTypeahead {...args} />
    </div>
  );
};
