import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeesSearchHookQueryVariables = Types.Exact<{
  token?: Types.InputMaybe<Types.Scalars['String']>;
  status?: Types.InputMaybe<Types.EmployeeStatus>;
  workLocation?: Types.InputMaybe<Types.Scalars['String']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.EmployeeSortBy>;
  roleName?: Types.InputMaybe<Types.Scalars['String']>;
  activePayrollDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EmployeesSearchHookQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', search?: { __typename?: 'ConnectionOfEmployee', token?: string | null, records?: Array<{ __typename?: 'Employee', email: string, workLocation: string, firstName: string, middleInitial: string, lastName: string, isMobileEmployee: boolean, isTerminated: boolean, ultiProId: string, userPrincipalName: string, timeEntryMethod: string, fieldOffice?: { __typename?: 'FieldOffice', officeCode: string, allowsProjects: boolean } | null, payrollAdmin?: { __typename?: 'Employee', ultiProId: string, firstName: string, middleInitial: string, lastName: string } | null } | null> | null } | null } | null };


export const EmployeesSearchHookDocument = gql`
    query EmployeesSearchHook($token: String, $status: EmployeeStatus, $workLocation: String, $searchText: String, $sortBy: EmployeeSortBy, $roleName: String, $activePayrollDate: String) {
  employees {
    search(
      token: $token
      status: $status
      workLocation: $workLocation
      searchText: $searchText
      sortBy: $sortBy
      roleName: $roleName
      activePayrollDate: $activePayrollDate
    ) {
      token
      records {
        email
        workLocation
        firstName
        middleInitial
        lastName
        middleInitial
        isMobileEmployee
        isTerminated
        ultiProId
        userPrincipalName
        timeEntryMethod
        fieldOffice {
          officeCode
          allowsProjects
        }
        payrollAdmin {
          ultiProId
          firstName
          middleInitial
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useEmployeesSearchHookQuery__
 *
 * To run a query within a React component, call `useEmployeesSearchHookQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesSearchHookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesSearchHookQuery({
 *   variables: {
 *      token: // value for 'token'
 *      status: // value for 'status'
 *      workLocation: // value for 'workLocation'
 *      searchText: // value for 'searchText'
 *      sortBy: // value for 'sortBy'
 *      roleName: // value for 'roleName'
 *      activePayrollDate: // value for 'activePayrollDate'
 *   },
 * });
 */
export function useEmployeesSearchHookQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesSearchHookQuery, EmployeesSearchHookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesSearchHookQuery, EmployeesSearchHookQueryVariables>(EmployeesSearchHookDocument, options);
      }
export function useEmployeesSearchHookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesSearchHookQuery, EmployeesSearchHookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesSearchHookQuery, EmployeesSearchHookQueryVariables>(EmployeesSearchHookDocument, options);
        }
export type EmployeesSearchHookQueryHookResult = ReturnType<typeof useEmployeesSearchHookQuery>;
export type EmployeesSearchHookLazyQueryHookResult = ReturnType<typeof useEmployeesSearchHookLazyQuery>;
export type EmployeesSearchHookQueryResult = Apollo.QueryResult<EmployeesSearchHookQuery, EmployeesSearchHookQueryVariables>;