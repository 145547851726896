import React, { useState } from "react";
import {
  ReceiptValuedExpenseRate,
  ReceiptValuedExpense,
  ReceiptValuedCategory,
} from "~/gql/types";
import ReceiptValuedForm from "~/visuals/organisms/Expenses/ExpenseForm/ReceiptValueTypeForm";

import { useWorkDayApi } from "~/WorkDayApi";
import { FORM_ERROR } from "final-form";
import { useLoadReceipt } from "../useLoadReceipt";
import Spinner from "~/spinner";

import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";

type EditReceiptValuedProps = BaseExpenseFormProps & {
  expenseRate: ReceiptValuedExpenseRate;
  expenseEntry: ReceiptValuedExpense;
};

const EditReceiptValuedExpense: React.FC<EditReceiptValuedProps> = (props) => {
  const { expenseRate, expenseEntry, project } = props;

  const [imageId, setImageId] = useState<string | null>(
    expenseEntry.receiptImageId ?? null
  );
  const { editReceiptValuedExpense } = useWorkDayApi();

  const [image, setImage] = useState<any>();

  useLoadReceipt({ image, setImage, expenseEntry });

  const editExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc
  ) => {
    const parseNum = (val: any) => (val ? parseFloat(val) : null);

    const isLodging = expenseRate.category === ReceiptValuedCategory.Lodging;
    const location = isLodging ? values.location : project.location;

    if (imageId) {
      const res = await editReceiptValuedExpense({
        ...defaultVals(values),
        payerInformation: values?.payerInformation,
        odometer: parseNum(values?.odometer),
        state: location?.state,
        county: location?.county,
        receiptTotal: parseNum(values?.receiptTotal)!,
        receiptImageId: imageId,
        expenseId: expenseEntry.id,
        vehicleNumber: values?.vehicleNumber,
        payableOverride: values?.payableOverride || null,
      });

      return res;
    }
    return { [FORM_ERROR]: "The image id cannon be null" };
  };

  if (!image && expenseEntry.receiptImageId) {
    return <Spinner open={true} />;
  }

  const uniqueInitVals: UniqueInitVals = [
    {
      payerInformation: expenseEntry.payerInformation,
      odometer: expenseEntry.odometer,
      location: expenseEntry.location ?? null,
      receiptTotal: expenseEntry.receiptTotal,
      receiptImage: image ?? null,
      vehicleNumber: expenseEntry.vehicleNumber,
    },
  ];

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: editExpense,
        expenseType: "Receipt Valued",
        formType: "Edit",
      }}
    >
      <ReceiptValuedForm {...props} setImageId={setImageId} />
    </ExpensePageWrapper>
  );
};

export default EditReceiptValuedExpense;
