import React from "react";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import Table from "~/old-table";
import Button from "~/button";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useWorkDayApi } from "~/WorkDayApi";
import "./ProjectTimeCard.scss";
import { Body2 } from "@material/react-typography";
import { useUser } from "~/Auth/useUser";
import { useRawJwt } from "~/Auth/AuthenticationManager";
import { NotesDisplay } from "~/notes-display";
import { useDownloadFile } from "~/file-loading/useDownloadFile";
import { WorkdayProjectDailies } from "./WorkdayProjectDailies";
import { WorkDayItemActions } from "../WorkDayItemActions";
import { CopyProjectTimeToDates } from "../CopyToDates";
import { CopyProjectTimeToDate, MoveProjectTimeToDate } from "../ItemToDate";
import { ProjectTimeRow } from "./types";
import { WorkdayCard } from "../WorkdayCard";
import { getDailyFilename } from "./utils";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

export type ProjectTimeCardProps = {
  projectTime: ProjectTimeRow[];
  editable: boolean;
};

export const ProjectTimeCard: React.FC<ProjectTimeCardProps> = ({
  projectTime,
  editable,
}) => {
  const projectTimeTotal = moment.duration(
    projectTime.map((x) => x.hours.asMilliseconds()).reduce((x, y) => x + y, 0)
  );

  const user = useUser();

  const baseMessage = `Total: ${projectTimeTotal.asHours().toFixed(2)}`;

  const navigate = useNavigate();

  const token = useRawJwt();

  const { deleteProjectTime } = useWorkDayApi();

  const download = useDownloadFile();

  const fetchPdf = async (projectTimeEntry: ProjectTimeRow) => {
    const url = projectTimeEntry?.pdfDownloadUrl;
    const filename = getDailyFilename(projectTimeEntry);

    void window
      .fetch(url, {
        method: "get",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: token ? `Bearer ${token}` : "",
          impersonation: user.isImpersonating ? user.email : "",
        },
      })
      .then(async (res) => {
        if (res.status === 200) {
          const blob = await res.blob();
          const file = new Blob([blob], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          download({ linkHref: fileURL, filename });
        }
      });
  };

  const otherOptions = (projectTimeEntry: ProjectTimeRow) => [
    editable
      ? {
          text: "Clone",
          onClick: () =>
            navigate(`${cloneProjectTimeEntry}/${projectTimeEntry.id}`),
        }
      : null,

    projectTimeEntry.dailyHours && projectTimeEntry.dailyHours.length > 0
      ? {
          text: "Download Daily PDF",
          onClick: () => fetchPdf(projectTimeEntry),
        }
      : null,
  ];

  const getCrewMembers = (entry: ProjectTimeRow) => {
    const members: string[] = [];
    const { otherCrew1, otherCrew2 } = entry;

    if (otherCrew1) {
      members.push(formatFirstMiddleLast(otherCrew1));
    }
    if (otherCrew2) {
      members.push(formatFirstMiddleLast(otherCrew2));
    }

    return members.map((member, i) => <Body2 key={i}>{member}</Body2>);
  };

  const actions = (
    <>
      {editable && (
        <Button onClick={() => navigate(createProjectTimeEntry)}>
          Add project time
        </Button>
      )}
      <WorkdayProjectDailies {...{ entries: projectTime }} />
    </>
  );

  return (
    <WorkdayCard
      title={"Project Time"}
      className="project-time-card"
      actions={actions}
      data={projectTime}
      editable={editable}
      placeholderText="(no project time)"
      subtitle={baseMessage}
    >
      <Table>
        <TableHeader>
          <TableCell text="Customer" />
          <TableCell text="Project" />
          <TableCell text="Notes" />
          <TableCell text="EE Code" />
          <TableCell text="Location" />
          <TableCell text="Crew Members" />
          <TableCell text="Hours" />
          <TableCell text="Task" />
          <TableCell text="Action" />
        </TableHeader>
        {projectTime.map((projectTimeEntry, idx) => (
          <TableContent
            key={idx}
            expandable
            trailingSeparator={idx < projectTime.length - 1}
          >
            <TableCell text={projectTimeEntry.project.customer!.number!} />
            <TableCell
              text={`${projectTimeEntry.project.name} (${projectTimeEntry.project.number})`}
            />
            <TableCell>
              <NotesDisplay
                {...{
                  notes: projectTimeEntry.notes!,
                  adminNotes: projectTimeEntry.lastAdminNotes!,
                }}
              />
            </TableCell>
            <TableCell text={projectTimeEntry.eeCode} />
            <TableCell
              text={`${projectTimeEntry.location!.state}-${
                projectTimeEntry.location!.county
              }`}
            />
            <TableCell className="pt-crew-members">
              {getCrewMembers(projectTimeEntry)}
            </TableCell>
            <TableCell text={projectTimeEntry.hours.asHours().toFixed(2)} />
            <TableCell text={projectTimeEntry.taskName} />
            <WorkDayItemActions
              {...{
                editable,
                onDelete: () =>
                  deleteProjectTime({ projectTimeId: projectTimeEntry.id }),
                onEdit: () =>
                  navigate(`${editProjectTimeEntry}/${projectTimeEntry.id}`),
                buttonsClassName: "project-time-actions-buttons",
                className: "project-time-actions",
                id: projectTimeEntry.id,
                date: projectTimeEntry.date,
                CopyToDate: CopyProjectTimeToDate,
                MoveToDate: MoveProjectTimeToDate,
                CopyToDates: CopyProjectTimeToDates,
                otherOptions: otherOptions(projectTimeEntry),
              }}
            />
          </TableContent>
        ))}
      </Table>
    </WorkdayCard>
  );
};

export const createProjectTimeEntry = "create-project-time-entry";
export const editProjectTimeEntry = "edit-project-time-entry";
export const cloneProjectTimeEntry = "clone-project-time-entry";
