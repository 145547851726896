type Employee = {
  firstName: string;
  lastName: string;
  middleInitial?: string;
};

export function formatFirstMiddleLast(employee: Employee): string {
  const { firstName, lastName, middleInitial } = employee;

  const middle = middleInitial ? `${middleInitial}. ` : "";

  return `${firstName} ${middle}${lastName}`;
}

export function formatLastFirstMiddle(employee: Employee): string {
  const { firstName, lastName, middleInitial } = employee;

  const middle = middleInitial ? ` ${middleInitial}.` : "";

  return `${lastName}, ${firstName}${middle}`;
}
