import React from "react";
import { TypeaheadFormField, TypeaheadProps } from "~/typeahead";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { EmployeeRole, useEmployeeSearch } from "~/refdata/useEmployeeSearch";
import { Employee } from "~/gql/types";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

const format = (item: Employee) => formatFirstMiddleLast(item);

export type EmployeePickerProps = FormFieldProps & {
  roleName?: EmployeeRole;
  activePayrollDate?: string;
};

export const EmployeePicker: React.FC<EmployeePickerProps> = (props) => {
  const getEmployees = useEmployeeSearch();

  const getData = async (searchText: string) => {
    if (!searchText) {
      return [];
    }
    const result = await getEmployees({
      searchText,
      roleName: props.roleName,
      activePayrollDate: props.activePayrollDate,
    });
    return result?.records ?? [];
  };

  const args = {
    ...props,
    getData,
    format,
  } as TypeaheadProps<Employee>;

  return <TypeaheadFormField {...args} />;
};
