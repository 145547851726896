import React from "react";
import { Charge } from "../types";
import { TotalRow } from "./ProjectChargeRow";
import { formatLastFirstMiddle } from "~/utils/formatEmployeeName";

type EmployeeTotalRowProps = {
  charge: Charge;
};

export const EmployeeTotalRow: React.FC<EmployeeTotalRowProps> = ({
  charge,
}) => {
  return (
    <TotalRow
      {...{
        totalTimesheetHours: charge.groupedTotalTimesheetHrs,
        totalBillableHours: charge.groupedTotalBillableHrs,
        totalTimesheetQty: charge.groupedTotalTimesheetQty,
        totalQty: charge.groupedTotalQty,
        total: charge.groupedTotal,
        label: `${formatLastFirstMiddle(charge.employee!)} Totals`,
      }}
    />
  );
};
