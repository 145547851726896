import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import { Moment } from "moment";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import CurrencyFormField from "~/visuals/organisms/CurrencyFormField";
import { EmployeePicker } from "~/visuals/organisms/EmployeePickerFormField";
import FormBottomRow from "~/form-bottom-row";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import { useSnackBar } from "~/snackbar";
import { TopActionButtons } from "~/top-action-buttons";
import Spinner from "~/spinner";
import Button from "~/button";
import { Employee, ValidationResponse } from "~/gql/types";
import { useCreateEmployeeRateMutation } from "./add.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

type FormValues = {
  effectiveDate: Moment | null;
  rate: string | null;
  employee: Employee | null;
};

type AddEmployeeRateProps = {
  initialVals?: FormValues;
};

export const AddEmployeeRate: React.FC<AddEmployeeRateProps> = ({
  initialVals,
}) => {
  const addAlert = useSnackBar();
  const [open, setOpen] = useState<boolean>(false);

  const initialValues = initialVals ?? {
    effectiveDate: null,
    rate: null,
    employee: null,
  };

  const resetRates = useResetSearchItems();

  const [doMutation, { loading }] = useCreateEmployeeRateMutation();

  const handleSubmit = async (values: FormValues) => {
    const variables = {
      ultiProId: values.employee!.ultiProId,
      effectiveDate: values.effectiveDate!.format("YYYY-MM-DD"),
      rate: parseFloat(values.rate!),
    };

    const result = await doMutation({ variables });
    return result?.data?.employeeRates?.createRate as ValidationResponse;
  };

  const onSuccess = ({ values }) => {
    setOpen(false);
    addAlert({
      key: `${values.effectiveDate.format("YYYY-MM-DD")}-${
        values.employee.ultiProId
      }`,
      message: `Employee rate created for ${formatFirstMiddleLast(
        values.employee
      )} effective ${values.effectiveDate.format("MM/DD/YYYY")}`,
      isSuccess: true,
    });
    resetRates();
  };

  return (
    <>
      <TopActionButtons>
        <Button onClick={() => setOpen(true)}>Add Rate</Button>
      </TopActionButtons>
      <Dialog
        className="create-employee-rate"
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
      >
        <DialogTitle>Create Employee Rate</DialogTitle>
        <DialogContent>
          <Spinner open={loading} />
          <MutationForm
            runMutation={handleSubmit}
            onSuccess={onSuccess}
            initialValues={initialValues}
            allowPristineSubmit={true}
          >
            <DatePickFormField
              formField="effectiveDate"
              label="Effective Date"
              helperText=""
              required
            />
            <CurrencyFormField
              formField="rate"
              label="Rate"
              helperText=""
              required
            />
            <EmployeePicker
              formField="employee"
              label="Employee"
              helperText=""
              required
            />

            <ErrorRenderer
              render={(submitError) => (
                <FormBottomRow
                  errorMessage={submitError}
                  buttonText="Save"
                  onCancel={() => setOpen(false)}
                />
              )}
            />
          </MutationForm>
        </DialogContent>
      </Dialog>
    </>
  );
};
