import React from "react";
import { State } from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { FilterOptions, Timesheet } from "./types";
import { getDateSuffix } from "./utils";

export type PdfReportExporterProps = {
  state: State<Timesheet, FilterOptions>;
  setLoading: React.Dispatch<boolean>;
  path: string;
  useReimbursement?: boolean;
  selectedWeeks?: Timesheet[];
};

export function usePdfReportExporter(): (
  props: PdfReportExporterProps
) => Promise<void> {
  const downloadPdf = useReportFetcher();

  return async ({
    state,
    setLoading,
    path,
    useReimbursement,
    selectedWeeks,
  }: PdfReportExporterProps) => {
    const {
      searchText,
      weekStart,
      dateBefore,
      dateAfter,
      workLocation,
      weekStatus,
      reimbursement,
      payrollAdmin,
      payPeriod,
      certifiedPayroll,
      showFuture,
    } = state.filterOptions;

    const body = {
      weekStart: weekStart?.format("YYYY-MM-DD"),
      dateBefore: dateBefore?.format("YYYY-MM-DD"),
      dateAfter: dateAfter?.format("YYYY-MM-DD"),
      searchText,
      workLocation,
      status: weekStatus,
      payrollAdmin: payrollAdmin?.userPrincipalName ?? null,
      payPeriod,
      reimbursement,
      certifiedPayroll: certifiedPayroll === "Yes",
      showFuture: showFuture === "Yes",
      timesheets: selectedWeeks
        ? selectedWeeks.map((x) => x.timesheetId)
        : null,
    };

    if (useReimbursement) {
      body["reimbursement"] = reimbursement;
    }

    setLoading(true);
    void downloadPdf({
      setLoading,
      path,
      fileName: `${path}-${getDateSuffix(state, selectedWeeks)}`,
      body,
    });
  };
}
