import React, { useEffect, useState } from "react";
import { useEeCodes, useInvalidateEeCodes } from "~/refdata2/eeCodes";
import { EeCodesFilter } from "./types";
import { EeCodeRecord } from "~/gql/types";
import { FilterForm } from "./FilterForm";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import { SearchFilterContainer } from "~/search-filter-container";
import { Table, TableRow, TableCell } from "~/table";
import Card from "@material/react-card";
import "./EeCodesSearch.scss";
import { UpdateEeCodeActive } from "./UpdateEeCodeActive";
import _ from "lodash";
import { VisibilityListener } from "~/visibility-listener";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

export type FilterEeCodesProps = {
  setItems: React.Dispatch<EeCodeRecord[]>;
  setLoading: React.Dispatch<boolean>;
  filters: EeCodesFilter;
};

export const FilterEeCodes: React.FC<FilterEeCodesProps> = (props) => {
  const { setLoading, setItems, filters } = props;
  const employeeFilter = filters.employee;
  const isActiveFilter = filters.isActive;
  const lcText = filters.searchText?.toLowerCase() || null;

  const allEeCodes = useEeCodes();

  useEffect(() => {
    if (allEeCodes) {
      const filtered = allEeCodes
        .filter(
          (x) =>
            !employeeFilter ||
            x.employee?.userPrincipalName === employeeFilter.userPrincipalName
        )
        .filter((x) => isActiveFilter === null || x.isActive === isActiveFilter)
        .filter(
          (x) =>
            !lcText ||
            x.eeCode.toLowerCase().startsWith(lcText) ||
            x.employee?.firstName?.toLowerCase()?.startsWith(lcText) ||
            x.employee?.lastName?.toLowerCase()?.startsWith(lcText)
        );

      setItems(_.orderBy(filtered, (x) => x.eeCode));
      setLoading(false);
    }
  }, [allEeCodes, filters]);

  return <></>;
};

type EeCodesDisplayProps = {
  loading: boolean;
  items: EeCodeRecord[];
};

const EeCodesDisplay: React.FC<EeCodesDisplayProps> = (props) => {
  const { loading, items } = props;
  const [loadedItems, setLoadedItems] = useState(items.slice(0, 100));

  useEffect(() => {
    setLoadedItems(items.slice(0, 100));
  }, [items]);

  const refresh = useInvalidateEeCodes();

  const loadMore = () => {
    if (loadedItems.length === items.length) {
      return;
    }

    setLoadedItems(items.slice(0, loadedItems.length + 100));
  };

  return (
    <SearchListContent
      {...{
        loading,
      }}
    >
      <Card className="crew-codes-search-table">
        <Table
          {...{
            columnCount: 4,
            columnWidths: "1fr 1fr repeat(2, max-content)",
          }}
        >
          <TableRow header>
            <TableCell text="EE Code" />
            <TableCell text="Employee/Description" />
            <TableCell text="Is Active" />
            <TableCell />
          </TableRow>
          {loadedItems.map((c) => (
            <TableRow key={c.eeCode}>
              <TableCell text={c.eeCode} />
              <TableCell
                text={
                  c.employee ? formatFirstMiddleLast(c.employee) : c.description
                }
              />
              <TableCell text={c.isActive ? "Yes" : "No"} />
              <TableCell>
                <UpdateEeCodeActive {...{ eeCode: c, refresh }} />
              </TableCell>
            </TableRow>
          ))}
          <VisibilityListener onShown={loadMore} />
        </Table>
      </Card>
    </SearchListContent>
  );
};

export type EeCodesResultsProps = {
  filters: EeCodesFilter;
};

export const EeCodesResults: React.FC<EeCodesResultsProps> = (props) => {
  const { filters } = props;

  const [items, setItems] = useState<EeCodeRecord[]>([]);
  const [loading, setLoading] = useState(true);

  const loadProps = {
    setItems,
    filters,
    setLoading,
  };

  return (
    <>
      <FilterEeCodes {...loadProps} />
      <EeCodesDisplay {...{ items, loading }} />
    </>
  );
};

export type EeCodesSearchProps = {
  FilterFormComponent?: typeof FilterForm;
};

export const EeCodesSearch: React.FC<EeCodesSearchProps> = (props) => {
  const [filters, setFilters] = useState<EeCodesFilter | null>(null);

  const setFilterOptions = (opts: EeCodesFilter) => setFilters(opts);

  const FilterComponent = props.FilterFormComponent ?? FilterForm;

  return (
    <SearchPageWrapper withSort>
      <SearchFilterContainer>
        <FilterComponent onFiltersChanged={setFilterOptions} />
      </SearchFilterContainer>
      {filters && <EeCodesResults {...{ filters }} />}
    </SearchPageWrapper>
  );
};
