import React, { useState, useEffect, createContext, useContext } from "react";
import List, {
  ListItem,
  ListItemText,
  ListItemGraphic,
} from "@material/react-list";
import { Link } from "react-router-dom";
import { useUser } from "~/Auth/useUser";
import { Role } from "~/visuals/pages/Home/RoleStats";

import MaterialIcon from "@material/react-material-icon";
import "./submenu.scss";
import { Chevron } from "~/chevron";
import { AuthMenuItemProps, NavMenuProps } from "../types";

export type RoleMap = {
  Pm: "projectManager";
  Ps: "projectSupervisor";
  Ba: "billingAdmin";
};

export const roles: Role[] = ["Ba", "Ps", "Pm"];

type SubMenuItemProps = {
  text: string;
  path: string;
  searchFilter: string;
  className?: string;
};

type SubMenuRoleItemProps = {
  role: Role;
  toText: (role: Role) => string;
  path: string;
  searchFilter: (role: Role) => string;
  className?: string;
};

type SubmenuLinkProps = {
  text: string;
  className?: string;
  style?: React.CSSProperties;
};

export const SubmenuListItem: React.FC<SubmenuLinkProps> = (props) => {
  const { text, className, style } = props;

  const linkStyle = ["submenu-item", className].filter((x) => x).join(" ");

  return (
    <ListItem className={linkStyle} style={style}>
      {text}
    </ListItem>
  );
};

export const MyRoleSubMenuItem: React.FC<SubMenuRoleItemProps> = ({
  role,
  searchFilter,
  toText,
  path,
  className,
}) => {
  const user = useUser();

  const roleMap = {
    Pm: "projectManager",
    Ps: "projectSupervisor",
    Ba: "billingAdmin",
  };

  const { closeNav } = useSubmenuContext();

  const userFilter = `${roleMap[role]}=${user.email}`;

  const otherSearch = searchFilter(role);

  const linkPath = path + `?${otherSearch}&${userFilter}`;
  const linkText = toText(role);

  return (
    <Link to={linkPath} onClick={closeNav}>
      <SubmenuListItem text={linkText} className={className} />
    </Link>
  );
};

export const AnyRoleSubMenuItem: React.FC<SubMenuRoleItemProps> = ({
  role,
  searchFilter,
  toText,
  path,
  className,
}) => {
  const search = searchFilter(role);

  const { closeNav } = useSubmenuContext();

  const linkPath = path + `?${search}`;
  const linkText = toText(role);

  return (
    <Link to={linkPath} onClick={closeNav}>
      <SubmenuListItem text={linkText} className={className} />
    </Link>
  );
};

export const AnySubMenuItem: React.FC<SubMenuItemProps> = ({
  searchFilter,
  text,
  path,
  className,
}) => {
  const { closeNav } = useSubmenuContext();

  const linkPath = path + `?${searchFilter}`;

  return (
    <Link to={linkPath} onClick={closeNav}>
      <SubmenuListItem text={text} className={className} />
    </Link>
  );
};

export const SubMenu: React.FC<{ multiplier: number }> = (props) => {
  const { multiplier, children } = props;

  const { open } = useSubmenuContext();

  const itemHeight = 26;
  const maxHeight = itemHeight * multiplier + itemHeight;

  return (
    <>
      <List
        className={"submenu"}
        style={{ maxHeight: open ? maxHeight + "px" : 0 }}
      >
        {children}
      </List>
    </>
  );
};

type SubmenuContext = {
  open: boolean;
  closeNav: React.DispatchWithoutAction;
};

export const SubmenuContext = createContext<SubmenuContext>({
  open: false,
  closeNav: () => undefined,
});

export const SubmenuNavItem: React.FC<AuthMenuItemProps & NavMenuProps> = (
  props
) => {
  const [open, setOpen] = useState(false);
  const { icon, title, navOpen, closeNav, children } = props;

  useEffect(() => {
    if (!navOpen && open) {
      setOpen(false);
    }
  }, [navOpen]);

  return (
    <li>
      <div
        onClick={() => setOpen(!open)}
        className="mdc-list-item submenu-nav-item"
      >
        <ListItemGraphic graphic={<MaterialIcon icon={icon} />} />
        <ListItemText primaryText={title} />
        <Chevron {...{ expanded: open, className: "submenu-chevron" }} />
      </div>
      <SubmenuContext.Provider value={{ open, closeNav }}>
        {children}
      </SubmenuContext.Provider>
    </li>
  );
};

export function useSubmenuContext(): SubmenuContext {
  const context = useContext(SubmenuContext);

  return context;
}
