import React from "react";
import { OptionProps } from "~/visuals/organisms/DropdownFormField";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { ProjectDailies } from "~/visuals/organisms/ProjectDailies";
import { ProjectTimeRow } from "./types";
import { getDailyFilename } from "./utils";

type WorkdayProjectDailiesProps = {
  entries: ProjectTimeRow[];
};

export const WorkdayProjectDailies: React.FC<WorkdayProjectDailiesProps> = ({
  entries,
}) => {
  const downloadPdf = useReportFetcher();

  const options = entries.map((x) => ({
    label: `${x.project!.name} (${x.project!.number})`,
    value: `${x.project!.number}`,
  })) as OptionProps[];

  const handleSubmit = async (
    values: { project: string | null },
    setLoading: React.Dispatch<boolean>
  ) => {
    const body = {
      projectTimeEntries: entries
        .filter((x) => x.project!.number == +values.project!)
        .map((x) => x.id),
    };

    void downloadPdf({
      setLoading,
      path: "workday-project-dailies",
      fileName: getDailyFilename(entries[0]),
      body,
    });
  };

  return <ProjectDailies {...{ options, handleSubmit }} />;
};
