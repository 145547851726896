import * as Types from '../../../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetFieldOfficesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFieldOfficesQuery = { __typename?: 'QueryModel', fieldOffices?: { __typename?: 'FieldOfficesQueryModel', all?: Array<{ __typename?: 'FieldOffice', officeCode: string, allowsProjects: boolean, projectRequestReviewers?: Array<{ __typename?: 'Employee', userPrincipalName: string, firstName: string, middleInitial: string, lastName: string } | null> | null } | null> | null } | null };


export const GetFieldOfficesDocument = gql`
    query getFieldOffices {
  fieldOffices {
    all {
      officeCode
      allowsProjects
      projectRequestReviewers {
        userPrincipalName
        firstName
        middleInitial
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetFieldOfficesQuery__
 *
 * To run a query within a React component, call `useGetFieldOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldOfficesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFieldOfficesQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldOfficesQuery, GetFieldOfficesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldOfficesQuery, GetFieldOfficesQueryVariables>(GetFieldOfficesDocument, options);
      }
export function useGetFieldOfficesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldOfficesQuery, GetFieldOfficesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldOfficesQuery, GetFieldOfficesQueryVariables>(GetFieldOfficesDocument, options);
        }
export type GetFieldOfficesQueryHookResult = ReturnType<typeof useGetFieldOfficesQuery>;
export type GetFieldOfficesLazyQueryHookResult = ReturnType<typeof useGetFieldOfficesLazyQuery>;
export type GetFieldOfficesQueryResult = Apollo.QueryResult<GetFieldOfficesQuery, GetFieldOfficesQueryVariables>;