import { ProjectRequest } from "~/gql/types";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";
import { getTimeSince } from "~/utils/getTimeSince";

export const getCheckedOutBy = (request: ProjectRequest): string => {
  if (!request.checkedOutBy) {
    return "";
  }

  return `${formatFirstMiddleLast(request.checkedOutBy)} (${
    getTimeSince(request.lastCheckoutTime).elapsed
  })`;
};
