import React from "react";
import { Body1, Body2 } from "@material/react-typography";
import TitledCard from "~/titled-card";
import "./InvoiceCard.scss";
import moment from "moment";
import { TextHighlighter } from "~/text-highlighter";
import { invoice as invoiceRoute } from "~/routes";
import { currencyFormatter } from "~/currencyFormatter";
import { StateHelperButton } from "~/state-helper";
import { CardSelectCheckbox } from "~/search-page-wrapper/BulkCheckboxes/CardSelectCheckbox";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { FilterOptions } from "./types";
import { LinkClickHandler } from "~/link-click-handler";
import { InvoiceRebillInfo } from "../visuals/pages/Invoice/InvoiceRebillInfo";
import ClickSwallower from "~/click-swallower";
import { Invoice, Maybe } from "~/gql/types";
import { ToolTip } from "~/tooltip";

const InvoiceDetailCell: React.FC = ({ children }) => {
  return <div className="invoice-detail-cell">{children}</div>;
};

type StatProps = {
  value: string;
  label: string;
};

const Stat: React.FC<StatProps> = ({ value, label }) => {
  return (
    <li>
      {value || 0} {label}
    </li>
  );
};

type InvoiceCardProps = {
  invoice: Invoice;
  dispatch: React.Dispatch<Action<Invoice, FilterOptions>>;
  state: State<Invoice, FilterOptions>;
};

export const InvoiceCard: React.FC<InvoiceCardProps> = ({
  invoice,
  state,
  dispatch,
}) => {
  const { invoiceDate, invoiceNumber, project, cancelledDate } = invoice;
  const Highlight = ({ text }) => <TextHighlighter {...{ text }} />;

  const path = invoiceRoute.toRoute(invoice.invoiceNumber).path;

  const actions = (
    <>
      <ToolTip text={invoice?.notes ?? ""}>
        <Body2 className="invoice-note">{invoice?.notes ?? ""}</Body2>
      </ToolTip>
      <ClickSwallower>
        <InvoiceRebillInfo {...{ invoice, textOnly: true }} />
      </ClickSwallower>
      <Body1>
        <ul>
          <Stat
            value={`${invoice?.lineItems?.length ?? 0}`}
            label="line items"
          />
        </ul>
      </Body1>
    </>
  );

  const toDateText = (date?: Maybe<string> | undefined) =>
    date ? moment(date).format("MM/DD/YYYY") : "";

  const invoiceDateText = toDateText(invoiceDate);
  const cancelledDateText = toDateText(cancelledDate);
  const criNum = invoice.customRollupInvoice?.invoiceNumber;

  const dateText = (
    <>
      {invoiceDateText ? `Billed: ${invoiceDateText}` : ""}
      <span>{cancelledDateText ? `Cancelled: ${cancelledDateText}` : ""}</span>
      <span>{criNum ? `CRI: ${criNum}` : ""}</span>
    </>
  );

  const group = invoice.project!.projectGroup;

  return (
    <LinkClickHandler className={"invoice-search-card"} path={path}>
      <TitledCard
        title={
          <>
            <CardSelectCheckbox {...{ state, dispatch, item: invoice }} />
            <Highlight text={invoiceNumber} /> - {invoice.state}
            <StateHelperButton type="invoice" />
          </>
        }
        subtitle={dateText}
        actions={actions}
      >
        <div className="card-content">
          <InvoiceDetailCell>
            <Body1 title="Project">
              <Highlight text={`Project: ${invoice.projectName}`} />
              <span className="item-number">
                (<Highlight text={`${project!.number}`} />)
              </span>
            </Body1>
          </InvoiceDetailCell>
          <InvoiceDetailCell>
            <Body1 title="Customer">
              <Highlight text={`Customer: ${invoice.customerName}`} />
              <span className="item-number">
                (<Highlight text={`${invoice.customerNumber}`} />)
              </span>
            </Body1>
          </InvoiceDetailCell>
          <InvoiceDetailCell>
            {!!group && (
              <Body1 title="Project Group">
                <Highlight text={`Project Group: ${group.name}`} />
              </Body1>
            )}
          </InvoiceDetailCell>
          <InvoiceDetailCell>
            <Body1 title="Total">
              Invoice total: {currencyFormatter.format(invoice.total)}
            </Body1>
          </InvoiceDetailCell>
        </div>
      </TitledCard>
    </LinkClickHandler>
  );
};
