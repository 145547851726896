import React, { useState } from "react";
import { PerDiemExpenseRate, PerDiemExpense } from "~/gql/types";
import PerDiemForm from "~/visuals/organisms/Expenses/ExpenseForm/PerDiemTypeForm";

import { useWorkDayApi } from "~/WorkDayApi";
import Spinner from "~/spinner";
import { useLoadReceipt } from "../useLoadReceipt";
import {
  BaseExpenseFormProps,
  DefaultValFunc,
  ExpensePageWrapper,
  UniqueInitVals,
} from "~/visuals/organisms/Expenses/ExpenseForm/ExpensePageWrapper";

type EditPerDiemProps = BaseExpenseFormProps & {
  expenseRate: PerDiemExpenseRate;
  expenseEntry: PerDiemExpense;
};

const EditPerDiemExpense: React.FC<EditPerDiemProps> = (props) => {
  const { expenseEntry } = props;

  const [imageId, setImageId] = useState<string | null>(
    expenseEntry?.receiptImageId ?? null
  );
  const { editPerDiemExpense } = useWorkDayApi();

  const [image, setImage] = useState<any>();

  useLoadReceipt({ image, setImage, expenseEntry });

  const editExpense = async (
    values: Record<string, any>,
    defaultVals: DefaultValFunc
  ) => {
    const res = await editPerDiemExpense({
      ...defaultVals(values),
      state: values?.location?.state,
      county: values?.location?.county,
      expenseId: expenseEntry.id,
      receiptImageId: imageId,
      payableOverride: values?.payableOverride || null,
    });

    return res;
  };

  if (!image && expenseEntry.receiptImageId) {
    return <Spinner open={true} />;
  }

  const uniqueInitVals: UniqueInitVals = [
    {
      location: expenseEntry.location ?? null,
      receiptImage: image,
    },
  ];

  return (
    <ExpensePageWrapper
      {...{
        ...props,
        uniqueInitVals,
        handleSubmit: editExpense,
        expenseType: "Per Diem",
        formType: "Edit",
      }}
    >
      <PerDiemForm {...props} setImageId={setImageId} />
    </ExpensePageWrapper>
  );
};

export default EditPerDiemExpense;
