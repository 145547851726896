import React, { useEffect, useReducer } from "react";
import { Action, reducer, State } from "./stateMachine";
import Card from "@material/react-card";
import { Table, TableRow, TableCell } from "~/table";
import PageTitleRow from "~/page-title-row";
import Spinner from "~/spinner";
import Button from "~/button";
import "./AssignSuccessor.scss";
import { Body1 } from "@material/react-typography";
import ExpenseRateSheetPickerFormField from "~/visuals/organisms/ExpenseRateSheetPickerFormField";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import FormBottomRow from "~/form-bottom-row";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  expenseRateSheets,
  taskRateSheets,
  viewExpenseRateSheet,
  viewTaskRateSheet,
} from "~/routes";
import FormCard from "~/form-card";
import { TopActionButtons } from "~/top-action-buttons";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import ErrorMessage from "~/error-message";
import { useSnackBar } from "~/snackbar";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useAssignSuccessorExpenseRateSheetMutation } from "~/visuals/pages/ExpenseRates/ExpenseRateSheetRouter/mutation.generated";
import { useSearchProjectsToAssignSuccessorRateSheetQuery } from "./query.generated";
import { useSearchMoreProjectsToAssignQuery } from "./queryMore.generated";
import { useAssignSuccessorTaskRateSheetMutation } from "~/visuals/pages/TaskRates/TaskRateSheetRouter/mutation.generated";
import TaskRateSheetPickerFormField from "../TaskRateSheetPickerFormField";
import { ExpenseRateSheet, TaskRateSheet, Project } from "~/gql/types";
import { ProjectsCsvExporter } from "./ProjectsCsvExporter";
import { ClientForm } from "~/forms/ClientForm";
import { ValueRenderer } from "~/forms/ValueContext";

export type SheetType = "expenseRateSheet" | "taskRateSheet";

export type AssignSuccessorProps = {
  rateSheet: ExpenseRateSheet | TaskRateSheet;
  useReload(): React.DispatchWithoutAction;
  type: SheetType;
};

type LoadDataProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
  type: SheetType;
};

const LoadData: React.FC<LoadDataProps> = ({ state, dispatch, type }) => {
  const variables = {
    token: state.token ?? null,
    successorArgs: {
      [type]: state.rateSheet,
      effectiveDate: state.effectiveDate!.format("YYYY-MM-DD"),
    },
  };

  const { data } = useSearchProjectsToAssignSuccessorRateSheetQuery({
    variables,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      const newToken = data?.projects?.search?.token as string | null;
      const newData = data?.projects?.search?.records as Project[];
      const numbers = data?.projects?.searchNumbers as number[];

      dispatch({
        tag: "LoadProjects",
        data: newData,
        token: newToken,
        numbers,
      });
    }
  }, [data]);
  return <></>;
};

const LoadMoreData: React.FC<LoadDataProps> = ({ state, dispatch, type }) => {
  const variables = {
    token: state.token ?? null,
    successorArgs: {
      [type]: state.rateSheet,
      effectiveDate: state.effectiveDate!.format("YYYY-MM-DD"),
    },
  };

  const { data } = useSearchMoreProjectsToAssignQuery({
    variables,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      const newToken = data?.projects?.search?.token as string | null;
      const newData = data?.projects?.search?.records as Project[];

      dispatch({ tag: "LoadMoreProjects", data: newData, token: newToken });
    }
  }, [data]);
  return <></>;
};

type FormSpyProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
  values: Record<string, any>;
};

const FormSpy: React.FC<FormSpyProps> = ({ state, dispatch, values }) => {
  useEffect(() => {
    for (const [key, value] of Object.entries(values)) {
      if (!_.isEqual(value, state[key])) {
        dispatch({ tag: "UpdateField", fieldName: key, value });
      }
    }
  }, [values]);

  return <></>;
};

const RateSheetForm: React.FC<LoadDataProps> = (props) => {
  const { state, dispatch, type } = props;

  const navigate = useNavigate();

  const singleSheetRoute =
    type === "expenseRateSheet"
      ? viewExpenseRateSheet.toRoute(state.rateSheet)
      : viewTaskRateSheet.toRoute(state.rateSheet);

  const initialValues = {
    successor: state.successor,
    effectiveDate: state.effectiveDate,
  };

  const pickerProps = {
    label: "Rate Sheet",
    formField: "successor",
    helperText: `Choose a rate sheet that should replace ${state.rateSheet} on the given effective date`,
    required: true,
  };

  return (
    <ClientForm
      initialValues={initialValues}
      onSuccess={() => dispatch({ tag: "ChangePage", page: "Project" })}
      allowPristineSubmit={true}
    >
      <ValueRenderer
        render={(values) => (
          <FormCard>
            <DatePickFormField
              label="Effective Date"
              formField="effectiveDate"
              helperText="The date on which the rate sheet will go into effect."
              required
              maxDate={new Date(8640000000000000)}
            />

            {type === "expenseRateSheet" && (
              <ExpenseRateSheetPickerFormField
                {...{
                  ...pickerProps,
                  effectiveDate: values.effectiveDate,
                }}
              />
            )}

            {type === "taskRateSheet" && (
              <TaskRateSheetPickerFormField
                {...{
                  ...pickerProps,
                  effectiveDate: values.effectiveDate,
                }}
              />
            )}
            <ErrorRenderer
              render={(submitError) => (
                <FormBottomRow
                  errorMessage={submitError}
                  buttonText="Next"
                  onCancel={() => navigate(singleSheetRoute.path)}
                />
              )}
            />
            <FormSpy {...{ values, state, dispatch }} />
          </FormCard>
        )}
      />
    </ClientForm>
  );
};

type WrapperProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
  text: string;
  type: SheetType;
};

const Wrapper: React.FC<WrapperProps> = ({
  state,
  dispatch,
  text,
  type,
  children,
}) => {
  const navigate = useNavigate();
  const addAlert = useSnackBar();

  const variables = {
    effectiveDate: state.effectiveDate!.format("YYYY-MM-DD"),
    rateSheetName: state.successor!,
    projectNumbers: state.projectNumbers,
  };

  const singleSheetRoute =
    type === "expenseRateSheet"
      ? viewExpenseRateSheet.toRoute(state.rateSheet)
      : viewTaskRateSheet.toRoute(state.rateSheet);

  const goToRatesheet = () => navigate(singleSheetRoute.path);
  const goBack = () => dispatch({ tag: "ChangePage", page: "Form" });

  const mutationHook =
    type === "expenseRateSheet"
      ? useAssignSuccessorExpenseRateSheetMutation
      : useAssignSuccessorTaskRateSheetMutation;

  const [doMutation, { loading }] = mutationHook();

  const onSuccess = () => {
    addAlert({
      message: `${state.rateSheet} assigned to ${
        state.projectNumbers.length
      } projects effective ${state.effectiveDate?.format("MM/DD/YYYY")}`,
      key: `${Math.random()}`,
      isSuccess: true,
    });
    state.reload();
    goToRatesheet();
  };

  const resultType = type === "expenseRateSheet" ? "expenseRates" : "taskRates";

  const handleSubmit = async () => {
    const result = await doMutation({ variables });
    return result?.data?.[resultType]?.assignRateSheetToProject;
  };

  const hasProjects = state.projectNumbers.length > 0;

  return (
    <Card className="assign-successor-projects">
      <div className="text-container">
        {text.split(/\n/).map((x, idx) => (
          <Body1 key={idx} className="help-text">
            {x}
          </Body1>
        ))}
      </div>
      <MutationForm
        initialValues={{}}
        allowPristineSubmit
        onSuccess={onSuccess}
        runMutation={handleSubmit}
      >
        <ErrorRenderer
          render={(error) => (
            <>
              <ErrorMessage errorMessage={error} />
              <TopActionButtons>
                {hasProjects && <Button primary>Assign</Button>}
                {hasProjects && <ProjectsCsvExporter {...{ state }} />}
                <Button type="button" onClick={goBack}>
                  Go Back
                </Button>
                <Button type="button" onClick={goToRatesheet}>
                  Cancel
                </Button>
              </TopActionButtons>
            </>
          )}
        />
      </MutationForm>
      {children}
      <Spinner open={loading} />
    </Card>
  );
};

type ProjectsDisplayProps = {
  state: State;
  dispatch: React.Dispatch<Action>;
  type: SheetType;
};

const ProjectsDisplay: React.FC<ProjectsDisplayProps> = (props) => {
  const { state, dispatch } = props;
  const effDate = state.effectiveDate?.format("MM/DD/YYYY");

  if (state.projects.length === 0 && !state.loading) {
    return (
      <Wrapper
        text={
          `There are no eligible projects. This could be because "${state.rateSheet}" is not assigned to any projects, ` +
          `or because all projects that use "${state.rateSheet}" have another rate sheet assigned going into effect on ${effDate}.`
        }
        {...props}
      />
    );
  }

  const text = state.loading
    ? ""
    : `These ${state.projectNumbers.length} projects currently have "${state.rateSheet}" assigned.\n` +
      `Click "Assign" to assign "${state.successor}" to these projects, effective ${effDate}.\n` +
      `Note: projects with a rate sheet assigned on the same effective date of ${effDate} are not shown here and will not be modified.`;

  return (
    <Wrapper text={text} {...props}>
      <Table
        columnCount={4}
        columnWidths="repeat(4, 1fr)"
        className="successor-table"
      >
        <TableRow header>
          <TableCell text="Name" />
          <TableCell text="Number" />
          <TableCell text="Customer Name" />
          <TableCell text="Customer Number" />
        </TableRow>
        {state.projects.map((project, idx) => (
          <TableRow key={idx} className="assign-successor-project">
            <TableCell text={project.name} />
            <TableCell text={`${project.number}`} />
            <TableCell text={project.customer!.name!} />
            <TableCell text={project.customer!.number!} />
          </TableRow>
        ))}
      </Table>
      {state.token && (
        <Button onClick={() => dispatch({ tag: "GetMoreProjects" })}>
          Load More
        </Button>
      )}
    </Wrapper>
  );
};

export const AssignSuccessorRateSheet: React.FC<AssignSuccessorProps> = (
  props
) => {
  const { rateSheet, useReload, type } = props;

  const isExpenseType = type === "expenseRateSheet";

  const rateSheetsRoute = isExpenseType ? expenseRateSheets : taskRateSheets;

  const singleSheetRoute = isExpenseType
    ? viewExpenseRateSheet.toRoute(rateSheet?.name)
    : viewTaskRateSheet.toRoute(rateSheet?.name);

  const routeText = `${isExpenseType ? "Expense" : "Task"} Rate Sheets`;

  useBreadcrumbs(
    [
      { text: routeText, to: rateSheetsRoute.path },
      { text: `${rateSheet?.name}`, to: singleSheetRoute.path },
      { text: "Assign successor" },
    ],
    [rateSheet]
  );

  const reload = useReload();

  const initialState = {
    tag: "ShowingForm",
    loading: false,
    projects: [],
    token: null,
    rateSheet: rateSheet.name,
    page: "Form",
    successor: null,
    effectiveDate: null,
    projectNumbers: [],
    reload,
  } as State;

  const [state, dispatch] = useReducer(
    (s: State, a: Action) => reducer(s, a),
    initialState
  );

  const defaultProps = { state, dispatch, type };

  return (
    <div className="assign-successor-ratesheet">
      <Spinner open={state.loading} />
      <PageTitleRow title="Assign Successor Rate Sheet" />
      {state.page === "Form" && <RateSheetForm {...defaultProps} />}
      {state.page === "Project" && <ProjectsDisplay {...defaultProps} />}
      {state.tag === "ProjectsLoading" && <LoadData {...defaultProps} />}
      {state.tag === "MoreProjectsLoading" && (
        <LoadMoreData {...defaultProps} />
      )}
    </div>
  );
};
