import React from "react";
import { useParams, Navigate } from "react-router-dom";
import EditTimeEntry from "./EditTime";
import { useWorkDayApi } from "~/WorkDayApi";
import { TimeEntry } from "~/gql/types";

const EditTime: React.FC = () => {
  const { timeEntryId, date } = useParams<{
    timeEntryId: string;
    date: string;
  }>();
  const workDayApi = useWorkDayApi();

  const timeEntry: TimeEntry | null =
    workDayApi.workDay.timeEntries?.find(
      (entry) => entry!.id === timeEntryId
    ) || null;

  if (!timeEntry) {
    return <Navigate to={"../../"} />;
  }

  return <EditTimeEntry {...{ timeEntry, date: date! }} />;
};

export default EditTime;
