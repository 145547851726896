import React from "react";
import { useNavigate } from "react-router-dom";
import { pendingCustomers } from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { PendingCustomer, ValidationResponse } from "~/gql/types";
import ApproveRejectFormCard from "~/visuals/organisms/ApproveRejectForm";
import Spinner from "~/spinner";
import { useRejectCustomerMutation } from "./mutation.hotchoc.generated";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";

type RejectCustomerForm = {
  reason: string;
};

const defaultFormValues: RejectCustomerForm = {
  reason: "",
};

type RejectCustomerProps = {
  customer: PendingCustomer;
  useReload(): React.DispatchWithoutAction;
};

const RejectCustomer: React.FC<RejectCustomerProps> = ({
  customer,
  useReload,
}) => {
  useBreadcrumbs(
    [
      { text: "Pending Customers", to: pendingCustomers.path },
      { text: `Reject Customer ${customer?.name}` },
    ],
    [customer]
  );

  const reloadPendingCustomers = useReload();
  const navigate = useNavigate();

  const { createCustomerRequestId } = customer;

  const [doMutation, { loading }] = useRejectCustomerMutation();

  const onSuccess = () => {
    navigate(pendingCustomers.path);
  };

  const rejectCustomer = async (values: RejectCustomerForm) => {
    const reason = values.reason;

    const variables = {
      input: {
        reason,
        createCustomerRequestId,
      },
    };

    const result = await doMutation({ variables });

    reloadPendingCustomers();

    return result?.data?.customers?.rejectCustomer as ValidationResponse;
  };

  return (
    <>
      {loading && <Spinner open={true} />}
      <MutationForm
        onSuccess={onSuccess}
        runMutation={rejectCustomer}
        initialValues={defaultFormValues}
      >
        <ErrorRenderer
          render={(error) => (
            <ApproveRejectFormCard
              customer={customer}
              errorMessage={error}
              kind="Reject"
            />
          )}
        />
      </MutationForm>
    </>
  );
};

export default RejectCustomer;
