import { useUser } from "~/Auth/useUser";
import {
  FormErrorResponseFn,
  FormErrors,
  WorkDay,
} from "~/WorkDayApi/WorkDayApiType";
import {
  ConfirmationPromptResult,
  TextPromptResult,
  useConfirmationPrompt,
  useTextPrompt,
} from "~/confirmation-prompt";

export type HandlePayableFn = (
  callback: FormErrorResponseFn<any>,
  workDay: WorkDay
) => FormErrorResponseFn<any>;

export function useOverridePayable(): HandlePayableFn {
  const textPrompt = useTextPrompt();
  const prompt = useConfirmationPrompt();

  const user = useUser();
  const canEditPayable = user.authorizations.legacyAuth.reviewWorkData;

  const defaultMessage =
    "This expense has a payable (reimburseable) amount that was manually entered," +
    " and will be overridden with the amount set from the rate sheet.";

  const adminMessage = " You may enter a custom payable amount below.";

  return (callback: FormErrorResponseFn<any>, workDay: WorkDay) =>
    async (values: any) => {
      const expense = workDay.expenses?.find(
        (x) => x?.id === values.expenseId
      )!;

      const cancelResponse = Promise.resolve({} as FormErrors);

      let payableOverride: number | null = null;

      const doPrompt = async (): Promise<
        TextPromptResult | ConfirmationPromptResult
      > => {
        if (canEditPayable) {
          return await textPrompt({
            title: "Payable Overriden",
            message: defaultMessage + adminMessage,
            fieldName: "Enter Payable Override",
            helperText: "",
            cancelButtonText: "Cancel",
            confirmButtonText: "Continue",
            currency: true,
          });
        }

        return await prompt({
          cancelButtonText: "Cancel",
          confirmButtonText: "Continue",
          message: defaultMessage,
          title: "Payable Overridden",
        });
      };

      if (expense.payableOverridden) {
        const promptResult = await doPrompt();

        if (promptResult === "Cancel") {
          return cancelResponse;
        }

        if (typeof promptResult === "object" && "message" in promptResult) {
          const val = promptResult.message;
          payableOverride =
            val !== null && val !== ""
              ? parseFloat(promptResult.message)
              : null;
        }
      }

      const newValues = {
        ...values,
        payableOverride,
      };

      return await callback(newValues);
    };
}
