import React, { useState, useMemo } from "react";
import Table from "~/old-table";
import TitledCard from "~/titled-card";
import TableCell from "~/old-table-cell";
import TableContent from "~/old-table-content";
import TableHeader from "~/old-table-header";
import _ from "lodash";
import moment from "moment";
import "./PerDiemState.scss";
import Button from "~/button";
import { InlineProgress } from "~/inline-progress";
import { createSearchRegex, TextHighlighter } from "~/text-highlighter";
import { useStateCounties } from "~/refdata2";
import { usePerDiemRatesByStateLazyQuery } from "./query.generated";
import { PerDiemRate } from "~/gql/types";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const arrangeRates = (rates: PerDiemRate[]) =>
  _.chain(rates)
    .orderBy((x) => -moment(x.effectiveDate, "MM/DD/YYYY").valueOf())
    .orderBy((x) => x.county)
    .groupBy((x) => x.county)
    .mapValues((vals) => ({
      county: vals[0].county,
      rows: vals,
    }))
    .values()
    .value();

export type PerDiemStateProps = {
  state: string;
  totalRecords: number;
  recordCount: number;
  filteredText: string | null;
};

const PerDiemState: React.FC<PerDiemStateProps> = ({
  state,
  recordCount,
  totalRecords,
  filteredText,
}) => {
  const [expanded, setExpanded] = useState(false);

  const searchRegex = useMemo(
    () => createSearchRegex(filteredText),
    [filteredText]
  );

  const [loadRates, { data, loading, called }] =
    usePerDiemRatesByStateLazyQuery({ variables: { state: state } });

  const handleClick = () => {
    if (!called && !expanded) {
      void loadRates();
    }
    setExpanded(!expanded);
  };

  const stateCountyData = useStateCounties();

  const isStateMatch = !searchRegex || !!state.match(searchRegex);

  const totalCounties =
    stateCountyData?.filter((x) => x.state === state).map((x) => x.county) ||
    [];

  const searchedCounties = totalCounties?.filter(
    (x) => !searchRegex || isStateMatch || x.match(searchRegex)
  );

  const perDiems =
    ((data && data.perDiemRates?.ratesByState) as PerDiemRate[]) ?? [];

  const stateRates = perDiems.length > 0 ? arrangeRates(perDiems) : [];

  const filteredCounties =
    stateRates.length > 0
      ? searchRegex
        ? stateRates.filter((x) => isStateMatch || x.county.match(searchRegex))
        : stateRates
      : [];

  if (searchedCounties.length === 0 && !isStateMatch) {
    return null;
  }

  const button = (
    <Button
      icon={expanded ? "expand_less" : "expand_more"}
      onClick={handleClick}
      title={expanded ? `Hide ${state} counties` : `Show ${state} counties`}
    />
  );

  const tableStyle =
    expanded && !loading ? { maxHeight: 40 * (recordCount + 1) } : undefined;

  const subtitle = searchRegex
    ? `${searchedCounties.length} of ${totalCounties.length} counties, ${recordCount} of ${totalRecords} records`
    : `${totalCounties.length} counties, ${totalRecords} records`;

  return (
    <TitledCard
      title={<TextHighlighter text={state} search={searchRegex} />}
      subtitle={subtitle}
      actions={loading ? <InlineProgress /> : button}
      className="state-card"
      collapsed={loading || !expanded}
    >
      <Table compact style={tableStyle}>
        <TableHeader>
          <TableCell text="County" />
          <TableCell text="Effective" />
          <TableCell
            text="Meals (Reimbursable/Billable)"
            className="currency"
          />
          <TableCell
            text="Lodging (Reimbursable/Billable)"
            className="currency"
          />
          <TableCell
            text="Travel (Reimbursable/Billable)"
            className="currency"
          />
        </TableHeader>
        {filteredCounties.map((county) =>
          county.rows.map((row, rowIdx) => (
            <TableContent
              key={`${county.county}-${rowIdx}`}
              trailingSeparator={rowIdx === county.rows.length - 1}
            >
              <TableCell>
                {rowIdx === 0 ? (
                  <TextHighlighter text={row.county} search={searchRegex} />
                ) : (
                  " "
                )}
              </TableCell>
              <TableCell
                text={moment(row.effectiveDate, "YYYY-MM-DD").format(
                  "MM/DD/YYYY"
                )}
              />
              <TableCell
                text={`${formatter.format(
                  row.mealsReimbursable
                )} / ${formatter.format(row.mealsBillable)}`}
                className="currency"
              />
              <TableCell
                text={`${formatter.format(
                  row.lodgingReimbursable
                )} / ${formatter.format(row.lodgingBillable)}`}
                className="currency"
              />
              <TableCell
                text={`${formatter.format(
                  row.travelReimbursable
                )} / ${formatter.format(row.travelBillable)}`}
                className="currency"
              />
            </TableContent>
          ))
        )}
      </Table>
    </TitledCard>
  );
};

export default PerDiemState;
