import React from "react";
import { Chip } from "@material/react-chips";
import MaterialIcon from "@material/react-material-icon";
import "./FilterChip.scss";

export type FilterChipProps = {
  onClick: React.DispatchWithoutAction;
  label: string;
  className?: string;
};

export const FilterChip: React.FC<FilterChipProps> = ({
  onClick,
  label,
  className,
}) => {
  const classes = [className, "filter-chip"].filter((x) => !!x).join(" ");

  return (
    <Chip
      className={classes}
      label={label}
      // @ts-expect-error it works, the types are just janky
      title="(click to remove)"
      selected
      trailingIcon={<MaterialIcon icon="cancel" onClick={onClick} />}
    />
  );
};
