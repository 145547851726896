import { myWorkDayData } from "~/WorkData/MyWorkDay/MockData";
import { myWorkDataRoute, myWorkDayRoute } from "~/WorkData/routes";
import { WorkDayApi, FormErrors } from "../WorkDayApiType";

const create = (id: string) => () =>
  Promise.resolve({ errors: [], [id]: null }) as any;
const edit = () => Promise.resolve({ errors: [] }) as any;

const createExpense = create("expenseId");
const defaultResponse = (): Promise<FormErrors> => Promise.resolve({});

const { employee } = myWorkDayData;

export const defaultMockApi: WorkDayApi = {
  createAtvExpense: createExpense,
  editAtvExpense: edit,
  createFuelExpense: createExpense,
  editFuelExpense: edit,
  createMileageExpense: createExpense,
  editMileageExpense: edit,
  createMobileAllowanceExpense: createExpense,
  editMobileAllowanceExpense: edit,
  createPerDiemExpense: createExpense,
  editPerDiemExpense: edit,
  createReceiptValuedExpense: createExpense,
  editReceiptValuedExpense: edit,
  createTcpAllowanceExpense: createExpense,
  editTcpAllowanceExpense: edit,
  createAdHocExpense: createExpense,
  editAdHocExpense: edit,
  deleteExpense: edit,
  createTimeEntry: defaultResponse,
  editTimeEntry: defaultResponse,
  deleteTimeEntry: () => Promise.resolve(),
  createTimeOffEntry: defaultResponse,
  editTimeOffEntry: defaultResponse,
  deleteTimeOffEntry: () => Promise.resolve(),
  createProjectTime: defaultResponse,
  editProjectTime: defaultResponse,
  deleteProjectTime: () => Promise.resolve(),
  editProjectTimeDaily: edit,
  workDay: myWorkDayData,
  breadcrumbs: [
    {
      text: `My Work Summary: ${employee!.firstName} ${employee!.lastName} (${
        employee!.userPrincipalName
      })`,
      to: myWorkDataRoute.toRoute(employee!.userPrincipalName),
    },
    {
      text: `My Work Day: ${myWorkDayData.date}`,
      to: myWorkDayRoute.toRoute(
        employee!.userPrincipalName,
        myWorkDayData.date
      ),
    },
  ],
  upn: employee!.userPrincipalName,
  mode: "Mine",
};
