import React, { useState } from "react";
import TableCell from "~/old-table-cell";
import Table from "~/old-table";
import TableHeader from "~/old-table-header";
import TableContent from "~/old-table-content";
import Button from "~/button";
import "./RateSheets.scss";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useNavigate } from "react-router-dom";
import {
  viewExpenseRateSheet,
  createExpenseRateSheet,
  cloneExpenseRateSheet,
  createTaskRateSheet,
  viewTaskRateSheet,
  cloneTaskRateSheet,
} from "~/routes";
import ClickSwallower from "~/click-swallower";
import { useUser } from "~/Auth/useUser";
import moment from "moment";
import { createSearchRegex, TextHighlighter } from "~/text-highlighter";
import _ from "lodash";
import { ExpenseRateSheet, TaskRateSheet } from "~/gql/types";
import { Body2 } from "@material/react-typography";
import { FilterOptions, RateSheet, RateSheetType } from "./types";
import { ArchiveRateSheet } from "./Archive";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";
import Card from "@material/react-card";
import { FilterForm } from "./Filterform";
import { SearchFilterContainer } from "~/search-filter-container";

type RateSheetItemProps = {
  rateSheet: RateSheet;
  canEdit: boolean;
  type: RateSheetType;
  search: string;
};

const RateSheetItem: React.FC<RateSheetItemProps> = ({
  rateSheet,
  canEdit,
  type,
  search,
}) => {
  const { name, allowAssignment, endDate, lastUsed } = rateSheet;

  const cloneRoute =
    type === "Expense" ? cloneExpenseRateSheet : cloneTaskRateSheet;

  const searchRegex = createSearchRegex(search);

  return (
    <>
      <TableCell>
        <TextHighlighter text={name} search={searchRegex} />
      </TableCell>
      <TableCell text={allowAssignment ? "Yes" : "No"} />
      <TableCell text={endDate ? moment(endDate).format("MM/DD/yyyy") : ""} />
      <TableCell text={lastUsed ? moment(lastUsed).format("MM/DD/yyyy") : ""} />
      <TableCell>
        {canEdit && (
          <ClickSwallower>
            <ArchiveRateSheet {...{ sheet: rateSheet }} />
            <Button outlined route={cloneRoute.toRoute(name)}>
              Copy
            </Button>
          </ClickSwallower>
        )}
      </TableCell>
    </>
  );
};

type RateSheetsProps = {
  rateSheets: ExpenseRateSheet[] | TaskRateSheet[];
  type: RateSheetType;
  FilterFormComponent?: typeof FilterForm;
};

const RateSheets: React.FC<RateSheetsProps> = ({
  rateSheets,
  type,
  FilterFormComponent,
}) => {
  const [filters, setFilters] = useState<FilterOptions>({
    searchText: "",
    assignable: "Assignable",
    showArchived: "No",
  });
  const { searchText, showArchived, assignable } = filters;
  const navigate = useNavigate();
  const user = useUser();
  const canEdit = user.authorizations.legacyAuth.manageRateSheets;

  const allSheets = rateSheets as RateSheet[];

  useBreadcrumbs([{ text: `${type} Rate Sheets` }], []);

  const createRoute =
    type === "Expense" ? createExpenseRateSheet : createTaskRateSheet;

  const editActions = (
    <Button raised primary route={createRoute}>
      <i className="material-icons mdc-button__icon">add</i>
      Create rate sheet
    </Button>
  );
  const viewRoute =
    type === "Expense" ? viewExpenseRateSheet : viewTaskRateSheet;

  const filteredSheets = allSheets
    .filter((x) =>
      showArchived == "Yes" ? x.archived == true : x.archived == false
    )
    .filter(
      (x) =>
        assignable == "All" ||
        (assignable == "Assignable" && x.allowAssignment) ||
        (assignable == "Unassignable" && !x.allowAssignment)
    )
    .filter(
      (x) =>
        !searchText || x.name.toLowerCase().includes(searchText.toLowerCase())
    );
  const sorted = _.sortBy(filteredSheets, ["name"]);

  const assignableCount = allSheets.filter((x) => x.allowAssignment).length;
  const unAssignableCount = allSheets.filter((x) => !x.allowAssignment).length;

  const FilterComponent = FilterFormComponent || FilterForm;
  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);
  return (
    <SearchPageWrapper className="rate-sheets">
      <SearchFilterContainer>
        <FilterComponent onFiltersChanged={setFilterOptions} />
      </SearchFilterContainer>
      <SearchListContent
        loading={false}
        actions={
          <div className="rate-sheet-actions">
            <Body2>{`Assignable count: ${assignableCount}`}</Body2>
            <Body2>{`Unassignable count: ${unAssignableCount}`}</Body2>
            {canEdit && editActions}
          </div>
        }
      >
        <Card className="rate-sheets-results">
          <Table>
            <div className="header">
              <TableHeader>
                <TableCell text="Name" />
                <TableCell text="Assignable?" />
                <TableCell text="EndDate?" />
                <TableCell text="LastUsed?" />
              </TableHeader>
            </div>
            {sorted.map((rateSheet, idx) => (
              <TableContent
                key={idx}
                trailingSeparator={idx !== rateSheets.length - 1}
                onClick={() => navigate(viewRoute.toRoute(rateSheet.name).path)}
                className="rate-sheet"
              >
                <RateSheetItem
                  {...{
                    rateSheet,
                    canEdit: canEdit && !rateSheet.archived,
                    type,
                    search: searchText ?? "",
                  }}
                />
              </TableContent>
            ))}
          </Table>
        </Card>
      </SearchListContent>
    </SearchPageWrapper>
  );
};

export default RateSheets;
