import React from "react";
import { Form } from "react-final-form";
import TimeOffEntryForm from "./TimeOffEntryForm";
import { Duration } from "moment";
import { EeCodeRecord, TimeOffType } from "~/gql/types";
import { FormEnterHandler } from "~/forms/FormEnterHandler";

type TimeOffEntryProps = {
  handleSubmit: (
    values: FormData
  ) => Promise<Record<string, unknown> | undefined>;
  initialValues?: FormData;
  type: "Create" | "Edit";
  date: string;
  loading: boolean;
  onDelete?: () => void;
};

export type FormData = {
  hours: Duration | null;
  timeOffType: TimeOffType | null;
  eeCode: EeCodeRecord | null;
  adminNotes: string | null;
  copyToDate: boolean;
  copyToDates: boolean;
};

export const initialNullValues = {
  hours: null,
  timeOffType: null,
  eeCode: null,
  adminNotes: null,
  copyToDate: false,
  copyToDates: false,
};

const TimeOffEntry: React.FC<TimeOffEntryProps> = ({
  handleSubmit,
  initialValues,
  type,
  date,
  loading,
  onDelete,
}) => {
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={type === "Edit" ? initialValues : initialNullValues}
      render={({ handleSubmit, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TimeOffEntryForm
              type={type}
              errorMessage={submitError}
              date={date}
              loading={loading}
              onDelete={onDelete}
            />
            <FormEnterHandler />
          </form>
        );
      }}
    />
  );
};

export default TimeOffEntry;
