import React from "react";
import { FormFieldProps } from "~/forms/FormFieldProps";
import { createSearchRegex } from "~/text-highlighter/createSearchRegex";
import { TextHighlighter } from "~/text-highlighter";
import { EeCodeRecord } from "~/gql/types";
import { useEeCodesAsync } from "~/refdata2/eeCodes";
import _ from "lodash";
import MaterialIcon from "@material/react-material-icon";
import { MruPicker, MruPickerProps } from "~/mru-picker";
import { formatLastFirstMiddle } from "~/utils/formatEmployeeName";

type EeCodeItem = EeCodeRecord & { recent?: boolean };

const format = (item: EeCodeItem, searchText: string): React.ReactNode => {
  const searchRegex = createSearchRegex(searchText);

  return (
    <div className="menuitem">
      <TextHighlighter text={item.eeCode} search={searchRegex} />
      {item.employee && (
        <TextHighlighter
          text={` (${formatLastFirstMiddle(item.employee)})`}
          search={searchRegex}
        />
      )}
      {!item.employee && item.description?.length > 0 && (
        <TextHighlighter text={` (${item.description})`} search={searchRegex} />
      )}
      {item.recent && <MaterialIcon icon="history" />}
    </div>
  );
};

const chipFormat = (item: EeCodeItem) => {
  return item.employee
    ? `${item.eeCode} (${formatLastFirstMiddle(item.employee)})`
    : item.eeCode;
};

export const EeCodePickerFormField: React.FC<FormFieldProps> = (props) => {
  const getEeCodes = useEeCodesAsync();

  const fetchData = (updateItems: (result: EeCodeItem[]) => EeCodeItem[]) => {
    return async (searchText: string) => {
      const result = (await getEeCodes()) ?? [];
      const active = result.filter((x) => x.isActive);
      const converted = updateItems(active);

      if (!searchText) {
        return converted;
      }

      const lcText = searchText.toLowerCase();

      return _.chain(active)
        .filter(
          (x) =>
            x.eeCode.toLowerCase().startsWith(lcText) ||
            (x.description && x.description.toLowerCase().includes(lcText)) ||
            (x.employee &&
              (x.employee.lastName.toLowerCase().startsWith(lcText) ||
                x.employee.firstName.toLowerCase().startsWith(lcText))) ||
            false
        )
        .orderBy((x) => x.eeCode)
        .value();
    };
  };

  const args = {
    ...props,
    fetchData,
    format,
    chipFormat,
    fieldsEqual: (storedItem: EeCodeItem, dataItem: EeCodeItem) =>
      storedItem.eeCode === dataItem.eeCode,
    className: "ee-code-picker",
    storageKey: "recent-eecodes",
  } as MruPickerProps<EeCodeItem>;

  return <MruPicker {...args} />;
};
