import React from "react";
import { DescriptionList } from "~/description-list";
import { ThirdPartyInvoice } from "~/gql/types";
import PageTitleRow from "~/page-title-row";
import Card from "@material/react-card";
import "./ThirdPartyInvoicePage.scss";
import ImageLoader from "~/visuals/organisms/Expenses/ExpenseForm/ImageLoader";
import { currencyFormatter } from "~/currencyFormatter";
import moment from "moment";
import { Button } from "rmwc";
import { editThirdPartyInvoice } from "~/routes";
import { useNavigate } from "react-router-dom";
import { ApproveButton } from "../ApproveThirdPartyInvoice";
import { RejectButton } from "../RejectThirdPartyInvoice";
import { useUser } from "~/Auth/useUser";

type ThirdPartyInvoiceProps = {
  thirdPartyInvoice: ThirdPartyInvoice;
  refetch: () => void;
};
export const ThirdPartyInvoicePage: React.FC<ThirdPartyInvoiceProps> = ({
  thirdPartyInvoice,
  refetch,
}) => {
  const {
    invoiceNumber,
    amount,
    date,
    project,
    state,
    documentUrl,
    notes,
    eeCode,
  } = thirdPartyInvoice;
  const { number, name, customer } = project!;

  const actions = (
    <>
      <ApproveButton invoiceNumber={invoiceNumber} refetch={refetch} />
      <RejectButton invoiceNumber={invoiceNumber} refetch={refetch} />

      <EditButton invoiceNumber={invoiceNumber} />
    </>
  );

  return (
    <DescriptionList className="third-party-invoice-page">
      <PageTitleRow
        title={`Third Party Invoice ${invoiceNumber} - ${state}`}
        actions={state == "Unreviewed" ? actions : <></>}
      />
      <Card>
        <div className="third-party-invoice-info">
          <dl>
            <dt>Date</dt>
            <dd>{moment.utc(date).format("MM/DD/yyyy")}</dd>
            <dt>Project Name</dt>
            <dd>{name}</dd>
            <dt>Project Number</dt>
            <dd>{number}</dd>
            <dt>Customer Name</dt>
            <dd>{customer?.name}</dd>
            <dt>Customer Number</dt>
            <dd>{customer?.number}</dd>
            <dt>Amount</dt>
            <dd>{currencyFormatter.format(amount)}</dd>
            <dt>EE Code</dt>
            <dd>{eeCode}</dd>
            <dt>Notes</dt>
            <dd>{notes ?? ""}</dd>
          </dl>
          <dl>
            <ImageLoader className={"document-image"} imageUrl={documentUrl} />
          </dl>
        </div>
      </Card>
    </DescriptionList>
  );
};

const EditButton: React.FC<{ invoiceNumber: string }> = ({ invoiceNumber }) => {
  const navigate = useNavigate();
  const user = useUser();
  if (!user.authorizations.legacyAuth.createThirdPartyInvoices) {
    return null;
  }
  return (
    <Button
      onClick={() =>
        navigate(editThirdPartyInvoice.toRoute(invoiceNumber).path)
      }
    >
      Edit
    </Button>
  );
};
