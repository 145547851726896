import React, { useContext, useEffect, useMemo, useState } from "react";
import { Employee } from "~/gql/types";
import { useRawJwt } from "../AuthenticationManager";
import { Validating } from "../AuthenticationManager/Validating";
import { Authorizations, GetAuthorizationsAsync } from "../types";

export const sessionStorageKey = "impersonated-employee";

type ImpersonationContextData = {
  getAuthorizations: GetAuthorizationsAsync;
  employee: Employee | null;
  setEmployee: React.Dispatch<Employee | null>;
  validating: boolean;
  setValidating: React.Dispatch<boolean>;
  authorizations: Authorizations | null;
  setAuthorizations: React.Dispatch<Authorizations | null>;
};

export const ImpersonationContext =
  React.createContext<ImpersonationContextData | null>(null);

async function beginImpersonating(
  employee: Employee,
  token: string,
  context: ImpersonationContextData
) {
  const { setValidating, getAuthorizations, setEmployee, setAuthorizations } =
    context;
  setValidating(true);

  const auths = await getAuthorizations(token!, employee.userPrincipalName);

  setValidating(false);
  setEmployee(employee);
  setAuthorizations(auths);
  sessionStorage.setItem(sessionStorageKey, JSON.stringify(employee));
}

export function useImpersonationContext(
  caller: string
): ImpersonationContextData {
  const contextVal = useContext(ImpersonationContext);

  if (contextVal === null) {
    throw new Error(`Do not use ${caller} outside of an ImpersonationManager.`);
  }

  return contextVal;
}

export function useImpersonatedAuthorizations(): Authorizations | null {
  const { authorizations } = useImpersonationContext(
    "useImpersonatedAuthorizations"
  );

  return authorizations;
}

export function useImpersonatedEmployee(): Employee | null {
  const { employee } = useImpersonationContext("useImpersonatedEmployee");

  return employee;
}

export function useStopImpersonating(): () => void {
  const { setEmployee, setAuthorizations } = useImpersonationContext(
    "useStopImpersonating"
  );

  return () => {
    setEmployee(null);
    setAuthorizations(null);
    sessionStorage.removeItem(sessionStorageKey);
  };
}

export function useImpersonate(): (employee: Employee) => void {
  const token = useRawJwt();
  const context = useImpersonationContext("useImpersonate");

  return (emp) => {
    void beginImpersonating(emp, token!, context);
  };
}

export const ImpersonationManager: React.FC<ImpersonationManagerProps> = ({
  children,
  getAuthorizations,
}) => {
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [authorizations, setAuthorizations] = useState<Authorizations | null>(
    null
  );
  const [validating, setValidating] = useState(false);
  const token = useRawJwt();

  const contextValue = useMemo<ImpersonationContextData>(
    () => ({
      getAuthorizations,
      employee,
      setEmployee,
      validating,
      setValidating,
      authorizations,
      setAuthorizations,
    }),
    [
      getAuthorizations,
      employee,
      setEmployee,
      validating,
      authorizations,
      setAuthorizations,
    ]
  );

  useEffect(() => {
    const empJson = sessionStorage.getItem(sessionStorageKey);

    if (!empJson) {
      return;
    }

    const emp = JSON.parse(empJson);

    void beginImpersonating(emp, token!, contextValue);
  }, []);

  return (
    <ImpersonationContext.Provider value={contextValue}>
      {!validating && children}
      {validating && <Validating />}
    </ImpersonationContext.Provider>
  );
};

export type ImpersonationManagerProps = {
  getAuthorizations: GetAuthorizationsAsync;
};
