import {
  BillingFrequencyType,
  Customer,
  GovernmentFundingType,
  Project,
  Employee,
  CorpLocation,
  Division,
  ProjectStatus,
} from "~/gql/types";

const frequency = BillingFrequencyType.Weekly;
const funding = GovernmentFundingType.None;
const corpLocation: CorpLocation = {
  id: "TYPE-1",
  name: "TCO-COLORADO-LAFAYETTE",
  companyName: "TOPO FReal",
  isActive: true,
};
const division: Division = {
  id: "DIV-1",
  name: "Surveying-Civil",
  isActive: true,
};

const testCustomer: Customer = {
  name: "Company One",
  number: "54321",
  address1: "123 Main St.",
  address2: "",
  city: "Tulsa",
  state: "OK",
  zip: "74120",
} as Customer;

export const employees = [
  {
    firstName: "Joe",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Bob",
    ultiProId: "ABC123",
    userPrincipalName: "joe@topographic.com",
  },
  {
    firstName: "Sally",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Field",
    ultiProId: "DEF456",
    userPrincipalName: "sally@topographic.com",
  },
  {
    firstName: "Ricky",
    isMobileEmployee: true,
    isTerminated: false,
    lastName: "Ricardo",
    ultiProId: "GHI789",
    userPrincipalName: "ricky@topographic.com",
  },
] as Employee[];

export const project: Project = {
  name: "Test Project",
  description: "Project description",
  number: 1234,
  customer: testCustomer,
  location: { state: "OK", county: "TULSA" },
  expenseRateSheets: [],
  taskRateSheets: [],
  clientContactName: "Test Person",
  survey: "Public",
  networkPath: "@//server/loc1/loc2",
  billingFrequency: frequency,
  governmentFunding: funding,
  billingAdmin: employees[0],
  projectManager: employees[1],
  projectSupervisor: employees[2],
  officeCode: "CE",
  corpLocation,
  budget: 4000,
  legacyBilled: 1000,
  division,
  allowThirdPartyInvoice: false,
  callerName: "Person Personson",
  canAddCharge: true,
  canCreateInvoice: true,
  canEdit: true,
  canViewInvoices: true,
  createdOn: "2024-01-01",
  certifiedPayroll: false,
  dateIn: "2024-01-01",
  geographicLocation: "",
  status: ProjectStatus.Active,
  internalNotes: "",
  invoiceNotes: "",
  legacy: false,
  originalName: "",
  scope: "",
  simplifiedName: "Test Project",
  stats: {
    totalUnbilled: 250,
    totalPendingApproval: 250,
    totalBilled: 1250,
    totalPrPending: 250,
    totalPmPending: 250,
    totalPendingBilling: 250,
    totalNonBillable: 500,
  } as Project["stats"],
  serviceDescription: {
    projectType: "GIS",
  } as Project["serviceDescription"],
};
