import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useWorkDayApi } from "~/WorkDayApi";
import EditTimeOffEntry from "./EditTimeOff";

const EditTimeOff: React.FC = () => {
  const { timeOffEntryId, date } = useParams<{
    timeOffEntryId: string;
    date: string;
  }>();
  const workDayApi = useWorkDayApi();

  const timeOffEntry = workDayApi.workDay.timeOff?.find(
    (entry) => entry!.id === timeOffEntryId
  );

  if (!timeOffEntry) {
    return <Navigate to={"../../"} />;
  }

  return <EditTimeOffEntry {...{ timeOffEntry, date: date! }} />;
};

export default EditTimeOff;
