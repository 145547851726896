import React, { useState } from "react";
import { Form } from "react-final-form";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import Button from "~/button";
import Spinner from "~/spinner";
import { useSnackBar } from "~/snackbar";
import BinaryPickerFormField from "~/visuals/organisms/BinaryPickerFormField";
import "./EmployeeRoles.scss";
import { useResetSearchItems } from "~/search-page-wrapper/infinite-scroll/ResetSearchItems";
import {
  useAddEmployeeRoleMutation,
  useRemoveEmployeeRoleMutation,
} from "~/roles/mutations.generated";
import { Employee, ValidationError } from "~/gql/types";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

export const companyRoles = [
  "Accounting",
  "Billing Admin",
  "Billing Supervisor",
  "Corporate Payroll",
  "Payroll Admin",
  "Project Manager",
  "Project Supervisor",
  "Revenue Reporting",
  "Supervisor",
  "System Administrator",
];

type FormValues = {
  role: boolean;
};

export const EmployeeRoles: React.FC<{ employee: Employee }> = ({
  employee,
}) => {
  const [open, setOpen] = useState(false);
  const roles = employee?.roles?.map((r) => r!.roleName) ?? [];
  const alert = useSnackBar();
  const employeeName = formatFirstMiddleLast(employee);
  const reset = useResetSearchItems();

  const [edited, setEdited] = useState(false);

  const [addRole, { loading: addLoading }] = useAddEmployeeRoleMutation();
  const [removeRole, { loading: removeLoading }] =
    useRemoveEmployeeRoleMutation();

  const handleSubmit = async (roleName: string, values: FormValues) => {
    const mutation = values.role ? addRole : removeRole;
    const mutationKey = values.role ? "addRole" : "removeRole";
    const messageAction = values.role ? "added to" : "removed from";
    const successMessage = `Role ${roleName} ${messageAction} ${employeeName}`;

    const variables = {
      role: roleName,
      userPrincipalName: employee.userPrincipalName,
    };

    const { data, errors } = await mutation({ variables });

    const response = data?.employees?.[mutationKey];

    const responseErrors =
      errors ?? response?.errors ?? ([] as ValidationError[]);

    if (responseErrors.length > 0) {
      const messageErrors: string[] = [];
      responseErrors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          messageErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          messageErrors.push(message);
        }
      });

      alert({
        key: roleName,
        message: messageErrors.join(" "),
        isSuccess: false,
      });
    } else {
      alert({
        key: roleName,
        message: successMessage,
        isSuccess: true,
      });

      setEdited(true);
    }
  };

  const onClose = () => {
    if (edited) {
      reset();
    }
    setOpen(false);
  };

  return (
    <>
      <Button icon="badge" onClick={() => setOpen(true)}>
        Roles
      </Button>
      <ClickSwallower>
        <Dialog
          className="employee-roles"
          open={open}
          onClose={onClose}
          data-open={open}
          portalize
        >
          <DialogTitle>{`${employeeName} Roles`}</DialogTitle>
          <DialogContent>
            {companyRoles.map((name, idx) => (
              <Form
                key={idx}
                onSubmit={(vals) => handleSubmit(name, vals as FormValues)}
                initialValues={{ role: roles.some((r) => r === name) ?? false }}
                render={({ handleSubmit, form }) => (
                  <form
                    onSubmit={handleSubmit}
                    onChange={() => {
                      void form.submit();
                    }}
                  >
                    <BinaryPickerFormField
                      formField="role"
                      label={name}
                      helperText=""
                    />
                  </form>
                )}
              />
            ))}
            <Button className="roles-close-button" onClick={onClose}>
              Close
            </Button>
          </DialogContent>
        </Dialog>
        <Spinner open={addLoading || removeLoading} />
      </ClickSwallower>
    </>
  );
};
