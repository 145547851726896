import React, { useEffect, useState } from "react";
import { FilterForm, FilterOptions } from "../TimesheetReview/FilterForm";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { TextHighlighter } from "~/text-highlighter";
import moment, { Duration } from "moment";
import Card from "@material/react-card";
import {
  State,
  Action,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import useInfiniteScroll from "~/search-page-wrapper/infinite-scroll/useInfiniteScroll";
import { VisibilityListener } from "~/visibility-listener";
import { TableCell } from "~/table";
import "./ExpensesSearch.scss";
import { ReceiptActions, ReceiptTypeExpense } from "./ReceiptActions";
import { TextHighlighterProvider } from "~/text-highlighter/TextHighlighterProvider";
import { Body2 } from "@material/react-typography";
import { ExpensePayableColumns } from "~/visuals/organisms/ExpensePayableAmount/ExpensePayableColumns";
import { SearchFilterContainer } from "~/search-filter-container";
import {
  AtvExpense,
  Employee,
  FuelExpense,
  MileageExpense,
  MobileAllowanceExpense,
  PerDiemExpense,
  ReceiptValuedExpense,
  TcpAllowanceExpense,
} from "~/gql/types";
import { useExpensesSearchQuery } from "./query.generated";
import { timesheetReview } from "../TimesheetReview/routes";
import classNames from "classnames";
import { useUser } from "~/Auth/useUser";
import { SearchType } from "~/visuals/organisms/TimesheetDisplay/types";
import { LinkClickHandler } from "~/link-click-handler";
import { SearchPageWrapper, SearchListContent } from "~/search-page-wrapper";

export type ExpenseResult = {
  expense:
    | AtvExpense
    | FuelExpense
    | MileageExpense
    | MobileAllowanceExpense
    | PerDiemExpense
    | ReceiptValuedExpense
    | TcpAllowanceExpense;
  employee: Employee;
};

export type ExpenseSearchState = State<ExpenseResult, FilterOptions>;

export const formatDuration = (d: Duration) =>
  `${Math.floor(d.asHours())}:${(d.minutes() + "").padStart(2, "0")}`;
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const addDurations = (d1, d2) =>
  moment.duration(d1.asMilliseconds() + d2.asMilliseconds());

type ExpenseRowProps = {
  expenseResult: ExpenseResult;
  refetch: React.DispatchWithoutAction;
  type?: SearchType;
};

const ExpenseRow: React.FC<ExpenseRowProps> = ({
  expenseResult,
  refetch,
  type,
}) => {
  const Highlight = ({ text }) => (
    <Body2>
      <TextHighlighter {...{ text }} />
    </Body2>
  );
  const { expense, employee } = expenseResult;

  const hasReceipt =
    (expense?.__typename === "ReceiptValuedExpense" ||
      expense?.__typename === "FuelExpense" ||
      expense?.__typename === "PerDiemExpense") &&
    (expense.receiptImageId ||
      expense["fuelReceiptId"] ||
      expense["perDiemReceiptId"]);

  const path = `${timesheetReview}/${employee.userPrincipalName}/${expense.date}`;

  const showMine = type === "MyExpenses";

  return (
    <LinkClickHandler path={path} className="row">
      <TableCell text={moment(expense.date).format("MM/DD/YYYY")} />
      {!showMine && (
        <TableCell>
          <Highlight text={employee.lastName} />
        </TableCell>
      )}
      {!showMine && (
        <TableCell>
          <Highlight text={employee.firstName} />
        </TableCell>
      )}
      <TableCell
        text={`${expense.project!.name} (${expense.project!.number})`}
      />
      {!showMine && <TableCell text={expense.expenseAccount ?? ""} />}
      <TableCell text={expense.eeCode} />
      <TableCell text={expense.rateName} />
      <ExpensePayableColumns {...{ expense, refetch }} />
      {hasReceipt ? (
        <ReceiptActions
          {...{ expense: { ...expense, employee } as ReceiptTypeExpense }}
        />
      ) : (
        <TableCell />
      )}
    </LinkClickHandler>
  );
};

type LoadExpensesProps = {
  dispatch: React.Dispatch<Action<ExpenseResult, FilterOptions>>;
  tag: "DataLoaded" | "MoreDataLoaded";
  searchText: string | null;
  token: string | null;
  filterOptions: FilterOptions;
  type?: SearchType;
};

const LoadExpenses = ({
  dispatch,
  tag,
  searchText,
  token,
  type,
  filterOptions: {
    weekStart,
    workLocation,
    dateAfter,
    dateBefore,
    weekStatus,
    reimbursement,
    payrollAdmin,
    payPeriod,
  },
}: LoadExpensesProps) => {
  const user = useUser();

  const { data } = useExpensesSearchQuery({
    variables: {
      searchText,
      token,
      week: weekStart?.format("YYYY-MM-DD") ?? null,
      workLocation,
      status: weekStatus,
      reimbursement,
      payrollAdmin: payrollAdmin?.userPrincipalName ?? null,
      payPeriod,
      dateBefore: dateBefore?.format("YYYY-MM-DD") ?? null,
      dateAfter: dateAfter?.format("YYYY-MM-DD") ?? null,
      userPrincipalName: type === "MyExpenses" ? user.email : null,
    },
  });

  useEffect(() => {
    void (async () => {
      if (data) {
        const newToken = data?.timesheets?.expenseSearch?.token as
          | string
          | null;
        const newExpenses = data?.timesheets?.expenseSearch
          ?.records as ExpenseResult[];
        dispatch({ tag, items: newExpenses, searchToken: newToken });
      }
    })();
  }, [data]);

  return <></>;
};

export type ExpensesSearchDisplayProps = {
  showSpinner: boolean;
  state: ExpenseSearchState;
  dispatch: React.Dispatch<Action<ExpenseResult, FilterOptions>>;
  type?: SearchType;
};

export const ExpensesSearchDisplay: React.FC<ExpensesSearchDisplayProps> = ({
  showSpinner,
  state,
  dispatch,
  type,
}) => {
  const refetch = () =>
    dispatch({
      tag: "Reset",
      filterOptions: state.filterOptions,
    });

  const showMine = type === "MyExpenses";

  const tableClass = classNames("table", {
    all: !showMine,
  });

  useBreadcrumbs([{ text: `${showMine ? "My " : ""}Expenses Search` }], []);

  return (
    <TextHighlighterProvider searchText={state.filterOptions.searchText}>
      <SearchListContent
        loading={showSpinner}
        loadingMore={state.tag === "LoadingMore"}
      >
        <Card className="expense-search-results-card">
          <div className={tableClass}>
            <div className="row header">
              <TableCell text="Date" />
              {!showMine && <TableCell text="Last Name" />}
              {!showMine && <TableCell text="First Name" />}
              <TableCell text="Project" />
              {!showMine && <TableCell text="Expense Acc." />}
              <TableCell text="EE Code" />
              <TableCell text="Type" />
              <TableCell text="Reimb" />
              <TableCell text="Payroll" />
              <TableCell text="Comp Paid" />
              <TableCell text="Receipt" />
            </div>
            {state.items &&
              state.items.map((x, i) => (
                <ExpenseRow key={i} {...{ expenseResult: x, refetch, type }} />
              ))}
            {state.showVisibility && (
              <VisibilityListener
                onShown={() => dispatch({ tag: "ScrolledToBottom" })}
              />
            )}
          </div>
        </Card>
      </SearchListContent>
    </TextHighlighterProvider>
  );
};

type ExpenseSearchProps = {
  FilterFormComponent?: typeof FilterForm;
  type?: SearchType;
};

export const ExpensesSearch: React.FC<ExpenseSearchProps> = (props) => {
  const [filters, setFilters] = useState<FilterOptions | null>(null);
  const FilterFormComponent = props.FilterFormComponent || FilterForm;

  const setFilterOptions = (opts: FilterOptions) => setFilters(opts);

  return (
    <SearchPageWrapper>
      <SearchFilterContainer>
        <FilterFormComponent
          onFiltersChanged={setFilterOptions}
          dialogTitle="Expenses Search"
          label="Type to search expenses"
          type={props.type ?? "All"}
        />
      </SearchFilterContainer>
      {filters && <ExpensesSearchResults {...{ ...props, filters }} />}
    </SearchPageWrapper>
  );
};

export const ExpensesSearchResults: React.FC<
  ExpenseSearchProps & { filters: FilterOptions }
> = (props) => {
  const { filters, type } = props;

  const initialState = {
    tag: "Loading",
    items: [],
    showVisibility: false,
    loading: true,
    filterOptions: filters,
    searchToken: null,
    selectedItems: [],
    selectIdKey: "",
  } as State<ExpenseResult, FilterOptions>;

  const { state, dispatch } = useInfiniteScroll<ExpenseResult, FilterOptions>(
    initialState
  );

  useEffect(() => {
    dispatch({
      tag: "SearchCriteriaChanged",
      filterOptions: filters,
    });
  }, [filters]);

  const loadProps = {
    dispatch,
    searchText: state.filterOptions.searchText,
    token: state.searchToken,
    filterOptions: state.filterOptions,
    type,
  };

  return (
    <>
      {state.tag === "Loading" && (
        <LoadExpenses
          {...{
            tag: "DataLoaded",
            ...loadProps,
            token: null,
          }}
        />
      )}
      {state.tag === "LoadingMore" && (
        <LoadExpenses
          {...{
            tag: "MoreDataLoaded",
            ...loadProps,
          }}
        />
      )}
      <ExpensesSearchDisplay
        {...{
          showSpinner: state.loading,
          state,
          dispatch,
          type,
        }}
      />
    </>
  );
};
