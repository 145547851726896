import React from "react";
import { FilterForm } from "./FilterForm";
import { TimeSheetDisplay } from "~/visuals/organisms/TimesheetDisplay";
import { FilterFormProps } from "~/visuals/organisms/TimesheetDisplay/types";
import { Route, Routes } from "react-router-dom";
import { weeklyProjectTasks } from "../routes";
import { WeeklyProjectTasks } from "~/visuals/pages/WeeklyProjectTasks";

type TimesheetReviewProps = {
  FilterFormComponent?: React.FC<FilterFormProps>;
};
export const MyWorkWeeks: React.FC<TimesheetReviewProps> = (props) => (
  <Routes>
    <Route path={weeklyProjectTasks}>
      <Route path=":weekStart" element={<WeeklyProjectTasks />} />
    </Route>
    <Route
      index
      element={
        <TimeSheetDisplay
          // @ts-expect-error TODO sort this out
          FilterFormComponent={props.FilterFormComponent ?? FilterForm}
          mode={"MyTimesheets"}
        />
      }
    />
  </Routes>
);
