import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "~/dialog";
import ClickSwallower from "~/click-swallower";
import Button from "~/button";
import Spinner from "~/spinner";
import { useSnackBar } from "~/snackbar";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import moment, { Moment } from "moment";
import DatePickFormField from "~/visuals/organisms/DatePickFormField";
import { useUpdateFinalPayrollMutation } from "~/visuals/pages/Employees/finalPayroll.generated";
import FormBottomRow from "~/form-bottom-row";
import { Employee, ValidationResponse } from "~/gql/types";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

export type EmployeeFinalPayrollProps = {
  employee: Employee;
  outlined?: boolean;
  reset: () => void;
};

export const EmployeeFinalPayroll: React.FC<EmployeeFinalPayrollProps> = ({
  employee,
  outlined,
  reset,
}) => {
  const [open, setOpen] = useState(false);
  const alert = useSnackBar();
  const employeeName = formatFirstMiddleLast(employee);

  const initialValues = {
    finalPayroll: employee.finalPayroll ? moment(employee.finalPayroll) : null,
  };

  const onSuccess = () => {
    alert({
      message: `${employeeName} final payroll updated`,
      isSuccess: true,
      key: `${Math.random()}`,
    });
    reset();
    setOpen(false);
  };

  const [doMutation, { loading: mutationLoading }] =
    useUpdateFinalPayrollMutation();

  const runMutation = async (values: { finalPayroll: Moment | null }) => {
    const variables = {
      userPrincipalName: employee.userPrincipalName,
      finalPayroll: values?.finalPayroll?.format("YYYY-MM-DD") ?? null,
    };

    const result = await doMutation({ variables });

    return result?.data?.employees?.updateFinalPayroll as ValidationResponse;
  };

  return (
    <>
      <Button
        icon={outlined ? undefined : "event_busy"}
        outlined={outlined}
        onClick={() => setOpen(true)}
      >
        Final Payroll
      </Button>
      <ClickSwallower>
        <Dialog
          className="employee-roles"
          open={open}
          onClose={() => setOpen(false)}
          data-open={open}
          portalize
        >
          <DialogTitle>{`${employeeName} Final Payroll`}</DialogTitle>
          <DialogContent>
            <MutationForm
              initialValues={initialValues}
              runMutation={runMutation}
              allowPristineSubmit
              onSuccess={onSuccess}
            >
              <ErrorRenderer
                render={(error) => (
                  <>
                    <DatePickFormField
                      formField="finalPayroll"
                      label="Final Payroll"
                      helperText="Select the final payroll date"
                    />
                    <FormBottomRow
                      errorMessage={error}
                      buttonText="Save"
                      onCancel={() => setOpen(false)}
                    />
                  </>
                )}
              />
            </MutationForm>
          </DialogContent>
        </Dialog>
      </ClickSwallower>
      <Spinner open={mutationLoading} />
    </>
  );
};
