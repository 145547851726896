import React, { useState } from "react";
import {
  CustomRollupInvoice,
  ProjectGroup,
  ValidationResponse,
} from "~/gql/types";
import { Dialog, DialogTitle, DialogContent } from "~/dialog";
import { ErrorRenderer, MutationForm } from "~/forms/MutationForm";
import Spinner from "~/spinner";
import Button from "~/button";
import { useSnackBar } from "~/snackbar";
import { useInvoice, useInvoiceContextData } from "./InvoiceContext";

import { useBulkAddToRollupInvoiceMutation } from "../../../invoice-search/bulkAddToRollup.generated";
import { RollupInvoicePicker } from "~/rollup-invoice-picker";
import FormBottomRow from "~/form-bottom-row";
import "./AddToRollup.scss";

export const AddToRollup: React.FC = () => {
  const invoice = useInvoice();

  if (!invoice.canPostToRollup) {
    return null;
  }

  return <AddToRollupDialog />;
};

const AddToRollupDialog: React.FC = () => {
  const { invoice, reload } = useInvoiceContextData();

  const [open, setOpen] = useState(false);
  const addAlert = useSnackBar();

  const [doMutation, { loading }] = useBulkAddToRollupInvoiceMutation();

  const runMutation = async (values: {
    rollupInvoice: CustomRollupInvoice | null;
  }) => {
    const variables = {
      addInvoicesArgs: [
        {
          invoiceNumbers: [invoice.invoiceNumber],
          customRollupInvoiceNumber:
            values.rollupInvoice?.invoiceNumber ?? null,
        },
      ],
    };

    const result = await doMutation({ variables });

    return result.data?.customRollupInvoices
      ?.bulkAddLineItems as ValidationResponse;
  };

  const onSuccess = () => {
    addAlert({
      message: `Invoice ${invoice.invoiceNumber} added to custom rollup invoice`,
      isSuccess: true,
      key: `${Math.random()}`,
    });
    setOpen(false);
    reload();
  };
  const initialValues = { rollupInvoice: null };
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add to rollup</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        data-open={open}
        className="add-invoice-to-rollup"
      >
        <DialogTitle>Add To Custom Rollup Invoice</DialogTitle>
        <DialogContent>
          <MutationForm
            initialValues={initialValues}
            onSuccess={onSuccess}
            allowPristineSubmit={true}
            runMutation={runMutation}
          >
            <RollupInvoicePicker
              label={"Custom Rollup Invoice"}
              formField={"rollupInvoice"}
              helperText={""}
              projectGroup={
                {
                  name: invoice.project!.projectGroup!.name,
                  customer: { number: invoice.customerNumber },
                } as ProjectGroup
              }
              excludedProject={invoice.projectNumber}
            />
            <ErrorRenderer
              render={(error) => (
                <FormBottomRow
                  errorMessage={error}
                  buttonText={"Add"}
                  onCancel={() => setOpen(false)}
                />
              )}
            />
          </MutationForm>
        </DialogContent>
      </Dialog>
      <Spinner open={loading} />
    </>
  );
};
