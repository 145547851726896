import React from "react";
import "./RateSheetTitle.scss";
import moment from "moment";

type RateSheetTitleProps = {
  rateSheet: { name: string; endDate?: string | null; archived: boolean };
};

export const RateSheetTitle: React.FC<RateSheetTitleProps> = ({
  rateSheet,
}) => (
  <div className="ratesheet-title">
    {`${rateSheet?.name} ${rateSheet?.archived ? "(Archived)" : ""}`}
    {rateSheet.endDate && (
      <span className="ratesheet-enddate">
        End Date: {moment(rateSheet.endDate, "YYYY-MM-DD").format("MM/DD/YYYY")}
        &nbsp; ({moment(rateSheet.endDate, "YYYY-MM-DD").fromNow()})
      </span>
    )}
  </div>
);
