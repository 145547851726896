import { useSnackBar } from "~/snackbar";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import { ProjectRequest } from "~/gql/types";
import { useCheckoutProjectRequestMutation } from "./checkout.generated";
import { useUser } from "~/Auth/useUser";
import { formatFirstMiddleLast } from "~/utils/formatEmployeeName";

type UseCheckoutProps = {
  projectRequest: ProjectRequest;
  isGroupReview?: boolean;
};

type UseCheckoutReturn = {
  checkout: (refetch: React.DispatchWithoutAction) => Promise<void>;
  loading: boolean;
  isCheckerOuter: boolean;
};

export function useCheckout(props: UseCheckoutProps): UseCheckoutReturn {
  const { projectRequest, isGroupReview } = props;

  const addAlert = useSnackBar();
  const prompt = useConfirmationPrompt();
  const [doCheckout, { loading }] = useCheckoutProjectRequestMutation();

  const user = useUser();

  if (!projectRequest) {
    return {
      isCheckerOuter: false,
      loading: false,
      checkout: () => Promise.resolve(),
    };
  }

  const isCheckerOuter =
    user.email.toLowerCase() ==
    projectRequest.checkedOutBy?.userPrincipalName!.toLowerCase();

  const checkout = async (refetch: React.DispatchWithoutAction) => {
    const needsOverride = !!projectRequest.checkedOutBy && !isCheckerOuter;

    if (needsOverride) {
      const checkedOutBy = projectRequest.checkedOutBy!;

      const promptResult = await prompt({
        cancelButtonText: "Cancel",
        confirmButtonText: "Yes, checkout",
        message:
          `The ${
            isGroupReview ? "requests are" : "request is"
          } currently checked out by ${formatFirstMiddleLast(checkedOutBy)}.` +
          "Are you sure you want to checkout?",
        title: `Checkout Project Request${isGroupReview ? "s" : ""}`,
      });

      if (promptResult !== "Confirm") {
        return;
      }
    }

    const result = await doCheckout({
      variables: {
        requestId: projectRequest.requestId,
        overrideCheckout: needsOverride,
      },
    });

    const errors =
      result?.errors ?? result?.data?.projectRequests?.checkout?.errors;

    const key = `${Math.random()}`;

    if (errors && errors.length > 0) {
      addAlert({
        message: errors.map((x) => x!.message).join(" "),
        isSuccess: false,
        key,
      });
    } else {
      addAlert({
        message: `Project request${isGroupReview ? "s" : ""} checked out`,
        isSuccess: true,
        key,
      });
      void refetch();
    }
  };

  return { checkout, loading, isCheckerOuter };
}
