import React, { useState } from "react";
import cn from "classnames";
import "./InlineTextField.scss";
import MaterialIcon from "@material/react-material-icon";

export type InlineInput = HTMLInputElement | HTMLTextAreaElement;

export type InlineTextFieldProps = {
  value: any;
  name: string;
  onChange: React.ChangeEventHandler<any>;
  onBlur?: React.FocusEventHandler<any>;
  onFocus?: React.FocusEventHandler<any>;
  onKeyDown?: React.Dispatch<React.KeyboardEvent<InlineInput>>;
  textarea?: boolean;
  disabled?: boolean;
  className?: string;
  errorMessage?: string;
  currency?: boolean;
  autoComplete?: string;
  required?: boolean;
};

export const InlineTextField: React.FC<InlineTextFieldProps> = (props) => {
  const { className, errorMessage, textarea, currency, ...rest } = props;
  const [focused, setFocused] = useState(false);

  const style = cn(
    ["inline-text-field mdc-typography--body1", className]
      .filter((x) => x)
      .join(" "),
    { error: !!errorMessage, focused }
  );

  const handleFocus = (e: React.FocusEvent<InlineInput>) => {
    setFocused(true);
    props.onFocus && props.onFocus(e);
  };

  const handleBlur = (e: React.FocusEvent<InlineInput>) => {
    setFocused(false);
    props.onBlur && props.onBlur(e);
  };

  const inputProps = {
    ...rest,
    onFocus: handleFocus,
    onBlur: handleBlur,
  };

  return (
    <div className={style}>
      {textarea ? (
        <textarea {...inputProps} autoComplete="off" />
      ) : (
        <input
          autoComplete="off"
          {...inputProps}
          aria-label={inputProps.name}
          step={currency ? ".01" : undefined}
          min={currency ? "0" : undefined}
          type={currency ? "number" : "text"}
        />
      )}
      {errorMessage && (
        <MaterialIcon
          icon="warning"
          title={errorMessage}
          className="inline-text-field-warning"
        />
      )}
    </div>
  );
};
