import React from "react";
import { ReviewWrapper, useReviewItemsContext } from "~/review-items";

import { ThirdPartyInvoice } from "~/gql/types";
import { SingleThirdPartyInvoiceRouter } from "./Single";

export const TPI_FILTERS_KEY = "tpi-filters";

export const TpiReview: React.FC = () => {
  const { selectedItems } = useReviewItemsContext();

  return (
    <ReviewWrapper
      {...{
        type: "third party invoices",
        path: "/third-party-invoices",
        filtersKey: TPI_FILTERS_KEY,
        navFunc: (x: ThirdPartyInvoice) =>
          `/third-party-invoices/review/${x.invoiceNumber}`,
        selectedItems: selectedItems as ThirdPartyInvoice[],
      }}
    >
      <SingleThirdPartyInvoiceRouter />
    </ReviewWrapper>
  );
};
