import { ApolloClient } from "@apollo/client";
import {
  GetStateCountiesDsQuery,
  GetStateCountiesDsQueryVariables,
  GetStateCountiesDsDocument,
  GetFieldOfficesDsQuery,
  GetFieldOfficesDsQueryVariables,
  GetFieldOfficesDsDocument,
  GetWorkLocationsDsQuery,
  GetWorkLocationsDsQueryVariables,
  GetWorkLocationsDsDocument,
  GetActiveProjectsDsQuery,
  GetActiveProjectsDsQueryVariables,
  GetActiveProjectsDsDocument,
  GetEmployeesDsQuery,
  GetEmployeesDsQueryVariables,
  GetEmployeesDsDocument,
} from "./queries.generated";
import { Employee, StateCounty, FieldOffice, Project } from "~/gql/types";

export type DataSourceKind<T extends string, TData> = {
  kind: T;
  load: (
    client: ApolloClient<any>,
    variables?: Partial<Record<string, any>>
  ) => Promise<TData[]>;
};

export type PreloadedData<TData> = {
  kind: "Preloaded";
  data: TData[];
};

export type ReferenceDataSource<TItem> = DataSourceKind<
  "Reference Data",
  TItem
> & { key: string };
export type SpotDataSource<TItem> = DataSourceKind<"Spot", TItem> & {
  key: string;
};

export type DataSource<TItem> =
  | SpotDataSource<TItem>
  | ReferenceDataSource<TItem>
  | PreloadedData<TItem>;

export type ProjectRefData = {
  name: string;
  id: string;
};

export const stateCounties: ReferenceDataSource<StateCounty> = {
  key: "stateCounties",
  kind: "Reference Data",
  load: async (client: ApolloClient<any>) => {
    const { data } = await client.query<
      GetStateCountiesDsQuery,
      GetStateCountiesDsQueryVariables
    >({
      query: GetStateCountiesDsDocument,
    });
    return data.stateCounties as StateCounty[];
  },
};

export const fieldOffices: ReferenceDataSource<FieldOffice> = {
  key: "fieldOffices",
  kind: "Reference Data",
  load: async (client: ApolloClient<any>) => {
    const { data } = await client.query<
      GetFieldOfficesDsQuery,
      GetFieldOfficesDsQueryVariables
    >({
      query: GetFieldOfficesDsDocument,
    });
    return data.fieldOffices?.all as FieldOffice[];
  },
};

export const workLocations: ReferenceDataSource<string> = {
  key: "officeCodes",
  kind: "Reference Data",
  load: async (client: ApolloClient<any>) => {
    const { data } = await client.query<
      GetWorkLocationsDsQuery,
      GetWorkLocationsDsQueryVariables
    >({
      query: GetWorkLocationsDsDocument,
    });
    return data.employees?.workLocations as string[];
  },
};

export const activeProjects: SpotDataSource<Project> = {
  kind: "Spot",
  key: "activeProjects",
  load: async (
    client: ApolloClient<any>,
    variables?: Partial<Record<string, any>>
  ) => {
    const { data } = await client.query<
      GetActiveProjectsDsQuery,
      GetActiveProjectsDsQueryVariables
    >({
      query: GetActiveProjectsDsDocument,
      variables: variables as GetActiveProjectsDsQueryVariables,
    });
    return data.projects?.activeProjects as Project[];
  },
};

const getEmployees = (
  key: string,
  roleName?: string
): ReferenceDataSource<Employee> => ({
  key: key,
  kind: "Reference Data",
  load: async (client: ApolloClient<any>) => {
    const { data } = await client.query<
      GetEmployeesDsQuery,
      GetEmployeesDsQueryVariables
    >({
      query: GetEmployeesDsDocument,
      variables: roleName ? { roleName } : undefined,
    });
    return data?.employees?.all as Employee[];
  },
});

export const allEmployees = getEmployees("allEmployees");
