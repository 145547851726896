import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStateCountiesDsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStateCountiesDsQuery = { __typename?: 'QueryModel', stateCounties?: Array<{ __typename?: 'StateCounty', state: string, county: string } | null> | null };

export type GetFieldOfficesDsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFieldOfficesDsQuery = { __typename?: 'QueryModel', fieldOffices?: { __typename?: 'FieldOfficesQueryModel', all?: Array<{ __typename?: 'FieldOffice', officeCode: string, allowsProjects: boolean } | null> | null } | null };

export type GetWorkLocationsDsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWorkLocationsDsQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', workLocations?: Array<string> | null } | null };

export type GetEmployeesDsQueryVariables = Types.Exact<{
  roleName?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetEmployeesDsQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', all?: Array<{ __typename?: 'Employee', firstName: string, lastName: string, middleInitial: string, userPrincipalName: string, ultiProId: string, isMobileEmployee: boolean, isTerminated: boolean } | null> | null } | null };

export type GetActiveProjectsDsQueryVariables = Types.Exact<{
  asOf: Types.Scalars['LocalDate'];
}>;


export type GetActiveProjectsDsQuery = { __typename?: 'QueryModel', projects?: { __typename?: 'ProjectsQueryModel', activeProjects?: Array<{ __typename?: 'Project', number: number, name: string, customer?: { __typename?: 'Customer', name: string } | null, location?: { __typename?: 'StateCounty', state: string, county: string } | null } | null> | null } | null };


export const GetStateCountiesDsDocument = gql`
    query GetStateCountiesDS {
  stateCounties {
    state
    county
  }
}
    `;

/**
 * __useGetStateCountiesDsQuery__
 *
 * To run a query within a React component, call `useGetStateCountiesDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStateCountiesDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStateCountiesDsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStateCountiesDsQuery(baseOptions?: Apollo.QueryHookOptions<GetStateCountiesDsQuery, GetStateCountiesDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStateCountiesDsQuery, GetStateCountiesDsQueryVariables>(GetStateCountiesDsDocument, options);
      }
export function useGetStateCountiesDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStateCountiesDsQuery, GetStateCountiesDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStateCountiesDsQuery, GetStateCountiesDsQueryVariables>(GetStateCountiesDsDocument, options);
        }
export type GetStateCountiesDsQueryHookResult = ReturnType<typeof useGetStateCountiesDsQuery>;
export type GetStateCountiesDsLazyQueryHookResult = ReturnType<typeof useGetStateCountiesDsLazyQuery>;
export type GetStateCountiesDsQueryResult = Apollo.QueryResult<GetStateCountiesDsQuery, GetStateCountiesDsQueryVariables>;
export const GetFieldOfficesDsDocument = gql`
    query getFieldOfficesDS {
  fieldOffices {
    all {
      officeCode
      allowsProjects
    }
  }
}
    `;

/**
 * __useGetFieldOfficesDsQuery__
 *
 * To run a query within a React component, call `useGetFieldOfficesDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldOfficesDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldOfficesDsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFieldOfficesDsQuery(baseOptions?: Apollo.QueryHookOptions<GetFieldOfficesDsQuery, GetFieldOfficesDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldOfficesDsQuery, GetFieldOfficesDsQueryVariables>(GetFieldOfficesDsDocument, options);
      }
export function useGetFieldOfficesDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldOfficesDsQuery, GetFieldOfficesDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldOfficesDsQuery, GetFieldOfficesDsQueryVariables>(GetFieldOfficesDsDocument, options);
        }
export type GetFieldOfficesDsQueryHookResult = ReturnType<typeof useGetFieldOfficesDsQuery>;
export type GetFieldOfficesDsLazyQueryHookResult = ReturnType<typeof useGetFieldOfficesDsLazyQuery>;
export type GetFieldOfficesDsQueryResult = Apollo.QueryResult<GetFieldOfficesDsQuery, GetFieldOfficesDsQueryVariables>;
export const GetWorkLocationsDsDocument = gql`
    query getWorkLocationsDS {
  employees {
    workLocations
  }
}
    `;

/**
 * __useGetWorkLocationsDsQuery__
 *
 * To run a query within a React component, call `useGetWorkLocationsDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkLocationsDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkLocationsDsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkLocationsDsQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkLocationsDsQuery, GetWorkLocationsDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkLocationsDsQuery, GetWorkLocationsDsQueryVariables>(GetWorkLocationsDsDocument, options);
      }
export function useGetWorkLocationsDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkLocationsDsQuery, GetWorkLocationsDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkLocationsDsQuery, GetWorkLocationsDsQueryVariables>(GetWorkLocationsDsDocument, options);
        }
export type GetWorkLocationsDsQueryHookResult = ReturnType<typeof useGetWorkLocationsDsQuery>;
export type GetWorkLocationsDsLazyQueryHookResult = ReturnType<typeof useGetWorkLocationsDsLazyQuery>;
export type GetWorkLocationsDsQueryResult = Apollo.QueryResult<GetWorkLocationsDsQuery, GetWorkLocationsDsQueryVariables>;
export const GetEmployeesDsDocument = gql`
    query GetEmployeesDS($roleName: String) {
  employees {
    all(employeeStatus: Active, roleName: $roleName) {
      firstName
      lastName
      middleInitial
      userPrincipalName
      ultiProId
      isMobileEmployee
      isTerminated
    }
  }
}
    `;

/**
 * __useGetEmployeesDsQuery__
 *
 * To run a query within a React component, call `useGetEmployeesDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesDsQuery({
 *   variables: {
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useGetEmployeesDsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesDsQuery, GetEmployeesDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesDsQuery, GetEmployeesDsQueryVariables>(GetEmployeesDsDocument, options);
      }
export function useGetEmployeesDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesDsQuery, GetEmployeesDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesDsQuery, GetEmployeesDsQueryVariables>(GetEmployeesDsDocument, options);
        }
export type GetEmployeesDsQueryHookResult = ReturnType<typeof useGetEmployeesDsQuery>;
export type GetEmployeesDsLazyQueryHookResult = ReturnType<typeof useGetEmployeesDsLazyQuery>;
export type GetEmployeesDsQueryResult = Apollo.QueryResult<GetEmployeesDsQuery, GetEmployeesDsQueryVariables>;
export const GetActiveProjectsDsDocument = gql`
    query GetActiveProjectsDS($asOf: LocalDate!) {
  projects {
    activeProjects(asOf: $asOf) {
      number
      customer {
        name
      }
      name
      location {
        state
        county
      }
    }
  }
}
    `;

/**
 * __useGetActiveProjectsDsQuery__
 *
 * To run a query within a React component, call `useGetActiveProjectsDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveProjectsDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveProjectsDsQuery({
 *   variables: {
 *      asOf: // value for 'asOf'
 *   },
 * });
 */
export function useGetActiveProjectsDsQuery(baseOptions: Apollo.QueryHookOptions<GetActiveProjectsDsQuery, GetActiveProjectsDsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveProjectsDsQuery, GetActiveProjectsDsQueryVariables>(GetActiveProjectsDsDocument, options);
      }
export function useGetActiveProjectsDsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveProjectsDsQuery, GetActiveProjectsDsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveProjectsDsQuery, GetActiveProjectsDsQueryVariables>(GetActiveProjectsDsDocument, options);
        }
export type GetActiveProjectsDsQueryHookResult = ReturnType<typeof useGetActiveProjectsDsQuery>;
export type GetActiveProjectsDsLazyQueryHookResult = ReturnType<typeof useGetActiveProjectsDsLazyQuery>;
export type GetActiveProjectsDsQueryResult = Apollo.QueryResult<GetActiveProjectsDsQuery, GetActiveProjectsDsQueryVariables>;