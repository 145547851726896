import { Body1, Body2 } from "@material/react-typography";
import _ from "lodash";
import React, { useState } from "react";
import { Chevron } from "~/chevron";
import PageTitleRow from "~/page-title-row";
import Spinner from "~/spinner";
import {
  Employee,
  ProjectChargeFilterState,
  ProjectStatusItem,
} from "~/gql/types";
import cn from "classnames";
import "./ProjectStatus.scss";
import Button from "~/button";
import moment, { Moment } from "moment";
import { useReportFetcher } from "~/report-fetcher/useReportFetcher";
import { InlineProgress } from "~/inline-progress";
import { useProjectStatusQuery } from "./query.generated";
import { FilterForm, FilterOptions } from "./FilterForm";
import {
  formatFirstMiddleLast,
  formatLastFirstMiddle,
} from "~/utils/formatEmployeeName";

type ProjectManagerData = {
  projectManager: Employee;
  projectCount: number;
  unbilledCharges: number;
  projectNumbers: number[];
};

export type BaProject = {
  billingAdmin: Employee;
  projectCount: number;
  chargeCount: number;
  projectManagers: ProjectManagerData[];
};

export const organizeProjects = (
  projects: ProjectStatusItem[]
): BaProject[] => {
  const grouped = _.chain(projects)
    .groupBy((x) => x?.billingAdmin!.userPrincipalName)
    .mapValues((baProjects) => ({
      billingAdmin: baProjects[0]!.billingAdmin!,
      projectCount: baProjects.length,
      chargeCount: _.sum(baProjects.map((x) => x.unbilledCharges)),
      projectManagers: _.chain(baProjects)
        .groupBy((pmProjects) => pmProjects.projectManager!.userPrincipalName)
        .mapValues((pmProjects) => ({
          projectManager: pmProjects[0].projectManager!,
          projectCount: pmProjects.length,
          unbilledCharges: _.sum(pmProjects.map((x) => x.unbilledCharges)),
          projectNumbers: Array.from(
            new Set(pmProjects.map((x) => x.projectNumber))
          ),
        }))
        .values()
        .sortBy((x) => formatLastFirstMiddle(x.projectManager))
        .value(),
    }))
    .values()
    .sortBy((x) => formatLastFirstMiddle(x.billingAdmin))
    .value();

  return grouped;
};

const Report: React.FC<{
  pm: ProjectManagerData;
  ba: Employee;
  statusFilters: FilterOptions;
}> = ({ pm, ba, statusFilters }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const downloadReport = useReportFetcher();

  const dateAfter = statusFilters.startDate
    ? moment(statusFilters.startDate).subtract(1, "day").format("YYYY-MM-DD")
    : null;
  const dateBefore = statusFilters.endDate
    ? moment(statusFilters.endDate).add(1, "day").format("YYYY-MM-DD")
    : null;

  const filterBody = {
    billingAdmin: ba.userPrincipalName,
    projectManager: pm.projectManager.userPrincipalName,
    state: ProjectChargeFilterState.Unbilled.toString(),
    dateAfter,
    dateBefore,
    customerNumber: statusFilters.customerNumber,
  };

  const pmName = `${pm.projectManager.firstName}-${pm.projectManager.lastName}`;
  const baName = `${ba.firstName}-${ba.lastName}`;

  const fileName = `project-charges-${baName}-${pmName}${moment().format(
    "MM-DD-YYYY"
  )}`;

  const exportCsv = async () => {
    await downloadReport({
      setLoading,
      path: "project-charge-csv",
      fileName,
      body: filterBody,
      type: "text/csv;charset=utf-8;",
    });
  };

  const exportPdf = async () => {
    await downloadReport({
      setLoading,
      path: "internal-billing-report",
      fileName,
      body: filterBody,
    });
  };

  const exportSummary = async () => {
    await downloadReport({
      setLoading,
      path: "projects-csv",
      fileName: `projects ${moment().format("YYYY-MM-DD")}`,
      body: {
        billingAdmin: ba.userPrincipalName,
        projectManager: pm.projectManager.userPrincipalName,
        chargeStartDate: dateAfter,
        chargeEndDate: dateBefore,
        customerNumber: statusFilters.customerNumber,
        projectNumbers: pm.projectNumbers,
      },
      type: "text/csv;charset=utf-8;",
    });
  };

  const buttonProps = {
    disabled: loading,
  };

  return (
    <div className="report-buttons">
      {loading && <InlineProgress />}
      <Button onClick={exportCsv} {...buttonProps}>
        Detailed Csv
      </Button>
      <Button onClick={exportPdf} {...buttonProps}>
        Detailed Pdf
      </Button>
      <Button onClick={exportSummary} {...buttonProps}>
        Summary Csv
      </Button>
    </div>
  );
};

export const BaAccordion: React.FC<{
  ba: BaProject;
  statusFilters: FilterOptions;
}> = ({ ba, statusFilters }) => {
  const [expanded, setExpanded] = useState(false);

  const { billingAdmin, projectCount, chargeCount, projectManagers } = ba;

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const produceText = (
    employee: Employee,
    projectCount: number,
    chargeCount: number,
    pmCount?: number
  ) => {
    const name = formatFirstMiddleLast(employee);
    const projectCountText = `${projectCount} project${
      projectCount > 1 ? "s" : ""
    }`;
    const chargeCountText = `${chargeCount} unbilled charge${
      chargeCount > 1 ? "s" : ""
    }`;
    const pmCountText = pmCount
      ? `${pmCount} project manager${pmCount > 1 ? "s" : ""}, `
      : "";

    return `${name} - ${pmCountText}${projectCountText}, ${chargeCountText}`;
  };

  const getPmText = (pm: ProjectManagerData) => {
    const { projectManager, projectCount, unbilledCharges } = pm;

    return produceText(projectManager, projectCount, unbilledCharges);
  };

  return (
    <div className="ba-accordion">
      <div className="ba-text" onClick={handleExpand}>
        <Body1>
          {produceText(
            billingAdmin,
            projectCount,
            chargeCount,
            projectManagers.length
          )}
        </Body1>
        <Chevron {...{ expanded }} />
      </div>

      <div
        className={cn("managers", { expanded })}
        style={{
          maxHeight: `${
            expanded ? (Math.min(projectManagers.length, 6) + 1) * 48 : 0
          }px`,
        }}
      >
        {projectManagers.map((pm, idx) => (
          <div key={idx} className="manager">
            <div>
              <Body2>{getPmText(pm)}</Body2>
            </div>

            <Report pm={pm} ba={billingAdmin} statusFilters={statusFilters} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const ProjectStatus: React.FC = () => {
  const [statusFilters, setStatusFilters] = useState<FilterOptions>({
    startDate: null,
    endDate: null,
    projectManager: null,
    billingAdmin: null,
    customerNumber: null,
    searchText: null,
  });

  const {
    startDate,
    endDate,
    projectManager,
    billingAdmin,
    customerNumber,
    searchText,
  } = statusFilters;

  const toDateValue = (date: Moment | null) =>
    date ? date.format("YYYY-MM-DD") : null;

  const { data, loading } = useProjectStatusQuery({
    variables: {
      startDate: toDateValue(startDate),
      endDate: toDateValue(endDate),
      projectManager,
      billingAdmin,
      customerNumber,
      searchText,
    },
  });

  const projects = data?.projects?.projectStatus ?? [];

  const grouped = organizeProjects(projects as ProjectStatusItem[]);

  return (
    <div className="project-status-page">
      <FilterForm onFiltersChanged={setStatusFilters} />
      <PageTitleRow title="Project Status" />

      {grouped.map((ba, idx) => (
        <BaAccordion key={idx} ba={ba} statusFilters={statusFilters} />
      ))}

      <Spinner open={loading} />
    </div>
  );
};
