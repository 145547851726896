import { MenuList, MenuListItem, MenuListItemText } from "@material/react-menu";
import MenuSurface from "@material/react-menu-surface";
import React, { useRef, useState, ReactElement } from "react";

import { TableRowActionButton } from "~/table";
import "./TableRowActions.scss";

type ValidationResponse = {
  errors?: { message: string; argumentName?: string }[];
};

export type MenuOption = {
  text: string;
  onClick: (
    string
  ) => void | ((string) => Promise<void>) | Promise<ValidationResponse>;
  disabled?: boolean;
};

export type TableRowActionsProps = {
  options: MenuOption[];
  className?: string;
};

export function TableRowActions({
  options,
  className,
}: TableRowActionsProps): ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <div className="table-row-actions">
      <div
        className="mdc-menu-surface--anchor table-row-actions-anchor"
        ref={anchorRef}
      >
        <TableRowActionButton
          iconName="more_horiz"
          label="Actions"
          onClick={() => setOpen(!open)}
        />
        <MenuSurface
          open={open}
          anchorCorner={4}
          onClose={() => setOpen(false)}
          onBlur={() => setOpen(false)}
          anchorElement={anchorRef.current ?? undefined}
        >
          <MenuList className={className}>
            {options &&
              options.map((option, idx) => (
                <MenuListItem
                  key={idx}
                  disabled={option.disabled ?? false}
                  onClick={() => {
                    void option.onClick(option.text);
                    setOpen(false);
                  }}
                >
                  <MenuListItemText primaryText={option.text} />
                </MenuListItem>
              ))}
          </MenuList>
        </MenuSurface>
      </div>
    </div>
  );
}
