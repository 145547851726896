import { useState } from "react";
import { useWorkDayApi } from "~/WorkDayApi";
import { ValidationResponse } from "~/gql/types";

type DeleteExpenseState = {
  deleteLoading: boolean;
  deleteExpenseEntry: (expenseId: string) => Promise<ValidationResponse>;
};

export function useDeleteExpense(): DeleteExpenseState {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const { deleteExpense } = useWorkDayApi();

  const deleteExpenseEntry = async (expenseId: string) => {
    setDeleteLoading(true);
    const res = await deleteExpense({ expenseId });
    setDeleteLoading(false);
    res.callback;
    return res.response;
  };

  return { deleteLoading, deleteExpenseEntry };
}
