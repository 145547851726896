import { useApolloClient } from "@apollo/client";
import moment from "moment";
import {
  ChargeType,
  Employee,
  PerDiemKind,
  PerDiemRate,
  Project,
  TaskRate,
} from "~/gql/types";
import { SingleEmployeeRateQueryDocument } from "./employeeRate.generated";
import { executeQuery } from "./fields/fieldUtils";
import { GetPerDiemRateForChargeFormDocument } from "./perDiemRate.generated";
import { ExpenseRate } from "~/gql/ExpenseRate";

type SetRateAmountProps = {
  rate: ExpenseRate | TaskRate;
  setRateAmount: React.Dispatch<string | null>;
  setLoading: React.Dispatch<boolean>;
  project: Project;
  date: string;
  employee: Employee | null;
  type: ChargeType;
};

export function useSetRateAmount(): (
  args: SetRateAmountProps
) => Promise<void> {
  const client = useApolloClient();

  return async (args) => {
    const { rate, setRateAmount, setLoading, project, date, employee, type } =
      args;

    const handleExpenseRateAmount = async (
      expenseRate: ExpenseRate
    ): Promise<string | null> => {
      if (
        expenseRate.__typename === "PerDiemExpenseRate" &&
        expenseRate.billableOverride == null
      ) {
        const result = await client.query({
          query: GetPerDiemRateForChargeFormDocument,
          variables: {
            state: project.location!.state,
            county: project.location!.county,
            effectiveDate: moment(date).format("YYYY-MM-DD"),
          },
        });

        const perDiemRate = result?.data?.perDiemRates?.rate as PerDiemRate;

        const amountMap = {
          [PerDiemKind.Lodging]: perDiemRate.lodgingBillable,
          [PerDiemKind.Meals]: perDiemRate.mealsBillable,
          [PerDiemKind.Travel]: perDiemRate.travelBillable,
          [PerDiemKind.TravelMeals]: perDiemRate.travelBillable,
        };

        return `${amountMap[expenseRate.kind]}`;
      }

      if (expenseRate.__typename === "AtvExpenseRate") {
        return `${expenseRate.billableAmount}`;
      }

      if (expenseRate.__typename === "MileageExpenseRate") {
        return `${expenseRate.billableAmountPerMile}`;
      }

      if (expenseRate.__typename === "MobileAllowanceExpenseRate") {
        return `${expenseRate.billableAmount}`;
      }

      if (expenseRate.__typename === "PerDiemExpenseRate") {
        return `${expenseRate.billableOverride}`;
      }

      return null;
    };

    const handleEmployeeRateTask = async (): Promise<string | null> => {
      const upn = employee?.userPrincipalName;

      if (upn) {
        const result = await client.query({
          query: SingleEmployeeRateQueryDocument,
          variables: {
            userPrincipalName: upn,
            effectiveDate: moment(date).format("YYYY-MM-DD"),
          },
        });

        const rate = result?.data?.employeeRates?.single?.rate ?? 0;

        return `${rate}`;
      } else {
        return `${0}`;
      }
    };

    if (type === "Expense") {
      void executeQuery(async () => {
        const result = await handleExpenseRateAmount(rate as ExpenseRate);
        setRateAmount(result);
      }, setLoading);

      return;
    } else if (type === "Task" && (rate as TaskRate).usesEmployeeRate) {
      void executeQuery(async () => {
        const result = await handleEmployeeRateTask();
        setRateAmount(result);
      }, setLoading);
    } else {
      setRateAmount(`${(rate as TaskRate).billableAmount}`);
    }
  };
}
