import * as Types from '../gql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RolesSearchQueryVariables = Types.Exact<{
  token?: Types.InputMaybe<Types.Scalars['String']>;
  roleName?: Types.InputMaybe<Types.Scalars['String']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type RolesSearchQuery = { __typename?: 'QueryModel', employees?: { __typename?: 'EmployeesQueryModel', roleSearch?: { __typename?: 'ConnectionOfRoleAssignment', token?: string | null, records?: Array<{ __typename?: 'RoleAssignment', roleName: string, employees?: Array<{ __typename?: 'Employee', firstName: string, middleInitial: string, lastName: string, userPrincipalName: string } | null> | null } | null> | null } | null } | null };


export const RolesSearchDocument = gql`
    query RolesSearch($token: String, $roleName: String, $searchText: String) {
  employees {
    roleSearch(token: $token, roleName: $roleName, searchText: $searchText) {
      token
      records {
        roleName
        employees {
          firstName
          middleInitial
          lastName
          userPrincipalName
        }
      }
    }
  }
}
    `;

/**
 * __useRolesSearchQuery__
 *
 * To run a query within a React component, call `useRolesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesSearchQuery({
 *   variables: {
 *      token: // value for 'token'
 *      roleName: // value for 'roleName'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useRolesSearchQuery(baseOptions?: Apollo.QueryHookOptions<RolesSearchQuery, RolesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesSearchQuery, RolesSearchQueryVariables>(RolesSearchDocument, options);
      }
export function useRolesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesSearchQuery, RolesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesSearchQuery, RolesSearchQueryVariables>(RolesSearchDocument, options);
        }
export type RolesSearchQueryHookResult = ReturnType<typeof useRolesSearchQuery>;
export type RolesSearchLazyQueryHookResult = ReturnType<typeof useRolesSearchLazyQuery>;
export type RolesSearchQueryResult = Apollo.QueryResult<RolesSearchQuery, RolesSearchQueryVariables>;