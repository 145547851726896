import React from "react";
import { ReviewWrapper, useReviewItemsContext } from "~/review-items";

import { CustomRollupInvoice } from "~/gql/types";
import { CustomRollupInvoicePage } from "./SinglePage";

export const CRI_FILTERS_KEY = "cri-filters";

export const CriReview: React.FC = () => {
  const { selectedItems } = useReviewItemsContext();

  return (
    <ReviewWrapper
      {...{
        type: "custom rollup invoices",
        path: "/custom-rollup-invoices",
        filtersKey: CRI_FILTERS_KEY,
        navFunc: (x: CustomRollupInvoice) =>
          `/custom-rollup-invoices/review/${x.invoiceNumber}`,
        selectedItems: selectedItems as CustomRollupInvoice[],
      }}
    >
      <CustomRollupInvoicePage />
    </ReviewWrapper>
  );
};
