import React, { useState } from "react";
import TimeOffEntryForm, {
  FormData,
} from "~/visuals/organisms/TimeOffEntryForm";
import moment from "moment";
import { useWorkDayApi } from "~/WorkDayApi";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { useTimeOffCallback } from "~/visuals/pages/NewTimeOffEntry/useTimeOffCallback";

const NewTimeOffEntry: React.FC = () => {
  const {
    createTimeOffEntry,
    workDay: { date },
    breadcrumbs,
  } = useWorkDayApi();
  const [loading, setLoading] = useState<boolean>(false);

  useBreadcrumbs(
    [...breadcrumbs, { text: "Create New Time Off Entry" }],
    [breadcrumbs]
  );

  const durationFormat = (d) => {
    if (!d) {
      return null;
    }
    const m = moment(d.asMilliseconds()).startOf("day").add(d);

    return m.format("HH:mm:ss");
  };

  const getCallback = useTimeOffCallback();

  const addTimeOffEntry = async (values: FormData) => {
    const callback = getCallback(values);

    setLoading(true);
    const res = await createTimeOffEntry(
      {
        date: date,
        hours: durationFormat(values.hours)!,
        timeOffType: values.timeOffType!,
        eeCode: values.eeCode?.eeCode!,
        adminNotes: values.adminNotes || null,
      },
      callback
    );
    setLoading(false);

    return res;
  };

  return (
    <TimeOffEntryForm
      type="Create"
      handleSubmit={addTimeOffEntry}
      date={date}
      loading={loading}
    />
  );
};

export default NewTimeOffEntry;
