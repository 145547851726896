import React, { useEffect, useState } from "react";
import { usePayrollAdminInfoQuery } from "./query.generated";
import TitledCard from "~/titled-card";
import TableCell from "~/old-table-cell";
import Table from "~/old-table";
import TableHeader from "~/old-table-header";
import TableContent from "~/old-table-content";
import "./PayrollAdminInfo.scss";
import { useUser } from "~/Auth/useUser";
import { timesheetReview } from "~/visuals/pages/TimesheetReview/routes";
import moment from "moment";
import Button from "~/button";
import { Route } from "~/routeTypes";
import Spinner from "~/spinner";

const DataFetcher = ({ onChange }) => {
  const result = usePayrollAdminInfoQuery();

  useEffect(() => {
    onChange(result);
  }, [result.loading, result.data]);

  return null;
};

type FetchResult = ReturnType<typeof usePayrollAdminInfoQuery>;

export const PayrollAdminInfo: React.FC = () => {
  const [{ data, loading }, setResult] = useState<FetchResult>({
    loading: true,
  } as any as FetchResult);

  const user = useUser();

  if (!user.authorizations.legacyAuth.viewPayrollAdminData) {
    return null;
  }

  const officeCodes = data?.employees?.payrollAdminInfo?.officeCodeData ?? [];

  const today = moment().utc();
  const thisWeek = today.startOf("week").format("YYYY-MM-DD");
  const lastWeek = moment(today.startOf("week"))
    .subtract(1, "week")
    .format("YYYY-MM-DD");

  const paFilter = `&payrollAdmin=${user.email.toLowerCase()}`;

  const toLastWeek = (office: string) =>
    `${timesheetReview}?weekStart=${lastWeek}${paFilter}&workLocation=${office}`;
  const toThisWeek = (office: string) =>
    `${timesheetReview}?weekStart=${thisWeek}${paFilter}&workLocation=${office}`;

  return (
    <div>
      <DataFetcher onChange={setResult} />
      <TitledCard title="Payroll Admin Info" className="payroll-admin-info">
        <Table>
          <TableHeader>
            <TableCell text="Office Code" />
            <TableCell text="Employee Count" />
            <TableCell text="Last week's timesheets" />
            <TableCell text="This week's timesheets" />
          </TableHeader>
          {officeCodes.map((office, idx) => (
            <TableContent key={idx} className="pa-office-code-data">
              <TableCell text={office!.officeCode} />
              <TableCell text={`${office!.employeeCount}`} />
              <TableCell>
                <Button
                  className={`${office!.officeCode}-last-week`}
                  route={new Route(toLastWeek(office!.officeCode))}
                >
                  Last Week
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  className={`${office!.officeCode}-this-week`}
                  route={new Route(toThisWeek(office!.officeCode))}
                >
                  This Week
                </Button>
              </TableCell>
            </TableContent>
          ))}
        </Table>
      </TitledCard>
      <Spinner open={loading} />
    </div>
  );
};
