import React, { useState } from "react";
import { useConfirmationPrompt } from "~/confirmation-prompt";
import Button from "~/button";
import { useSnackBar } from "~/snackbar";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import Spinner from "~/spinner";
import { FilterOptions, Timesheet } from "../types";
import { useApolloClient } from "@apollo/client";
import { ApproveableTimesheetsCountDocument } from "./query.generated";
import { useApproveAllTimeSheetsMutation } from "./approveAll.generated";
import { ValidationResponse } from "~/gql/types";
import { ValidationError } from "~/gql/types2";

type ApproveAllProps = {
  state: State<Timesheet, FilterOptions>;
  dispatch: React.Dispatch<Action<Timesheet, FilterOptions>>;
};

export const canShowButton = (
  state: State<Timesheet, FilterOptions>
): boolean => {
  const {
    filterOptions: { weekStart, weekStatus },
    items,
  } = state;
  const hasSubmittedWeeks =
    items?.some((x) => x.status === "PrPending") ?? false;
  const canApprove = items?.some((x) => x.canBeApproved) ?? false;
  return !(
    !weekStart ||
    !hasSubmittedWeeks ||
    !canApprove ||
    (weekStatus && weekStatus !== "PrPending")
  );
};

export const ApproveAll: React.FC<ApproveAllProps> = ({ state, dispatch }) => {
  const {
    filterOptions: {
      weekStart,
      dateAfter,
      dateBefore,
      weekStatus,
      workLocation,
      searchText,
      reimbursement,
      payPeriod,
      payrollAdmin,
    },
  } = state;

  const prompt = useConfirmationPrompt();
  const [loading, setLoading] = useState<boolean>(false);
  const client = useApolloClient();
  const addAlert = useSnackBar();
  const alertKey = `${workLocation}-${weekStart?.format("YYYY-MM-DD")}`;

  const [doMutation, { loading: approveLoading }] =
    useApproveAllTimeSheetsMutation();

  if (!canShowButton(state)) {
    return null;
  }

  const variables = {
    searchText: searchText ?? null,
    weekStart: weekStart!.format("YYYY-MM-DD"),
    dateBefore: dateBefore?.format("YYYY-MM-DD") ?? null,
    dateAfter: dateAfter?.format("YYYY-MM-DD") ?? null,
    workLocation: workLocation ?? null,
    status: weekStatus ?? null,
    reimbursement: reimbursement ?? null,
    payrollAdmin: payrollAdmin?.userPrincipalName ?? null,
    payPeriod: payPeriod ?? null,
  };

  const createMessage = (count: number) =>
    `There ${count > 1 ? "are" : "is"} ${count} submitted timesheet${
      count > 1 ? "s" : ""
    } that can be approved for week ${weekStart?.format("MM/DD/YYYY")}. ` +
    `Do you want to approve ${count > 1 ? "them" : "it"}?`;

  const getCount = async () => {
    setLoading(true);
    const results = await client.query({
      query: ApproveableTimesheetsCountDocument,
      variables,
    });
    setLoading(false);
    return results?.data?.timesheets?.approveableTimesheetCount?.count;
  };

  const approveAll = async (count: number) => {
    const result = await doMutation({
      variables,
    });

    const data = result?.data?.timesheets
      ?.approveAllWorkWeeks as ValidationResponse;
    const errors = (result?.errors ?? data?.errors ?? []) as ValidationError[];

    if (errors.length > 0) {
      const formErrors: string[] = [];
      errors.forEach(({ argumentName, message }) => {
        if (argumentName) {
          formErrors.push(`${argumentName}: ${message}`);
        } else if (message) {
          formErrors.push(message);
        }
      });

      addAlert({
        key: alertKey,
        message: formErrors.join(" "),
        isSuccess: false,
      });
    } else {
      addAlert({
        key: alertKey,
        message: `${count} timesheet${count > 1 ? "s" : ""} approved`,
        isSuccess: true,
      });
      dispatch({
        tag: "Reset",
        filterOptions: state.filterOptions,
      });
    }
  };

  const openPrompt = async () => {
    const count = await getCount();

    if (!count) {
      addAlert({
        key: alertKey,
        message: "There are no timesheets that can be approved",
        isSuccess: true,
      });
      return;
    }

    const promptResult = await prompt({
      cancelButtonText: "Cancel",
      confirmButtonText: `Yes, approve ${count > 1 ? "all" : "it"}`,
      message: createMessage(count ?? 0),
      title: "Approve timesheets",
    });

    if (promptResult !== "Confirm") {
      return;
    }

    await approveAll(count);
  };

  return (
    <>
      <Spinner open={loading || approveLoading} />
      <Button primary onClick={openPrompt}>
        Approve All
      </Button>
    </>
  );
};
